import { observable, action, computed, runInAction } from 'mobx'
import { create, persist } from 'mobx-persist'
import axios from 'axios'
import MiniSearch from 'minisearch'
import { productOptionsTotalPrice, geoDistance, searchSubstitutions } from '../utils'

import { Modal } from 'antd';

const isHotel = () => !!window.location.pathname.match("^/(hotel|breakfast)")

class AppState {
    @observable
    currentStore = null

    @observable
    currentStoreSlug = null

    @observable
    currentStoreData = null


    // Cart map of shop_id => shop_cart,
    // shop_cart is a map of product_id => quantity or (map of options => quantity, for personalizable products)
    @persist('object')
    @observable
    cart = {}

    @observable.shallow
    products = {}

    @observable
    searchPhrase = ""

    @observable
    searchPhraseDebounced = ""

    @observable
    stores = {}

    //@observable
    //lastJoinedStores = []

    @persist
    @observable
    address = ""

    @persist('object')
    @observable
    geolocation = {}

    @observable
    categories = {}

    @persist
    @observable
    firstTime = true

    @observable
    hydrated = false

    @persist('object')
    @observable
    checkoutFormData = undefined

    @observable
    categoryFilter = ''


    @observable
    lang = 'pl'

    @action.bound
    setProducts(data) {

        const categories = {}

        this.products = {}
        for (const p of data) {
            this.products[p.id] = p

            const [c1, c2, c3] = (p.category || "").split('>>')
            p._index_category = (p.category || "").replace(/>>/g, ' ')
            categories[c1] = categories[c1] || {}
            categories[c1][c2] = categories[c1][c2] || {}
            if(c3) {
                categories[c1][c2][c3] = (categories[c1][c2][c3] || 0) + 1
            }
        }
        this.categories = categories

        for (const p in this.cart[this.currentStore]) {
            if (!this.products[p]) {
                delete this.cart[this.currentStore][p]
            }
        }

        this.miniSearch = new MiniSearch({
            fields: ['name', '_index_category'],
            storeFields: ['name'],
            processTerm: (x) => {
                let term = x.toLowerCase()
                return searchSubstitutions[term] || term
            },
            searchOptions: {
                boost: {_index_category: 2}
            }
        })
        this.miniSearch.addAll(data)
    }

    @action.bound
    fetchProducts(id) {
        axios.get(`/api/shop/${id || this.currentStore}/products`).then(({ data }) => this.setProducts(data))
    }

    @action.bound
    setStore(id) {
        return this.storesFetched.then(() => {
            if(id === this.currentStore) return;

            if(id && !id.match(/\d+/)) {
                const store = Object.values(this.stores).find((x) => x.slugs && x.slugs.includes(id))
                if(!store) {
                    throw "Store not found"
                }
                id = store.id
            }
            //if(id && !this.stores[id]) {
            //    throw "Store not found"
            //}
            this.currentStore = id
            this.currentStoreData = this.stores[id]
            this.currentStoreSlug = this.stores[id] && this.stores[id].slugs && this.stores[id].slugs[0]
            this.currentStoreUrlPrefix = `${this.currentStore}/${this.currentStoreSlug}`
            this.products = {}
            this.searchIndex = null

            if (id) {
                axios.get(`/api/shop/${id}/details`).then(({ data }) => {
                    this.setStores([data])
                }).catch(() => {
                    throw "Store not found"
                })

                if(!isHotel()) {
                    this.fetchProducts(id)
                }
            }
        })
    }

    @action.bound
    setInCart(product, quantity, options) {
        this.cart[this.currentStore] = this.cart[this.currentStore] || {}
        const cart = this.cart[this.currentStore]
        var prev_quantity = 0;

        if (!options) {
            if (quantity <= 0) {
                delete cart[product.id];
            } else if(typeof quantity === 'number') {
                prev_quantity = cart[product.id]
                cart[product.id] = quantity;
            }
        } else {
            const serialized = JSON.stringify(options)
            cart[product.id] = cart[product.id] || {}
            if (quantity <= 0) {
                delete cart[product.id][serialized];
                if (Object.keys(cart[product.id]).length === 0) {
                    delete cart[product.id]
                }
            } else {
                prev_quantity = cart[product.id][serialized]
                cart[product.id][serialized] = quantity;
            }
        }

        const maxCartValue = 700
        prev_quantity = prev_quantity || 0;
        if(quantity > prev_quantity && this.cartTotal > maxCartValue) {
            Modal.warning({content: 'Wartość koszyka nie może przekraczać kwoty 700 zl.'})
            this.setInCart(product, prev_quantity, options)
        }
    }

    @action.bound
    clearCart() {
        this.cart[this.currentStore] = {}
    }


    @action.bound
    setSearch(v) {
        this.searchPhrase = v
        //this.searchPhraseCompleted = false
        clearTimeout(this.searchTimer)
        this.searchTimer = setTimeout(action(() => this.searchPhraseDebounced = this.searchPhrase), 500)

        /*if(this.miniSearch) {
            this.searchSuggestions = this.miniSearch.autoSuggest(v, {combineWith: 'AND',})
            console.log(this.searchSuggestions)
        }*/
    }

    @action.bound
    setCategoryFilter(x) {
        this.categoryFilter = x
        //if(x) { this.setSearchNow('') }
    }

    @action.bound
    setSearchNow(v) {
        this.searchPhrase = v
        this.searchPhraseDebounced = v
        //this.searchPhraseCompleted = true
        clearTimeout(this.searchTimer)
    }

    @computed get currentProducts() {
        const searchPhrase = this.searchPhraseDebounced
        if (!searchPhrase || searchPhrase.length < 3) {
            this.searchActive = false

            let results;
            if(this.categoryFilter) {
                results = Object.values(this.products).filter(x => x.category && x.category.startsWith(this.categoryFilter))
            } else {
                results = Object.values(this.products)
            }
            results.sort((a, b) => {
                if(a.image_type && !b.image_type) return -1
                if(!a.image_type && b.image_type) return 1
                return 0
            })
            return results
        } else if (this.products && this.miniSearch) {
            this.searchActive = true
            const results = this.miniSearch.search(searchPhrase, {
                prefix: true, // !this.searchPhraseCompleted
                fuzzy: 0.2,
                combineWith: 'AND',
            })
            //console.log(results)

            return results.map(x => ({...this.products[x.id], match: x}))
        }
        return []
    }

    @action.bound
    setStores(data) {
        for (const x of data) {
            const v = {
                ...(this.stores[x.id] || {}),
                ...x,
                id: '' + x.id,
                slug: x.slug || ('' + x.id),
                flags: x.flags || {},
            }
            /*if(v.id == 21) {
                v.flags = {hotel: true, breakfast: 'true'}
            }*/
            this.stores[v.id] = v
            if (v.id === this.currentStore) {
                this.currentStoreData = v
            }
        }
        /*if (data && data.length > 1) {
            this.lastJoinedStores = data.slice(-3)
        }*/
    }

    @action.bound
    fetchStores() {
        if(isHotel()) {
            this.storesFetched = Promise.resolve([])
            return
        }
        this.storesFetched = axios.get(`/api/shops`).then(({ data }) => { return this.setStores(data) })
    }

    @action.bound
    setAddress(address) {
        this.address = address
        console.log("Address", address)
    }

    @action.bound
    setGeolocation(location) {
        this.geolocation = location
        console.log("Geolocation", location)
    }

    @computed get storesByDistance() {
        if (!this.geolocation.lat) { return [] }

        const list = Object.values(this.stores).map(x => ({
            ...x,
            distance: geoDistance(x, this.geolocation)
        }))
        list.sort((a, b) => a.distance - b.distance)

        return list
    }

    @computed get cartTotal() {
        const cart = this.cart[this.currentStore] || {}
        var total = 0
        for (let id in cart) {
            if(!this.products[id]) continue; // fixme
            const q = cart[id]
            if (typeof q !== 'object') {
                total += this.products[id].price * q
            } else {
                for (let variant in q) {
                    const optionsPrice = productOptionsTotalPrice(this.products[id], variant)
                    total += (this.products[id].price + optionsPrice) * q[variant]
                }
            }
        }
        total += ((this.currentStoreData && this.currentStoreData.packing_cost) || 0)
        // add handling cost
        return total
    }

    @action.bound
    doBrowserGeoLocation() {
        return new Promise((resolve, reject) => {
            console.log("browserGeoLocation")

            const success = (position) => {
                const lat = position.coords.latitude;
                const lng = position.coords.longitude;

                this.setGeolocation({ lat, lng })
                geocodeByLocation({ lat, lng }).then((res) => {
                    const x = res[0]
                    if (x) {
                        const address = x.formatted_address.replace(/ \d\d-\d\d\d/, "").replace(", Polska", "")
                        this.setAddress(address)
                        resolve()
                    } else {
                        reject()
                    }
                }).catch(error => {
                    //console.error(error)
                    reject()
                });
            }
            const error = (error) => {
                //console.error(error)
                reject()
            }
            navigator.geolocation.getCurrentPosition(success, error);
        })
    }

    @action.bound
    rememberCheckoutFormData(data) {
        this.checkoutFormData = data
    }

    @action.bound
    setLang(lang) {
        this.lang = lang
    }
}


const hydrate = create({ storage: localStorage, })

export const state = new AppState()
window._state = state

state.fetchStores()

// todo: move
export const geocodeByLocation = (location) => {
    const geocoder = new window.google.maps.Geocoder();
    const OK = window.google.maps.GeocoderStatus.OK;

    return new Promise((resolve, reject) => {
        geocoder.geocode({ location }, (results, status) => {
            if (status !== OK) {
                reject(status);
            }
            resolve(results);
        });
    });
};

const browserGeoLocation = () => {
    return new Promise((resolve, reject) => {
        console.log("browserGeoLocation")

        const success = (position) => {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;

            state.setGeolocation({ lat, lng })
            geocodeByLocation({ lat, lng }).then((res) => {
                const x = res[0]
                if (x) {
                    const address = x.formatted_address.replace(/, \d\d-\d\d\d/, "").replace(", Polska", "")
                    state.setAddress(address)
                }
                resolve()
            })
                .catch(error => {
                    console.error(error)
                    resolve()
                });
        }
        const error = (error) => {
            console.error(error)
            resolve()
        }
        if (!state.geolocation.lat) {
            navigator.geolocation.getCurrentPosition(success, error);
        } else {
            resolve()
        }
    })
}

hydrate('state', state).then(() => {
    console.log('state hydrated')

    runInAction(() => { state.hydrated = true })

    if (state.firstTime) {
        setTimeout(action(() => state.firstTime = false), 3000)
    }
})
