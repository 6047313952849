import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react'
import {
  Modal,
  Input as AntInput,
  Spin,
} from 'antd'
import { PlusOutlined, MinusOutlined, QrcodeOutlined } from '@ant-design/icons';
import styled, { createGlobalStyle } from 'styled-components'
import axios from 'axios'
import { inject, observer } from 'mobx-react'
import { size, omit, isEmpty, keys, sortBy, values, find, range, sumBy } from 'lodash'
import { state } from '../models/AppState'

//import { animated, useSpring } from 'react-spring'

import { format, startOfTomorrow, parse as parseDate } from 'date-fns'
import { pl } from 'date-fns/locale'

import {
  LangSwitch,
  Header,
  ContactButton,
  Wrapper,
  GlobalStyle,
  useGAScreen,
  gaEvent,
  gaSet,
  readQrCodeCookie,
  t,
  CartButton,
  Button,
  ScanQRAgain,
} from './Common.js'

const Wrapper2 = styled.div`
max-width: 600px;
margin: 0 auto;
font-family: 'FS Lola';
--primary: #509E2F;
font-size: 20px;
padding: 12px;
`

const ProductWrapper = styled.div`
border: 1px solid #d9d9d9;
padding: 8px 12px 12px;
margin: 12px auto;
border-radius: 6px;
width: 100%;
text-align: center;
background-color: #fff;
transition: 0.3s border-color, 0.3s background-color;

&.in-cart {
  border: 1px solid var(--primary);
  background: rgb(80 158 47 / 10%);
}
`

const Progress = ({ step }) => {
  const steps = 4

  const dotStyles = (i) => {
    if (i == step) {
      return {
        border: '3px solid var(--primary)',
        backgroundColor: '#fff',
        width: 15,
        height: 15,
      }
    }
    if (i < step) {
      return {
        backgroundColor: 'var(--primary)',
      }
    }
    return {
      backgroundColor: '#fff',
    }
  }

  return <div style={{
    display: 'flex',
    alignItems: 'center',
    margin: '30px 50px 0',
    height: 15,
  }}>
    {range(steps).map((i) => <React.Fragment key={i}>
      <div
        style={{
          width: 11,
          height: 11,
          borderRadius: '50%',
          border: '2px solid var(--primary)',
          transition: 'all 0.3s',
          ...dotStyles(i),
        }}
      />
      {i < steps - 1 && <div
        style={{
          margin: '0 2px',
          borderRadius: 1,
          backgroundColor: i < step ? 'var(--primary)' : '#f0f0f0',
          height: 3,
          flex: 1,
          transition: 'all 0.3s',
        }}
      />}
    </React.Fragment>)}

  </div>
}

const Input = styled(AntInput)`
&:hover {
  border-color: var(--primary);
}
&.ant-input:focus {
  box-shadow: 0 0 0 2px rgb(80 158 47 / 20%);
}
`

const formatDesc = (x) => {
  return (x || '').replace(/^[-*]/mg, '•')
}

const Product = ({ product, inCart, setInCart, addDisabled }) => {

  return <ProductWrapper className={inCart > 0 ? 'in-cart' : ''}>
    {t(product.name)}
    {product.desc && <p style={{ fontSize: 16, marginBottom: 12, whiteSpace: 'pre-wrap', textAlign: 'left' }}>{formatDesc(t(product.desc))}</p>}
    <CartButton
      limit={product.limit}
      inCart={inCart}
      setInCart={setInCart}
      addDisabled={addDisabled}
    />
  </ProductWrapper>
}

const Products = ({ guestId, menu, close, cart, setCart }) => {

  // fixme: don't use cart => local breakfast state can be used
  //const cart = state.cart[state.currentStore] || {}
  //const opt = JSON.stringify({ guest: guestId })
  useGAScreen('ProductsPopup')

  const cartProds = cart.products || {}
  const [summary, setSummary] = useState(!isEmpty(cartProds))

  const setNote = (x) => setCart({ ...cart, note: x })

  if (summary) {
    const cats = menu.categories.map((c) => {
      return {
        ...c,
        products: c.products.filter(x => cartProds[x.short])
      }
    })

    return <Wrapper style={{ padding: 0 }}>
      <p style={{
        fontWeight: 'bold',
        marginBottom: 12,
        color: 'var(--primary)',
      }}
      >
        {t({
          pl: 'Twoje śniadanie',
          en: 'Your breakfast'
        })}&nbsp;#{guestId + 1}
      </p>

      <div>
        {cats.map((cat) => {
          if (cat.products.length) {
            return <div key={t(cat.name)}>
              <p>{t(cat.name)}</p>
              <ul>
                {cat.products.map(p => (
                  <li key={p.short}>{t(p.name)} ({cartProds[p.short]})</li>
                ))}
              </ul>
            </div>
          }
        })}

      </div>

      <p
        style={{
          color: 'var(--primary)',
          marginBottom: 12,
        }}
      >{t({
        pl: "Uwagi do zamówienia",
        en: "Other requests:",
      })}</p>
      <Input.TextArea
        placeholder={t({ pl: "Uwagi", en: "Requests" })}
        style={{ fontSize: 16 }}
        value={cart.note}
        onChange={(v) => setNote(v.target.value)}
      />
      <div style={{ display: 'flex' }}>
        <Button
          onClick={() => setSummary(false)}
          style={{ flex: 1, margin: 12, marginLeft: 0, marginRight: 6, height: 'auto', padding: 8, lineHeight: '1em' }}
          size="large"
        >
          {t({
            pl: 'ZMIEŃ',
            en: 'CHANGE',
          })}
          <br />
          {t({
            pl: 'ZAMÓWIENIE',
            en: 'ORDER',
          })}
        </Button>
        <Button
          size="large"
          type="primary"
          onClick={close}
          style={{ flex: 1, margin: 12, marginRight: 0, marginLeft: 6, height: 'auto', padding: 8 }}
        >
          {t({
            pl: 'DODAJ',
            en: 'ADD',
          })}
        </Button>
      </div>

    </Wrapper>
  }

  return <Wrapper style={{ padding: 0 }}>
    {menu.categories.map((c) => {
      const limit = c.limit || 10000
      const categoryShorts = c.products.map(x => x.short)
      const categoryInCart = sumBy(categoryShorts, x => cartProds[x] || 0)
      const addDisabled = (categoryInCart >= limit) && (limit > 1)
      const setInCart = (p) => (x) => {
        if (x > 0) {
          let prods = { ...cartProds }
          if (limit == 1) { // auto-remove for limit == 1
            prods = omit(prods, categoryShorts)
          }
          setCart({ ...cart, products: { ...prods, [p.short]: x } })
        } else {
          setCart({ ...cart, products: omit(cartProds, [p.short]) })
        }
      }

      return <div key={t(c.name)} style={{ marginBottom: 24 }}>
        <p style={{ fontSize: '22px', marginBottom: 0 }}>{t(c.name)}</p>
        {c.desc && <p style={{ fontSize: 16, marginBottom: 0, whiteSpace: 'pre-wrap' }}>{formatDesc(t(c.desc))}</p>}
        {c.products.map(p => <Product
          product={{ ...p, id: p.short }}
          key={p.short}
          inCart={cartProds[p.short] || 0}
          addDisabled={addDisabled}
          setInCart={setInCart(p)}
        />)}
      </div>
    })}


    <Button
      style={{
        display: 'block',
        margin: '20px auto',
        width: 200,
      }}
      type="primary"
      onClick={() => setSummary(true)}
      size="large"
      disabled={isEmpty(cartProds)}
    >
      {t({
        pl: 'DALEJ',
        en: 'NEXT',
      })}
    </Button>
  </Wrapper>
}





const ThxScreen = ({ room, breakfasts, day, slot, lang, storeId }) => {
  useGAScreen('ThxScreen')

  useEffect(() => {
    window.onbeforeunload = null;
  }, [])

  return <Wrapper
    style={{
      textAlign: 'center'
    }}
  >
    <img
      src={`/api/shop/${storeId}/image`}
      style={{ maxWidth: 250 }}
    />

    <Progress step={4} />

    <div style={{ margin: '10px 0' }}>
      <img
        src="/imgs/breakfast.png"
      />
      <p style={{
        fontWeight: 'bold',
        color: 'var(--primary)',
        fontSize: 26,
        padding: '0 36px',
        lineHeight: '1.2em',
        marginTop: -20,
      }}>{t({
        pl: 'Dziękujemy za zamówienie!',
        en: 'Thank you for your order!'
      })}</p>
    </div>

    <p style={{ padding: '0 12px', textAlign: 'left' }}>
      {t({
        pl: 'Liczba śniadań',
        en: 'Breakfasts',
      })}
      <span style={{ float: 'right', fontWeight: 600, color: 'var(--primary)' }}>{breakfasts}</span><br />
      {t({
        pl: 'Do pokoju',
        en: 'Room'
      })}
      <span style={{ float: 'right', fontWeight: 600, color: 'var(--primary)' }}>{room}</span><br />
      {t({
        pl: 'Na dzień',
        en: 'Day'
      })}
      <span style={{ float: 'right', fontWeight: 600, color: 'var(--primary)' }}>{format(day, 'eeee dd.MM.y', lang == 'pl' ? { locale: pl } : {})}</span><br />
      {t({
        pl: 'W godzinach ',
        en: 'Hour'
      })}
      <span style={{ float: 'right', fontWeight: 600, color: 'var(--primary)' }}>{slot}</span>
    </p>

    <p>
      {t({
        pl: 'Chcesz coś zmienić?',
        en: 'Want to change something?'
      })}
    </p>
    <ContactButton />
  </Wrapper>
}

const WelcomeScreen = ({ day, close, storeId }) => {
  useGAScreen('WelcomeScreen')

  return <Wrapper style={{
    textAlign: 'center',
  }}>
    <div>
      <LangSwitch />
      <img
        src={`/api/shop/${storeId}/image`}
        style={{ maxWidth: 250, marginTop: 10, maxHeight: 150 }}
      /><br />
      {/*
      <img
        src="/imgs/breakfast.png"
      />
       */}
      <p style={{
        fontWeight: 'bold',
        color: 'var(--primary)',
        marginBottom: 35,
        padding: '0 20px',
        fontSize: '26px',
        lineHeight: '1.2em',
      }}>
        {t({
          pl: "Witaj w systemie zamawiania śniadań!",
          en: "Welcome to the breakfast ordering system!"
        })}
      </p>

      <p style={{
        color: 'var(--primary)',
        marginBottom: 35,
      }}>
        {t({
          pl: `Zamówisz tutaj śniadanie na ${format(day, 'dd.MM.yyyy')} do swojego pokoju. Jeśli chcesz zamówić śniadanie na inny dzień `,
          en: `You can order your breakfast for ${format(day, 'dd.MM.yyyy')} here. If you want to order breakfast for another day, `,
        })}
        <a href={"tel:" + (state.currentStoreData && state.currentStoreData.phone)}
          onClick={() => gaEvent('contact_frontdesk')}
          style={{ fontWeight: 500, textDecoration: 'underline', color: 'var(--primary)' }}
        >
          {t({
            pl: 'skontaktuj się z recepcją.',
            en: 'please contact the front desk.'
          })}
        </a>
      </p>

      <Button
        size="large"
        type="primary"
        style={{ width: 160, }}
        onClick={close}
      >OK</Button>
    </div>
  </Wrapper>
}


const ClosedScreen = ({ message, storeId }) => {
  useGAScreen('OrdersClosed')

  return <Wrapper style={{
    textAlign: 'center',
  }}>
    <LangSwitch />
    <img
      src={`/api/shop/${storeId}/image`}
      style={{ maxWidth: 250, marginTop: 10 }}
    />

    <p style={{
      whiteSpace: 'pre-wrap',
      textAlign: 'left',
      margin: '60px 12px 50px'
    }}
    >{t(message)}</p>

    <ContactButton />
  </Wrapper>
}


const RoomForm = ({ setRoomNo, hotelId, day }) => {
  useGAScreen('RoomNumberScreen')

  const [val, setVal] = useState('')
  const [loading, setLoading] = useState(false)

  const [errors, setErrors] = useState()

  const qrcode = useMemo(readQrCodeCookie, []);

  useEffect(() => {
    window.onbeforeunload = function (e) {
      return t({
        pl: 'Czy na pewno chcesz załadować stronę ponownie? Twoje zamówienie nie zostanie zapisane.',
        en: 'Are you sure you want to reload? Your order will not be saved.'
      });
    };
  }, [])

  const checkRoomNumber = () => {
    const number = val
    setLoading(true)
    axios.get(`/api/shop/${hotelId}/hotel/check-room-number/${number}`, {
      params: {
        'qr-id': qrcode['qr-id'],
        token: qrcode['token'],
        day: format(day, 'yyyy-MM-dd'),
      }
    }).then(({ data }) => {
      setLoading(false)
      setRoomNo(number)
      gaSet({room: number})
    }).catch((error) => {
      setLoading(false)
      if (error.response && error.response.status == 404) {
        setErrors('incorrect')
      }
      if (error.response && error.response.status == 403) {
        setErrors('denied')
      }
      if (error.response && error.response.status == 409) {
        setErrors('ordered')
      }
    })
  }

  return <Wrapper>
    <p style={{
      color: 'var(--primary)',
      fontWeight: 'bold',
    }}>{t({
      pl: 'Wpisz numer pokoju:',
      en: 'Enter room number:',
    })}</p>
    <div style={{ marginBottom: 50 }}>
      <Input
        size="large"
        style={{
          fontSize: '32px',
          width: 160,
          textAlign: 'center',
        }}
        placeholder="000"
        type="tel"
        maxLength={4}
        value={val}
        onChange={(e) => setVal((e.target.value || '').replace(/\D/g, ''))}
      />
    </div>
    <p>
      {t({
        pl: 'Śniadania powyżej opłaconej oferty zostaną doliczone do rachunku.',
        en: 'Breakfasts above the paid offer will be added to the bill.'
      })}
    </p>
    <Button
      size="large"
      type="primary"
      onClick={checkRoomNumber}
      disabled={!val}
      loading={loading}
      style={{ width: 160, marginTop: 100 }}
    >{t({
      pl: 'DALEJ',
      en: 'NEXT',
    })}</Button>

    <Modal
      visible={errors == 'incorrect'}
      onCancel={() => setErrors(null)}
      footer={null}
    >
      <Wrapper style={{ textAlign: 'center' }}>
        <p>{t({
          pl: 'Przepraszamy, podany numer pokoju jest nieprawidłowy. Prosimy o sprawdzenie i ponowne wprowadzenie numeru pokoju.',
          en: 'We are sorry, provided room number is incorrect. Please check your room number and try again.',
        })}
        </p>
        <Button
          onClick={() => setErrors(null)}
          type="primary"
        >OK</Button>
      </Wrapper>
    </Modal>
    <Modal
      visible={errors == 'ordered'}
      onCancel={() => setErrors(null)}
      footer={null}
    >
      <Wrapper style={{ textAlign: 'center' }}>
        <p>
          {t({
            pl: 'Zamówienie dla tego pokoju zostało już złożone.',
            en: 'We have already received breakfast order for this room.',
          })}
        </p>
        <p>
          {t({
            pl: 'Chcesz coś zmienić?',
            en: 'Want to change something?',
          })}
        </p>
        <ContactButton />
      </Wrapper>
    </Modal>
    <Modal
      visible={errors == 'denied'}
      onCancel={() => setErrors(null)}
      footer={null}
    >
      <Wrapper style={{ textAlign: 'center' }}>
        <p>{t({ pl: 'Pokój: ', en: 'Room: ' })} <b>{val}</b></p>
        <p>
          {t({
            pl: 'Przepraszamy, dla podanego numeru pokoju nie możesz złożyć zamówienia.',
            en: 'We are sorry, you cannot place an order for the provided room number.',
          })}
        </p>
        <ContactButton />
      </Wrapper>
    </Modal>

  </Wrapper>
}

const BreakfastPageInner = inject('state')(observer(({ state, shopId }) => {
  const qrCookie = readQrCodeCookie() || {}
  const hotelId = shopId || ('' + (qrCookie['hotel-id'] || ''))

  if (!hotelId) {
    return <ScanQRAgain />
  }

  gaSet({hotel: hotelId, qrId: qrCookie['qr-id']})

  const [ready, setReady] = useState()
  const [roomNo, setRoomNo] = useState()
  const [timeSlot, setTimeSlot] = useState()
  const [timeSelector, setTimeSelector] = useState()
  const [breakfastInEdit, setBreakfastInEdit] = useState(null)
  const [welcomePage, setWelcomePage] = useState(true)
  const [timeSlotAccepted, setTimeSlotAccepted] = useState(false)
  const [thxPage, setThxPage] = useState(false)
  const [loading, setLoading] = useState(true)

  const [menu, setMenu] = useState({})

  const [orderedNum, setOrderedNum] = useState({})

  const [cart, setCart] = useState({}) // guest-id => {products (short => quantity), note}

  const breakfastsNum = size(cart)

  const addBreakfast = () => {
    if (breakfastsNum < 5) {
      const b = breakfastsNum
      setBreakfastInEdit(b)
    }
  }

  const loadMenu = () => {
    axios.get(`/api/shop/${hotelId}/hotel/menu/active`).then(({ data }) => {
      setMenu(data)
      setLoading(false)
      if (!data || (!data.closed && !data.categories)) {
        Modal.error({ content: 'Jeszcze nie ma menu na jutro. Spróbuj później.' })
      }
    }).catch(() => {

    })
  }

  useEffect(() => {
    state.setStore(hotelId).then(() => {
      setReady(true)
    })
    loadMenu()
  }, [])

  const day = menu.day ? parseDate(menu.day, 'yyyy-MM-dd', new Date()) : startOfTomorrow()

  const slots = menu.slots || []

  const postOrder = (confirmed) => {
    const breakfasts = values(cart)
    const qr = readQrCodeCookie() // todo not in cookie

    var order = {
      day: format(day, 'yyyy-MM-dd'),
      slot: timeSlot,
      room: roomNo,
      orders: breakfasts,
      qr: qr,
      not_finished: !confirmed,
    }

    if (confirmed || qr['qr-id']) {
      return axios.post(`/api/shop/${hotelId}/hotel/orders`, order)
    } else {
      return Promise.resolve()
    }
  }

  const order = () => {
    postOrder(true).then((x) => {
      setOrderedNum(values(cart).length)
      setThxPage(true)
    }).catch((error) => {
      if (error.response && error.response.status == 409) {
        loadMenu()
        Modal.info({
          title: t({
            pl: 'Przepraszamy, przedział czasowy dostarczenia śniadania, który wybrałeś, jest już zajęty. Wybierz nowy przedział lub skontaktuj się z recepcją.',
            en: 'We are sorry, the delivery time slot you’ve chosen has became unavailable. Please choose a new delivery time slot.'
          }),
          onOk: () => {
            setTimeSelector(true)
          }
        })
      } else {
        Modal.error({ title: 'Błąd', content: '' + error })
      }
    })
  }

  /*if (false)
    return <ThxScreen
      room={"030"}
      breakfasts={2}
      day={day}
      slot={"9:00 - 9:30 2021-03-30"}
      lang={state.lang}
      key={state.lang}
      storeId={state.currentStore}
    />*/

  if (loading) {
    return <Spin style={{ margin: '150px auto', display: 'block' }} />
  }

  if (menu.closed) {
    return <ClosedScreen message={menu.message} storeId={hotelId} key={state.lang} />
  }

  if (welcomePage) {
    return <WelcomeScreen day={day} key={state.lang} close={() => setWelcomePage(false)} storeId={hotelId} />
  }

  if (thxPage) {
    return <ThxScreen
      room={roomNo}
      breakfasts={orderedNum}
      day={day}
      slot={timeSlot}
      lang={state.lang}
      key={state.lang}
      storeId={state.currentStore}
    />
  }

  return <Wrapper
    key={state.lang}
    style={{ textAlign: 'center' }}
  >
    <Header hotelId={hotelId} />

    <Progress step={0 + !!roomNo + !!timeSlotAccepted + !!breakfastsNum} />

    <p style={{
      textAlign: 'center',
      margin: '30px 0',
      height: 60,
    }}>
      {t({
        pl: 'Śniadanie na ',
        en: 'Breakfast for ',
      })}
      <b style={{ color: 'var(--primary)', }}>{format(day, 'eeee, dd.MM.y', state.lang == 'pl' ? { locale: pl } : {})}</b><br />
      {roomNo && <span>
        {t({
          pl: 'do pokoju nr: ',
          en: 'to room number: ',
        })}
        <b style={{ color: 'var(--primary)', }}>{roomNo}</b><br />
        {/*
        <Input
          type="tel"
          maxLength={4}
          value={roomNo}
          style={{ fontSize: 20, fontWeight: 'bold', width: 45, color: 'var(--primary)', padding: 0 }}
          bordered={false}
          onChange={(e) => setRoomNo(e.target.value)}
        />*/}
      </span>}
    </p>

    {!roomNo && <RoomForm setRoomNo={setRoomNo} hotelId={hotelId} day={day} />}

    {roomNo && !timeSlotAccepted && <div>
      <p style={{
        textAlign: 'center',
        color: 'var(--primary)',
        fontWeight: 'bold',
      }}
      >{t({
        pl: 'Wybierz godzinę:',
        en: 'Choose delivery hour:',
      })}</p>
      <div
        style={{
          border: '1px solid #d9d9d9',
          padding: '20px 0',
          margin: '12px auto 40px',
          borderRadius: 6,
          width: 200,
          textAlign: 'center',
          color: timeSlot ? '#666' : '#d9d9d9',
          fontSize: '32px',
          cursor: 'pointer',
        }}
        onClick={() => {
          setTimeSelector(true)
        }}
      >{(timeSlot && timeSlot.replace(/ \d{4}-\d{2}-\d{2}/, '')) || t({ pl: 'wybierz', en: 'choose' })}</div>
      <p style={{ textAlign: 'center', }}>{t({
        pl: 'Z uwagi na wymogi prawne zamówienie dostarczamy do pokoju hotelowego.',
        en: 'Due to legal requirements, the order will be delivered to your hotel room.',
      })}</p>
      <Button
        size="large"
        type="primary"
        disabled={!timeSlot}
        onClick={() => setTimeSlotAccepted(true)}
        style={{ width: 160, marginTop: 100 }}
      >{t({
        pl: 'DALEJ',
        en: 'NEXT',
      })}</Button>
    </div>}

    {timeSlotAccepted &&
      <Button
        size="large"
        style={{
          width: '100%',
          height: 'auto',
          fontSize: '22px',
          fontWeight: 'normal',
          whiteSpace: 'normal',
          marginBottom: 12,
          lineHeight: '1.3em',
        }}
        onClick={() => setTimeSelector(true)}
      >
        {t({
          pl: 'Dostarczenie do pokoju ',
          en: 'Delivery to room '
        })}<br />
        {t({
          pl: 'w godz.',
          en: 'between:'
        })}&nbsp;<span style={{ color: 'var(--primary)', fontWeight: 600, }}>{timeSlot.replace(/ \d{4}-\d{2}-\d{2}/, '')}</span>
      </Button>
    }

    <Modal
      visible={timeSelector}
      footer={null}
      onCancel={() => setTimeSelector(false)}
    >
      <p style={{
        fontSize: '18px',
        textAlign: 'center',
      }}>{t({
        pl: 'Proszę o dostarczenie do mojego pokoju pomiędzy:',
        en: 'Deliver to my room between:'

      })}</p>
      <div style={{ textAlign: 'center' }}>
        {slots.map(slot => {
          const disabled = slot.limit && slot.used_limit && slot.used_limit >= slot.limit
          return <Button
            key={slot.id}
            type={slot.id == timeSlot ? 'primary' : 'normal'}
            onClick={() => {
              setTimeSlot(slot.id)
              setTimeSelector(false)
            }}
            style={{
              height: 'auto',
              width: 135,
              fontSize: '18px',
              fontWeight: 500,
              margin: 6,
              padding: '9px 12px',
              textAlign: 'center',
            }}
            disabled={disabled}
          >{slot.id}</Button>
        })}
      </div>
    </Modal>

    {timeSlotAccepted && sortBy(keys(cart)).map(x => <Button
      key={x}
      style={{
        width: '100%',
        height: 'auto',
        fontSize: '22px',
        fontWeight: 'bold',
        whiteSpace: 'normal',
        marginBottom: 12,
        padding: 24,
        color: 'var(--primary)',
      }}
      onClick={() => setBreakfastInEdit(+x)}
    >
      {t({
        pl: 'Twoje śniadanie',
        en: 'Your breakfast'
      })}&nbsp;#{(+x) + 1}
    </Button>
    )}

    <div style={{ marginBottom: 50 }}>
      {timeSlotAccepted && menu && breakfastsNum < 5 && <div>
        <Button
          style={{
            width: '100%',
            height: 'auto',
            fontSize: '22px',
            fontWeight: '600',
            whiteSpace: 'normal',
            color: 'var(--primary)',
            marginBottom: 30,
            marginTop: 20,
          }}
          type="link"
          onClick={addBreakfast}
        >
          <PlusOutlined style={{
            fontSize: '48px',
            fontWeight: 600,
            color: '#fff',
            background: 'var(--primary)',
            borderRadius: '50%',
            width: 48,
            height: 48,
          }} />
          <br />
          {t({
            pl: 'Dodaj śniadanie',
            en: 'Add breakfast'
          })} #{breakfastsNum + 1}
        </Button>
        {breakfastsNum < 4 &&
          <Button
            style={{
              width: '100%',
              height: 'auto',
              fontSize: '22px',
              fontWeight: '600',
              whiteSpace: 'normal',
              color: '#d9d9d9',
              marginBottom: 30,
            }}
            type="link"
          >
            <PlusOutlined style={{
              fontSize: '48px',
              fontWeight: 600,
              color: '#fff',
              background: '#d9d9d9',
              borderRadius: '50%',
              width: 48,
              height: 48,
            }} />
            <br />
            {t({
              pl: 'Dodaj śniadanie',
              en: 'Add breakfast'
            })} #{breakfastsNum + 2}
          </Button>
        }
      </div>
      }
    </div>

    <Modal
      visible={breakfastInEdit !== null}
      footer={null}
      onCancel={() => {
        setBreakfastInEdit(null)
        postOrder(false)
      }}
      style={{ top: 10 }}
    >
      <Products
        key={breakfastInEdit}
        menu={menu}
        guestId={breakfastInEdit}
        close={() => {
          setBreakfastInEdit(null)
          postOrder(false)
        }}
        cart={cart[breakfastInEdit] || {}}
        setCart={(x) => setCart({ ...cart, [breakfastInEdit]: x })}
      />
    </Modal>

    {timeSlotAccepted &&
      <div style={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '8px 0',
        background: '#fff',
        boxShadow: 'rgb(0 0 0 / 5%) 0px -1px 2px',
      }}>
        <Button
          style={{
            display: 'block',
            margin: '0 auto',
            width: 200,
            height: 'auto',
            fontSize: '22px',
            fontWeight: '500',
            whiteSpace: 'normal',
          }}
          disabled={!breakfastsNum}
          type="primary"
          onClick={() => {
            Modal.confirm({
              title: t({ pl: 'Czy zamówiłeś śniadania dla wszystkich osób w pokoju? Złożonego zamówienia nie można edytować.', en: 'Please make sure you ordered breakfast for everyone in the room. You can’t edit the order once it’s made.' }),
              okText: t({ pl: 'ZAMÓW', en: 'ORDER' }),
              onOk: order,
              cancelText: t({ pl: 'ANULUJ', en: 'CANCEL' }),
            })
            //order()
          }}>
          {t({
            pl: 'ZAKOŃCZ',
            en: 'ORDER',
          })}
        </Button>
      </div>
    }
  </Wrapper >
}))

const GlobalStyle2 = createGlobalStyle`
:root {
  --primary: ${props => props.primaryColor || "rgb(27,26,78)"}; //#509E2F;
}`

const BreakfastPage = inject('state')(observer(({ state, ...args }) => {
  const color = state.currentStoreData?.hotel_config?.primary_color
  return <div>
    <GlobalStyle />
    <GlobalStyle2 primaryColor={color} />
    <BreakfastPageInner {...args} />
  </div>
}))

export default BreakfastPage

export { BreakfastPageInner }
