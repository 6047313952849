import { state } from './models/AppState'
import { useLocation } from 'react-router-dom';
import { isString } from 'lodash';

const imageUrl = (product) => {
    if(isString(product)) return `/cust-images/${state.currentStore}/${product}.jpg`
    if (!product || !product.image_type) return '/product-placeholder.png';
    if (product.image_type == "custom") return `/cust-images/${state.currentStore}/${product.id}.jpg`
    if (product.image_type == "bio") return `/bio-images/${product.id}.jpg`
    if (product.image_type.match(/^fruit:/)) {
        return `/fruits-images/${(product.image_type.match(/^fruit:(.+)$/) || [])[1]}.jpg`
    }
    return `/db-images/${product.id}-${product.image_type}.jpg`
}

const imageErrorHandler = (e) => {
    e.target.src = '/product-placeholder.png'
}

const formatPrice = (x) => x ? x.toFixed(2).replace('.', ',') : '-'

const formatQuantity = (x) => (x && x.toFixed) ? x.toFixed(1).replace('.', ',').replace(',0', '') : '-'

const quantityStep = (unit) => unit == 'kg' ? 0.1 : 1;

const productOptionsTotalPrice = (product, optionsSpec) => {
    const options = JSON.parse(optionsSpec)
    let total = 0
    for (let x in options) {
        const customization = (product.customizations || []).find(c => c.id == x)
        const optionCost = ((customization || {}).options || []).map(o => {
            if (o.price && (o.label == options[x] || (options[x].includes && options[x].includes(o.label)))) {
                return o.price;
            }
            return 0
        }).reduce((a, b) => a + b, 0)
        total += optionCost
    }
    return total
}

const geoDistance = (a, b) => {
    const R = 6371
    const rad = (x) => x * Math.PI / 180
    var dLat = rad(a.lat - b.lat)
    var dLng = rad(a.lng - b.lng)
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(rad(a.lat)) * Math.cos(rad(b.lat)) *
        Math.sin(dLng / 2) * Math.sin(dLng / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
}

const searchSubstitutions = {
    'jajka': 'jaja',
}

const useQuery = () => {
    return Object.fromEntries(new URLSearchParams(useLocation().search))
}

const debounce = (callback, wait) => {
    let timeout;
    return (...args) => {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => callback.apply(context, args), wait);
    };
}

export {
    imageUrl,
    imageErrorHandler,
    formatPrice,
    formatQuantity,
    quantityStep,
    productOptionsTotalPrice,
    geoDistance,
    searchSubstitutions,
    useQuery,
    debounce,
}
