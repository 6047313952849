import React, { useState, useEffect } from 'react';
import { Spin, Row, Col, Divider } from 'antd';
import styled from 'styled-components';
import { imageUrl, imageErrorHandler } from '../utils'
import axios from 'axios'
import { state } from '../models/AppState'

const Image = styled.img`
max-width: 100%;
max-height: 250px;
object-fit: contain;
margin: 0 auto 26px;
display: block;
`

const Name = styled.p`
font-size: 2em;
line-height: 1.36363636em;
font-weight: 300;
text-align: center;
padding: 0 20px;
margin-bottom: 24px;
`

const Desc = styled.div`
margin-bottom: 16px;
`
const Label = styled.div`
font-weight: 600;
font-size: 1.2em;
`
const Value = styled.div``

const fetchDetails = (id) => {
    return axios.get(`/api/shop/${state.currentStore}/product/${id}`).then(({ data }) => ({
        ...data,
        description: data.description || {empty: true},
    }))
}

const stripHtml = (s) => {
    return (s || '').replace(/(<([^>]+)>)/ig, '')
}

const BreakfastName = styled.p`
font-weight: 500;
font-family: 'FS Lola';
text-align: center;
font-size: 24px;
`
const BreakfastImage = styled.img`
width: 100%;
width: calc(100% + 48px);
object-fit: contain;
margin: 0 -24px 26px;
display: block;
`

const ProductDetails = ({ product, cartButtons, breakfastVersion }) => {
    const [data, setData] = useState()
    const details = data && data.description

    const Name1 = !breakfastVersion ? Name : BreakfastName
    const Image1 = !breakfastVersion ? Image : BreakfastImage


    useEffect(() => {
        fetchDetails(product.id).then((d) => { setData(d) })
    }, [product])

    const desc = (x) => {
        //console.log(x)
        const res = []

        if (x.empty) {
            res.push(<Desc>
                <Value style={{color: "#aaa"}}>Brak opisu produktu</Value>
            </Desc>)
        }

        if (x.custom) {
            res.push(<Desc>
                <Value>{x.custom}</Value>
            </Desc>)
        }

        if (x.marketing) {
            res.push(<Desc>
                <Value>{x.marketing}</Value>
            </Desc>)
        }
        if (x.ingredients) {
            res.push(<Desc>
                <Label>Skład:</Label>
                <Value>{stripHtml(x.ingredients.join ? x.ingredients.join(', ') : x.ingredients)}</Value>
            </Desc>)
        }
        if (x.storage) {
            res.push(<Desc>
                <Label>Przechowywanie:</Label>
                <Value>{x.storage}</Value>
            </Desc>)
        }
        if (x.allergy_advice) {
            var txt = []
            for (var k in x.allergy_advice) {
                txt.push(<span key={k}>{k}: {x.allergy_advice[k]}<br /></span>)
            }
            res.push(<Desc>
                <Label>Alergeny:</Label>
                <Value>{txt}</Value>
            </Desc>)
        }
        if (x.allergy_text) {
            res.push(<Desc>
                <Value>{x.allergy_text}</Value>
            </Desc>)
        }
        if (x.manufacturer) {
            res.push(<Desc>
                <Label>Producent:</Label>
                <Value>{x.manufacturer}</Value>
            </Desc>)
        }
        if (x.nutrition) {

            if(x.nutrition['100g']) {
                res.push(<Desc>
                    <Label>Wartości odżywcze w 100g:</Label>
                    <Value>{x.nutrition['100g']}</Value>
                </Desc>)
            }
            if(x.nutrition['100ml']) {
                res.push(<Desc>
                    <Label>Wartości odżywcze w 100ml:</Label>
                    <Value>{x.nutrition['100ml']}</Value>
                </Desc>)
            }
            if(x.nutrition.columnHeaders) {
                res.push(<Desc>
                    <Label>Wartości odżywcze:</Label>
                    <Value>
                        <table>
                            <tbody>
                                <tr>
                                    <th></th>
                                    {x.nutrition.columnHeaders.map((x,i) => <th key={i}>{x}</th>)}
                                </tr>
                                {x.nutrition.rowData.map((r,i) => (<tr key={i}>
                                    <td>{r.nutrient}</td>
                                    {r.values.map((x,i) => <td key={i}>{x}</td>)}
                                </tr>))}
                            </tbody>
                        </table>
                    </Value>
                </Desc>)
            }
        }
        if(res.length > 0 && data.brandbank_id) {
            res.push(<div style={{marginTop: 50}}>
                Informacje o produktach zapewnia <img style={{height: 20, verticalAlign: "bottom"}} src="/nielsen-brandbank-logo.png" alt="nielsen brandbank"/>
            </div>)
        }
        if(res.length > 0 && data.bioplanet_id) {
            res.push(<div style={{marginTop: 50}}>
                Informacje o produktach zapewnia <img style={{height: 30, verticalAlign: "bottom", marginLeft: 5}} src="/bio-planet-logo.png" alt="bio planet"/>
            </div>)
        }

        return res;
    }


    return <Spin spinning={!details} delay={200}>
        <Name1>{product.name}</Name1>
        {!breakfastVersion && <Divider/>}
        <Row gutter={16}>
            <Col xs={24} md={8}>
                <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', height: "100%"}}>
                    <Image1
                        src={imageUrl(product)}
                        onError={imageErrorHandler}
                    />
                    <div style={{margin: "0 auto 24px", width: "200px"}}>
                    {!breakfastVersion && cartButtons}
                    </div>
                </div>
            </Col>
            <Col xs={24} md={16}>
                <div
                style={breakfastVersion ? {
                    fontFamily: 'FS Lola',
                    fontSize: 16,
                } : {}}
                >
                {!!details && <>{desc(details).map((x,i) => <div key={i}>{x}</div>)}</>}
                </div>
                {breakfastVersion &&
                    <div style={{margin: "0 auto 24px", width: "200px"}}>
                        {cartButtons}
                    </div>
                }
            </Col>
        </Row>
    </Spin>
}

export default ProductDetails
export { Name, Image, BreakfastName }