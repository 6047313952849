import React, { useState, useEffect } from 'react';
import PageWrapper, { Container, H2, Lead } from './PageWrapper'
import { Form, Button, Input, Result } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons';
import axios from 'axios'

const HelpPage = () => {
    const [msgSend, setMsgSend] = useState(false)

    const submitMessage = (values) => {
        return axios.post('/api/contact-form/email', {
            message: values.message,
            from: values.from,
            customer_type: 'consumer'
        }).then(() => {
            setMsgSend(true)
        }).catch(() => {
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return <PageWrapper headerButtons={<></>}>

        <Container style={{ paddingTop: 100, paddingBottom: 100, maxWidth: 600, textAlign: "center" }}>
            <H2>Potrzebujesz pomocy<br /> albo chcesz się z nami skontaktować?</H2>
            <Lead style={{ marginBottom: 26 }}>Napisz do nas i pomóż nam w tworzeniu społeczności Fablo!</Lead>
            <Lead style={{ marginBottom: 52 }}>Pracujemy w tygodniu w godzinach 9 - 17.</Lead>

            {!msgSend && <Form
                layout="vertical"
                style={{ maxWidth: 500, margin: "0 auto" }}
                onFinish={submitMessage}
                requiredMark={false}
            >
                <Form.Item
                    label="Adres email:"
                    name="from"
                    rules={[{
                        required: true,
                        message: 'Podaj swój adres email',
                    },{
                        type: 'email',
                        message: 'Wprowadź poprawny adres email',
                    }]}
                >
                    <Input
                        placeholder="imie@mail.com"
                        style={{
                            background: '#fcfcfc',
                            borderColor: '#ececec',
                        }}
                    />
                </Form.Item>
                <Form.Item
                    label="Treść wiadomości:"
                    name="message"
                    rules={[{
                        required: true,
                        message: 'Wpisz swoją wiadomość',
                    }]}
                >
                    <Input.TextArea
                        placeholder="Wiadomość"
                        rows={5}
                        style={{
                            background: '#fcfcfc',
                            borderColor: '#ececec',
                        }}
                    />
                </Form.Item>

                <Button type="primary" size="large" htmlType="submit" style={{ width: "100%", backgroundColor: "#2E773A", borderColor: "#2E773A" }}>
                    WYŚLIJ WIADOMOŚĆ
                </Button>
            </Form>}
            {msgSend && <Result
                status="success"
                title="Wiadomość wysłana!"
                subTitle="Odpowiemy jak najszybciej."
                icon={<CheckCircleFilled style={{color: "#3C6E51"}}/>}
            />}
        </Container>
    </PageWrapper>
}

export default HelpPage
