import React, { } from 'react';
import PageWrapper, { Container } from './PageWrapper'
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return <PageWrapper>
    <Container className="padding">
      <Result
        status="404"
        title="404"
        subTitle="Strona nie istnieje"
        extra={<Link to="/"><Button type="primary">WRÓĆ NA STRONĘ GŁÓWNĄ</Button></Link>}
      />
    </Container>
  </PageWrapper>
}

export default NotFoundPage
