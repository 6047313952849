import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react'
import { inject, observer } from 'mobx-react'
import {
  Button as AntButton,
  Modal,
  Input as AntInput,
  Spin,
} from 'antd'
import { PlusOutlined, MinusOutlined, QrcodeOutlined } from '@ant-design/icons';
import styled, { css, createGlobalStyle } from 'styled-components'
import { state } from '../models/AppState'
import { Link, } from "react-router-dom";


const LangSwitch = inject('state')(observer(({ state }) => {
  return <div style={{ fontSize: '16px' }}>
    <a
      onClick={() => {
        const lang = state.lang == 'en' ? 'pl' : 'en'
        state.setLang(lang)
        gaEvent('lang_changed', { lang: lang })
      }}
      style={{ color: 'var(--primary)', fontWeight: 500 }}
    >{state.lang == 'en' ? "POLISH" : "ENGLISH"} ▸</a>
  </div>
}))

const Header = ({ hotelId }) => {
  return <div style={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }}>
    <Link to="/hotel">
      <img
        src={`/api/shop/${hotelId}/image`}
        style={{ maxWidth: 120, maxHeight: 52, margin: 4 }}
      />
    </Link>
    <LangSwitch />
  </div>
}

const Header2 = ({ hotelId }) => {
  return <div style={{ textAlign: 'center' }}>
    <LangSwitch />
    <Link to="/hotel">
      <img
        src={`/api/shop/${hotelId}/image`}
        style={{ maxWidth: 250, marginTop: 10, maxHeight: 150, marginBottom: 36 }}
      />
    </Link>
  </div>
}

const HeadText = styled.p`
  font-weight: bold;
  color: var(--primary);
  margin-bottom: 0;
  padding: 0 24px 24px;
  font-size: 26px;
  line-height: 1.2em;
`

const ContactButton = () => (
  <a
    href={"tel:" + (state.currentStoreData && state.currentStoreData.phone)}
    onClick={() => gaEvent('contact_frontdesk')}
    style={{
      padding: '14px 24px',
      display: 'block',
      border: '2px solid var(--primary)',
      borderRadius: 20,
      color: 'var(--primary)',
      maxWidth: 260,
      fontWeight: 600,
      margin: '12px auto',
      textAlign: 'left',
    }}
  >
    <i className="icon-Phone-2" style={{ fontSize: 52, float: 'left', marginRight: 24, }} />
    {t({
      pl: 'Poinformuj',
      en: 'Contact'
    })}<br />{t({ pl: 'Recepcję', en: 'Front desk' })}
  </a>
)

const Wrapper = styled.div`
max-width: 600px;
margin: 0 auto;
font-family: 'FS Lola';
//--primary: #509E2F;
font-size: 20px;
padding: 12px;
`
const GlobalStyle = createGlobalStyle`
.ant-btn-primary {
  background: var(--primary);
  border-color: var(--primary);
  &:hover { color: #fff; }
  &:focus {
    background: var(--primary);
    border-color: var(--primary);
  }
}
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: var(--primary);
}

.ant-form-item-label > label {
  font-size: 16px;
}

/* temporary */
@font-face {
  font-family: 'FS Lola';
  src: url("/css/FSLola.eot");
  src: url("/css/FSLola.eot?#iefix") format("embedded-opentype"), url("/css/FSLola.woff2") format("woff2"), url("/css/FSLola.woff") format("woff"), url("/css/FSLola.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'FS Lola';
  src: url("/css/FSLola-Medium.eot");
  src: url("/css/FSLola-Medium.eot?#iefix") format("embedded-opentype"), url("/css/FSLola-Medium.woff2") format("woff2"), url("/css/FSLola-Medium.woff") format("woff"), url("/css/FSLola-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

  @font-face {
    font-family: 'FS Lola';
    src: url("/css/FSLola-MediumItalic.eot");
    src: url("/css/FSLola-MediumItalic.eot?#iefix") format("embedded-opentype"), url("/css/FSLola-MediumItalic.woff2") format("woff2"), url("/css/FSLola-MediumItalic.woff") format("woff"), url("/css/FSLola-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-style: italic; }

`

const useGAScreen = (name) => {
  return useEffect(() => {
    window.gtag('event', 'screen_view', {
      'screen_name': name
    })
  }, [])
}
const gaEvent = (name, params) => {
  window.gtag('event', name, params)
}
const gaSet = (vals) => {
  window.gtag('set', vals)
}

const getCookieValue = (name) => {
  try {
    return decodeURIComponent(document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '')
  } catch (e) {
    return ''
  }
}

const readQrCodeCookie = () => {
  try {
    return JSON.parse(getCookieValue('qrcode') || '{}')
  } catch (e) {
    return {}
  }
}

const t = (x) => {
  const lang = state.lang
  return x[lang] || x['pl'] || x['en'] || ''
}

// --primary #509E2F
const Button = styled(AntButton)`
&:hover, &:focus {
  border-color: var(--primary);
  color: var(--primary);
}
&.ant-btn-primary {
  background: var(--primary);
  border-color: var(--primary);
  &:hover { color: #fff; }
}
&.ant-btn-link:focus {
  border-color: transparent;
}
&.ant-btn-lg {
  font-size: 20px;
  height: 48px;
}
&.ant-btn-primary[disabled] {
  background: #f5f5f5;
  border-color: #d9d9d9;
  color: #d9d9d9;
}
`

const CartButton = ({ limit, inCart, setInCart, addDisabled }) => {
  return <div style={{ display: 'flex' }}>
    <Button
      shape="circle"
      icon={<MinusOutlined />}
      disabled={inCart <= 0}
      onClick={() => setInCart(inCart - 1)}
    />
    <div style={{
      flex: 1,
      fontSize: 24,
      display: 'inline-block',
      textAlign: 'center',
    }}>{inCart}</div>
    <Button
      shape="circle"
      icon={<PlusOutlined />}
      onClick={(e) => setInCart(inCart + 1)}
      disabled={inCart >= limit || addDisabled}
    />
  </div>
}

const ScanQRAgain = () => {
  useGAScreen('ScanQRAgainScreen')

  return <Wrapper style={{
    paddingTop: 100,
    textAlign: 'center',
  }}>
    <QrcodeOutlined style={{ fontSize: 64, marginBottom: 50 }} />
    <h2>Zeskanuj ponownie kod QR</h2>
    <h2>Scan the QR code again</h2>
  </Wrapper>
}

export {
  LangSwitch,
  Header,
  Header2,
  ContactButton,
  Wrapper,
  GlobalStyle,
  useGAScreen,
  gaEvent,
  gaSet,
  readQrCodeCookie,
  t,
  HeadText,
  CartButton,
  Button,
  ScanQRAgain,
}
