const gaTrackingID = 'UA-164379232-1'
window.gtag('config', gaTrackingID, {cookie_domain: 'fablo.pl'})

const pageview = (page_path) => {
    window.gtag('config', gaTrackingID, {page_path: (page_path || window.location.pathname + window.location.search)})
}

export {
    pageview
}
