import React, { useEffect, useState } from 'react';
import { Layout, Input, Button, Drawer, Affix, Badge, Empty } from 'antd';
import ProductTile from './ProductTile';
import styled from 'styled-components';
import { inject, observer } from 'mobx-react';
import { SearchOutlined, HomeOutlined, ShoppingCartOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { CartProducts } from './CartProducts'
import InfiniteScroll from 'react-infinite-scroller';
import { Link, useParams, useHistory } from 'react-router-dom';
import CategoriesMenu from './CategoriesMenu'
import { pathTo, categoryUrlToPath } from '../routes'
import { useQuery } from '../utils'

const { Content } = Layout;

const ProductsContainer = styled.div`
& > div {
display: flex;
flex-wrap: wrap;
justify-content: center;
max-width: 1280px;
margin: 0 auto;
}

.layout-big & {
    background-color: #fff;
}
`;

const Header = styled.div`
background: rgba(255,255,255,0.95);
box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px;
z-index: 1;
padding: 12px 12px 24px;
`;

const SearchWrapper = styled.div`
flex: 1;
max-width: 1024px;
`;

const SearchInput = styled(Input)`
background: #f0f2f5;
border-radius: 12px;
padding: 12px;
max-width: 635px;
input { font-size: 16px; }
`;

const LocationLine = styled.div`
margin-bottom: 12px;
height: 22px;
overflow: hidden;
transition: height 0.1s, margin-bottom 0.1s;

display: flex;
flex-direction: row;
align-items: baseline;
max-width: 95vw;

> * {
    margin-right: 5px;
    white-space: nowrap;
    flex-shrink: 0;
}
> .name {
    flex-shrink: 1;
    text-overflow: ellipsis;
    overflow: hidden;
}

.ant-affix & {
    height: 0;
    margin-bottom: 0;
}
`;

const FlexH = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
`

const CartButton = styled(Button)`
height: 100% !important;
border-radius: 12px !important;
margin-left: 12px;
padding: 12px 20px !important;
`;

const HeaderShopImg = styled.img`
height: 82px;
width: 120px;
object-fit: cover;
margin-right: 24px;
border-radius: 6px;
@media (max-width: 460px) {
    display: none;
}
transition: height 0.1s, box-shadow 0.3s;
cursor: pointer;
.ant-affix & {
    height: 48px;
}
`

const TilePlaceholder = styled.div`
width: 204px;
.layout-big & {
    width: 392px;
}
`

const pageSize = 20

const BreakfastCartButton = styled(Button)`
border-radius: 12px !important;
font-weight: 500;
font-family: 'FS Lola';
&.ant-btn-round.ant-btn-lg {
    font-size: 22px;
    height: 50px;
}
&.ant-btn-primary {
    background: #509E2F;
    border-color: #509E2F;
}
`

const Cart = inject('state')(observer(({ state, showCart }) => {
    const { currentStore, cart } = state;
    const store = state.currentStoreData

    if(store.cart_disabled) {
        return <div/>
    }

    const inCart = Object.keys(cart[currentStore] || {}).length

    // temporary? for demo presentation
    const hotel = store.flags.hotel
    const breakfast = state.currentStoreData && state.currentStoreData.flags.breakfast

    return <Badge count={inCart}>
        {!breakfast &&
            <CartButton
                type={inCart > 0 ? "primary" : "default"}
                shape="round"
                icon={<ShoppingCartOutlined />}
                size='large'
                onClick={showCart}
            >
                {!hotel ? "Twój koszyk" : "Twoje zamówienie"}
            </CartButton>
        }
        {breakfast &&
            <BreakfastCartButton
                type={inCart > 0 ? "primary" : "default"}
                shape="round"
                size='large'
                disabled={inCart === 0}
                onClick={showCart}
            >Your order</BreakfastCartButton>
        }
    </Badge>
}))

const PageHeader = inject('state')(observer(({ state, showCart }) => {

    const { currentStore, stores } = state;

    const store = stores[currentStore]
    const history = useHistory()
    const params = useParams()
    const url_phrase = params.search_phrase && decodeURIComponent(params.search_phrase)


    const search = (phrase, now) => {
        if(now) {
            state.setSearchNow(phrase)
        } else {
            state.setSearch(phrase)
        }
        if(!phrase) {
            history.replace(pathTo('products'))
        } else {
            if(!url_phrase) {
                history.push(pathTo('search', {search_phrase: encodeURIComponent(phrase)}))
            } else {
                history.replace(pathTo('search', {search_phrase: encodeURIComponent(phrase)}))
            }
        }
    }

    return <Affix>
        <Header>
            <FlexH style={{ justifyContent: 'center' }}>
                {store &&
                    <Link to={`/${store.slug}`}>
                        <HeaderShopImg
                            className="ant-card-hoverable"
                            src={`/api/shop/${store.id}/image`}
                        />
                    </Link>}
                <SearchWrapper>
                    <LocationLine>
                        {store &&
                            <>
                                <HomeOutlined />
                                <span className="name"><b>{store.name}</b>, {store.street}, {store.city}</span>
                                <small><Link to="/">wróć</Link></small>
                            </>}
                    </LocationLine>
                    <FlexH>
                        <SearchInput
                            placeholder="Wyszukaj w sklepie..."
                            prefix={<SearchOutlined />}
                            size="large"
                            id="search-input"
                            value={state.searchPhrase}
                            type="search"
                            onChange={(e) => search(e.target.value, false)}
                            onPressEnter={(e) => search(e.target.value, true)}
                            allowClear={true}
                        />
                        <Cart showCart={showCart} />
                    </FlexH>
                </SearchWrapper>
            </FlexH>
        </Header>
    </Affix>
}))

const BreakfastHeader = () => {
    return <Header>
        <FlexH>
        <img
            src="https://digital.ihg.com/is/image/ihg/holiday-inn_lsc_lkp_d_r_rgb_pos-web?fmt=png-alpha"
            style={{maxWidth: 300, height: 45}}
        />
        <img
            src="https://upload.wikimedia.org/wikipedia/commons/a/ae/Flag_of_the_United_Kingdom.svg"
            style={{maxWidth: 300, height: 35}}
        />
        </FlexH>
        <p style={{
            fontFamily: 'FS Lola',
            fontWeight: 500,
            fontStyle: 'italic',
            color: '#509E2F',
            textAlign: 'center',
            fontSize: 32,
            margin: '24px 0 0',
        }}>Your breakfast order</p>
    </Header>
}

const Products = inject('state')(observer(({ state }) => {
    const [page, setPage] = useState(1)
    const products = state.currentProducts;

    return <div>
        <ProductsContainer>
            {/* ant design list instead?
                or react-window
            */}
            <InfiniteScroll
                loadMore={setPage}
                hasMore={page * pageSize < products.length}
                pageStart={1}
            >
                {products.slice(0, page * pageSize).map((p, i) =>
                    <ProductTile
                        product={p}
                        key={i}
                    />
                )}
                {Array(10).fill(1).map((_, i) => <TilePlaceholder key={`ph${i}`} />)}
            </InfiniteScroll>
        </ProductsContainer>
        {state.searchActive && products.length === 0 &&
            <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<>Nie znaleźliśmy żadnych produktów pasujących do wyszukiwania.<br />Spróbuj skorzystać z kategorii bądź wpisz inną frazę.</>}
                style={{ margin: "200px 0" }}
            />
        }
    </div>
}))

const ProductsPage = inject('state')(observer(({ state }) => {

    const params = useParams()
    const queryParams = useQuery()
    const history = useHistory()

    const cartVisible = !!queryParams.koszyk
    const showCart = () => {
        const params = new URLSearchParams(history.location.search)
        params.set('koszyk', '1')
        history.push(history.location.pathname + '?' +params)
    }
    const hideCart = () => {
        const params = new URLSearchParams(history.location.search)
        params.delete('koszyk')
        history.push(history.location.pathname + (params ? `?${params}` : ''))
    }

    const categoryFilter = (params.category ? categoryUrlToPath(params.category) : []).join('>>')
    const searchPhrase = params.search_phrase && decodeURIComponent(params.search_phrase)
    useEffect(() => {
        if(searchPhrase !== state.searchPhrase) {
            state.setSearchNow(searchPhrase)
        }
    }, [searchPhrase])
    useEffect(() => {
        if(state.categoryFilter !== categoryFilter) {
            state.setCategoryFilter(categoryFilter)
        }
    }, [categoryFilter])

    const products = state.currentProducts;
    const bigLayout = state.currentStoreData && state.currentStoreData.layout === "big"

    // todo: move details popup away from ProductTile
    const openedProduct = products.find((p) => p.id === queryParams.p)

    // temporary? for demo presentation
    const hotel = state.currentStoreData && state.currentStoreData.flags.hotel
    const breakfast = state.currentStoreData && state.currentStoreData.flags.breakfast

    return <Layout className={"layout " + (bigLayout ? "layout-big" : "layout-small")}>

        {!breakfast &&
            <PageHeader
                showCart={showCart}
            />
        }
        {breakfast &&
            <BreakfastHeader />
        }

        {openedProduct && <div style={{ display: 'none' }}>
            <ProductTile
                product={openedProduct}
                openedDetails={true}
            />
        </div>}

        <Layout style={bigLayout
            ? { paddingBottom: 0, minHeight: "calc(100vh - 121px)", background: '#fff' }
            : { paddingBottom: 50, minHeight: "calc(100vh - 121px)" }}>
            <CategoriesMenu categories={state.categories} />

            <Content>
                <Products />
            </Content>
        </Layout>

        {breakfast && <Affix offsetBottom={0}>
            <Header style={{
                padding: 12,
                textAlign: 'center',
                boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 2px'
            }}>
                <Cart showCart={showCart} />
            </Header>
        </Affix>}

        <Drawer
            title={!hotel
                ? "Zawartość Twojego koszyka"
                : (breakfast
                    ? <span style={{fontFamily: 'FS Lola', fontSize: 18}}>Your Breakfast</span>
                    : "Zawartość Twojego zamówienia")}
            placement="right"
            closable={true}
            onClose={hideCart}
            visible={cartVisible}
            className="drawerFlex"
            width={500}
            drawerStyle={{ maxWidth: "100vw", marginRight: 0, marginLeft: 'auto', }}
            bodyStyle={{ paddingTop: 0 }}
            closeIcon={<CloseCircleOutlined style={{ color: (!breakfast ? 'var(--primary)' : '#509E2F'), fontSize: 19, marginTop: -4 }} />}
        >
            <CartProducts />
        </Drawer>

    </Layout>
}))

export default ProductsPage
export {
    Products
}