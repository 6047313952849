import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Modal, Divider, Row, Col, Steps, Checkbox, Radio, message } from 'antd';
import { inject, observer } from 'mobx-react';
import { ShoppingCartOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { imageUrl, formatPrice, formatQuantity, quantityStep, imageErrorHandler, useQuery } from '../utils'
import ProductDetails from './ProductDetails';
import * as ProductDetailsModal from './ProductDetails';
import { Link, useHistory } from 'react-router-dom';

import { t } from '../hotel/Common'

const Wrapper = styled.div`
margin: 12px;
padding: 12px;
border-radius: 6px;
background: #fff;
width: 180px;
box-shadow: rgba(0, 0, 0, 0.03) 0px 1px 2px;
text-align: center;

@media (max-width: 600px) {
    width: calc(50% - 12px);
    margin: 6px;
}

.layout-big & {
    background: #fafafa;
    width: 380px;
    margin: 6px;
    padding: 26px;
}

`;

const ImageWrapper = styled.div`
padding: 0 6px;
height: 150px;
display: flex;
justify-content: center;
align-items: center;

.layout-big & {
    height: 240px;
}

`;

const Image = styled.img`
max-width: 100%;
max-height: 100%;
object-fit: contain;
`

const Name = styled.p`
font-weight: 400;
font-size: 13px;
line-height: 1.2em;
height: 3.5em;
overflow: hidden;
margin: 6px 0 12px;
text-align: center;

.layout-big & {
    font-size: 14px;
    margin: 13px 0 12px;
}
`

const Price = styled.p`
font-size: 16px;
line-height: 1.2em;
margin: 12px 0px;
color: var(--primary);
text-align: center;

.layout-big & {
    font-size: 19px;
    color: #252525;
    margin-bottom: 20px;
}

`

const InCartBox = styled.div`
display: inline-flex;
justify-content: space-between;
align-items: center;
text-align: center;
&:hover {
    background-color: var(--primary);
}
> span {
    width: 100%;
}
`
const CartChangeBtn = styled.div`
border-radius: 6px;
padding: 8px 12px;
margin: 0 -24px;
&:hover {
    background-color: rgba(255,255,255,0.3);
}
`

const CustomizationModal = ({ product, storeData, quantity, setInCart, onClose }) => {
    const customizations = (product.customizations || []).filter(x => x.type && x.title)
    const { Image } = ProductDetailsModal
    const { Step } = Steps

    const [step, setStep] = useState(0)
    const [selected, setSelected] = useState({})

    const breakfastVersion = storeData && storeData.flags.breakfast
    const Name = !breakfastVersion ? ProductDetailsModal.Name : ProductDetailsModal.BreakfastName

    const Choice = ({ customization, value, onChange }) => {
        let Group, Item, val
        if (customization.type === 'single') {
            Group = Radio.Group
            Item = Radio
            val = value && value[0]
        } else {
            Group = Checkbox.Group
            Item = Checkbox
            val = value
        }
        return <div style={{textAlign: 'center', padding: "20px 0 40px"}}>
            <Group
                style={{textAlign: 'left'}}
                value={val}
                onChange={x => {
                    if(Array.isArray(x)) onChange(x)
                    else onChange([x.target.value])
                }}
            >
                {(customization.options || []).map((x, i) => <Item
                    key={i}
                    value={x.label}
                    style={{ display: 'block', lineHeight: "30px", margin: 0, fontSize: 16, whiteSpace: 'normal'}}
                >
                    {x.label} {x.price ? `(${formatPrice(x.price)} zł)` : ''}
                </Item>)}
            </Group>
        </div>
    }

    const nextStep = () => {
        if(step < customizations.length - 1) {
            setStep(step + 1)
        } else {
            setInCart(product, quantity, selected)
            onClose(true)
        }
    }

    const verticalSteps = (customizations.length > 3 || customizations.length === 1)

    return <Modal
        visible={true}
        onCancel={() => onClose(false)}
        footer={null}
        width="min(100%, 800px)"
        style={breakfastVersion ? { top: 12 } : {}}
    >
        <Name>{product.name}</Name>
        {!breakfastVersion && <Divider/>}
        <Row gutter={16}>
            {!breakfastVersion &&
            <Col xs={24} md={8}>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: "100%" }}>
                    <Image
                        src={imageUrl(product)}
                        onError={imageErrorHandler}
                    />
                    <div style={{ margin: "0 auto", width: "160px" }}>
                        <Price>{formatPrice(product.price)} zł / {product.unit || 'szt.'}</Price>
                    </div>
                </div>
            </Col>
            }
            <Col xs={24} md={!breakfastVersion ? 16 : 24}>
                <Steps
                    style={(breakfastVersion && !verticalSteps) ? {margin: '0 -22px'} : {}}
                    progressDot
                    current={step}
                    direction={verticalSteps ? "vertical" : "horizontal"}
                >
                    {customizations.map((x) => <Step key={x.id} title={x.title} />)}
                </Steps>
                {customizations[step] && <Choice
                    customization={customizations[step]}
                    value={selected[customizations[step].id]}
                    onChange={x => setSelected({...selected, [customizations[step].id]: x})}
                />}
                <div style={{textAlign: 'center'}}>
                    {step > 0 &&
                    <Button

                        onClick={() => setStep(step - 1)}
                        style={breakfastVersion ? {
                            fontSize: 22,
                            height: 52,
                            fontFamily: 'FS Lola',
                            fontWeight: 500,
                            marginRight: 12,
                        } : {marginRight: 12}}
                    >
                        {!breakfastVersion ? 'WRÓĆ' : 'BACK'}
                    </Button>}
                    <Button
                        type={!breakfastVersion && "primary"}
                        onClick={nextStep}
                        style={breakfastVersion ? {
                            fontSize: 22,
                            height: 52,
                            fontFamily: 'FS Lola',
                            fontWeight: 500,
                        } : {}}
                    >
                        {!breakfastVersion ? 'DALEJ' : 'OK'}
                    </Button>
                </div>
            </Col>
        </Row>
    </Modal>
}

const CartButton = observer(({ product, setInCart, cart, currentStore, currentStoreData }) => {
    const cartEntry = (cart[currentStore] || {})[product.id]
    const inCart = (!cartEntry || typeof cartEntry === 'number') ? cartEntry : Object.values(cartEntry).reduce((a, b) => a + b, 0)
    const unit = product.unit || 'szt.';
    const quantity_step = quantityStep(unit);
    const [customizationModal, openCustomizationModal] = useState(false)
    const breakfast = currentStoreData && currentStoreData.flags.breakfast

    const history = useHistory()

    var button = <></>

    const addToCart = (product, quantity) => {
        if (product.customizations && product.customizations.length) {
            openCustomizationModal(true)
        } else {
            setInCart(product, quantity)
        }
    }

    if (!inCart) {
        button = <Button
            type="default"
            //shape="round"
            icon={<ShoppingCartOutlined />}
            size={!breakfast ? 'medium' : 'large'}
            onClick={() => addToCart(product, quantity_step)}
            style={!breakfast
                ? { width: '100%', maxWidth: 200 }
                : { width: '100%', maxWidth: 200,
                    fontSize: 22,
                    height: 50,
                    fontFamily: 'FS Lola',
                    fontWeight: 500,
                  }
            }
        >
            {!breakfast && t({pl: 'Do koszyka', en: 'ADD'})}
            {breakfast && (product.customizations ? 'COMPOSE' : 'ORDER')}
        </Button>
    } else {
        button = <InCartBox
            className="ant-btn ant-btn-primary"
            style={!breakfast
                ? { width: '100%', maxWidth: 200 }
                : { width: '100%', maxWidth: 200, background: '#509E2F', borderColor: '#509E2F' }}
        >
            {!product.options &&
            <CartChangeBtn onClick={() => setInCart(product, inCart - quantity_step)}>
                <MinusOutlined />
            </CartChangeBtn>}
            {!breakfast &&<span>{formatQuantity(inCart)} {t({pl: `${unit} w`, en: 'in'})} <ShoppingCartOutlined /></span>}
            {breakfast &&<span>{formatQuantity(inCart)} in <ShoppingCartOutlined /></span>}
            <CartChangeBtn onClick={() => addToCart(product, inCart + quantity_step)}>
                <PlusOutlined />
            </CartChangeBtn>
        </InCartBox>
    }
    return <>
        {button}
        {customizationModal && <CustomizationModal
            product={product}
            storeData={currentStoreData}
            quantity={quantity_step}
            setInCart={setInCart}
            onClose={(added) => {
                openCustomizationModal(false)
                if(breakfast && added) {
                    history.push(history.location.pathname.replace(/\??p=\d+/, ''))
                    message.success(<>Great choice!<br/> Your breakfast has been added to the order</>)
                }
            }}
        />}
    </>

})


const BreakfastTile = ({product}) => {
    return <div>
        <Link
            style={{ color: 'inherit' }}
            to={location => `${location.pathname}?p=${product.id}`}
        >
            <ImageWrapper style={{height: 'auto', padding: 0 }}>
                <Image
                    src={imageUrl(product)}
                    onError={imageErrorHandler}
                    style={{height: 150, width: '100%', objectFit: 'cover', objectPosition: 'center'}}
                />
            </ImageWrapper>
            <div style={{
                display: 'flex',
                color: '#509E2F',
                fontSize: 24,
                fontWeight: 500,
                fontFamily: 'FS Lola',
                marginTop: 26,
                justifyContent: 'space-between',
                textAlign: 'left',
            }}>
                <div>{product.name}</div>
                {product.id != 3 &&
                    <div style={{whiteSpace: 'nowrap'}}>{formatPrice(product.price).replace(',00', '')} zł</div>
                }
            </div>
        </Link>
    </div>
}


const ProductsTile = inject('state')(observer(({ state, product }) => {
    const { setInCart, cart, currentStore, currentStoreData } = state
    const queryParams = useQuery()
    const history = useHistory()
    const detailsVisible = product.id === queryParams.p
    const breakfast = state.currentStoreData && state.currentStoreData.flags.breakfast

    const Cart = <>
        {!breakfast && <Price>{formatPrice(product.price)} zł / {product.unit || 'szt.'}</Price>}
        {!currentStoreData?.cart_disabled &&
            <CartButton product={product} setInCart={setInCart} cart={cart} currentStore={currentStore} currentStoreData={currentStoreData} />
        }
    </>

    return <Wrapper style={breakfast ? {
        borderRadius: 0,
        border: '2px solid #ddd'
    } : {}}>
        {!breakfast && <>
        <Link
            style={{ color: 'inherit' }}
            to={location => `${location.pathname}?p=${product.id}`}
        >
            <ImageWrapper>
                <Image
                    src={imageUrl(product)}
                    onError={imageErrorHandler}
                />
            </ImageWrapper>
            <Name>{product.name}</Name>
        </Link>
        {Cart}
        </>}
        {breakfast && <BreakfastTile product={product}/>}

        <Modal
            visible={detailsVisible}
            onCancel={() => {
                history.push(history.location.pathname.replace(/\??p=\d+/, ''))
            }}
            footer={null}
            width="min(100%, 800px)"
            style={breakfast ? { top: 12 } : {}}
        >
            <ProductDetails product={product} cartButtons={Cart} breakfastVersion={breakfast}/>
        </Modal>
    </Wrapper>
}))

export default ProductsTile
