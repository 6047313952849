
// temporary solution, to be splitted info independend files for each env

const prod = {
  panel_url: '//my.fablo.pl/',
}

const staging = {
  panel_url: '//panel.demo.fablo.pl/',
}

const dev = {
  ...staging,
  panel_url: '//my.fablo.wip/',
}

const host = window.location.hostname
const val = {
  'fablo.wip': dev,
  'demo.fablo.pl': staging,
  'staging.fablo.pl': staging,
  'fablo.pl': prod
}[host]

export default val || prod
