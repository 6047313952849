import { generatePath } from "react-router"
import { state } from './models/AppState'

const routes = {
  order: "/:shop_id(\\d+)/:shop_slug/zamowienie/:order_id(\\d+)/:token",
  search: "/:shop_id(\\d+)/:shop_slug/produkty/szukaj/:search_phrase",
  category: "/:shop_id(\\d+)/:shop_slug/produkty/kategoria/:category",
  products: "/:shop_id(\\d+)/:shop_slug/produkty",
  shopCard: "/:shop_id(\\d+)/:shop_slug/",
}

const pathTo = (routeId, params) => {
  return generatePath(routes[routeId], {
    shop_id: '' + state.currentStore,
    shop_slug: state.currentStoreSlug,
    ...params,
  })
}

const categorySeparator = '→'
const categoryToUrl = (path) => path.join(categorySeparator).replace(/ /g, '-')
const categoryUrlToPath = (url) => url.replace(/-/g, ' ').split(categorySeparator)


export {
  routes,
  pathTo,
  categoryToUrl,
  categoryUrlToPath
}
