import React, { useLayoutEffect, useRef } from 'react';
import PageWrapper, { Container, H2, H3, H4 } from './PageWrapper'
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`

ol.main {
  list-style-position: inside;

  > li {
    margin: 0 0 26px;
    font-size: 25px;
    line-height: 1.5em;
    font-weight: 300;
    color: #252525;
    margin: 0 0 26px;

    @media (max-width: 767px) {
        font-size: 17px;
        line-height: 32px;
    }
  }
}

.nested-numbered-list {
  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }

  ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }

  ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
  }

  li ol > li {
    margin: 0;
  }

  li ol > li:before {
    content: counters(item, ".") " ";
  }
}

`

const LegalPage = () => {
  const location = useLocation()
  const refs = {
    prywatnosc: useRef(),
    cookies: useRef(),
    'dla-sprzedawcow': useRef(),
    'dla-uzytkownikow': useRef(),
  }
  useLayoutEffect(() => {
    if (location.hash) {
      const r = refs[location.hash.substr(1)]
      if (r) {
        r.current.scrollIntoView(true)
      }
    }
  }, [location.hash])

  return <PageWrapper>
    <Wrapper>
      <Container className="padding" ref={refs.prywatnosc}>
        <H2>Polityka Prywatności</H2>

        <ol className="main">
          <li>Administrator danych osobowych</li>
          <p>Administratorem danych osobowych przetwarzanych w związku z korzystaniem z Fablo i usług tam świadczonych jest Retailic sp. z o.o. z siedzibą w Warszawie, ul. Dembowskiego 19 lok. 26, 02-784 Warszawa, wpisana do Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m. st. Warszawy w Warszawie, XIII Wydział Gospodarczy KRS, pod numerem KRS 0000357852, NIP 9512317035, REGON 142434470, z kapitałem zakładowym wynoszącym 5 000 zł („<b>Fablo</b>”).</p>

          <li>Kontakt z administratorem</li>
          <p>W razie pytań dotyczących przetwarzania danych osobowych możesz skontaktować się z nami pod adresem e-mail: prywatnosc@fablo.pl.</p>

          <li>Rodzaje przetwarzanych danych osobowych, cele i podstawy prawne przetwarzania danych osobowych</li>
          <p>Fablo przetwarza dane osobowe w następujących celach:</p>
          <ol style={{ listStyleType: 'lower-latin' }}>
            <li>Rejestracja i obsługa kont sprzedawców</li>
            <p>Sprzedawanie produktów poprzez Fablo wymaga założenia konta. W tym celu przetwarzamy następujące dane osobowe sprzedawców: imię, nazwisko, nazwa firmy, ulica, numer budynku, miasto, kod pocztowy, numer NIP, adres e-mail, numer telefonu, nazwa użytkownika, hasło.</p>
            <p>Podanie tych danych jest niezbędne w celu prezentacji sprzedawcy i jego oferty handlowej oraz dokonywania sprzedaży za pośrednictwem Fablo oraz rozliczania finansowego z Fablo.</p>
            <p>Podstawa prawna: art. 6 ust. 1 lit. b) RODO – niezbędność do podjęcia działań na żądanie przed zawarciem umowy oraz do wykonania umowy.</p>

            <li>Obsługa transakcji użytkowników</li>
            <p>Poprzez stronę Fablo użytkownicy mogą kupować i rezerwować produkty oferowane przez sprzedawców. Fablo przetwarza dane osobowe podane przy składaniu zamówień składanych w tym: email, numer telefonu, informacje o sposobie płatności.</p>
            <p>Podanie tych danych jest niezbędne w celu przeprowadzenia transakcji.</p>
            <p>Podstawa prawna: art. 6 ust. 1 lit. b) RODO – niezbędność do podjęcia działań na żądanie przed zawarciem umowy oraz do wykonania umowy.</p>

            <li>Marketing Fablo oraz sprzedawców</li>
            <p>Fablo może przetwarzać dane osobowe w celu wyświetlania dopasowanych profili sprzedawców oraz ofert, a także wysyłania newsletterów (o ile wyrażono zgodę na ich otrzymywanie).</p>
            <p>Podstawa prawna: art. 6 ust. 1 lit. f) RODO – nasz prawnie uzasadniony interes polegający na marketingu naszych usług oraz usług i towarów sprzedawców.</p>

            <li>Realizacja obowiązków ustawowych wynikających z przepisów podatkowych i rachunkowych w zakresie rozliczenia</li>
            <p>Fablo przetwarza dane osobowe niezbędne do dokonania rozliczeń wynikających z przepisów podatkowych i rachunkowych.</p>
            <p>Podstawa prawna: Art. 6 ust.1 lit. c RODO – niezbędność przetwarzania do wykonania obowiązku prawnego.</p>

            <li>Zapewnienie bezpieczeństwa usług i prowadzenie statystyk ich wykorzystania</li>
            <p>Podczas korzystania z naszych usług zbieramy również informacje automatycznie i przechowujemy je w tzw. log files. Informacje te mogą obejmować adres komputera lub urządzenia (IP), typ przeglądarki, dostawcę usług internetowych, informacje o stronach odsyłających/wyjściowych, systemie operacyjnym, znaczniku daty lub godziny jak i danych dotyczących kliknięć.</p>
            <p>W Fablo przetwarzamy te dane osobowe w celu analizy trendów i śledzenia ruchów na stronie internetowej oraz w celu gromadzenia informacji o bazie użytkowników i sprzedawców.</p>
            <p>W celu oceny naszej działalności możemy korzystać także z dodatkowych zanonimizowanych statystyk prowadzonych przez zewnętrznego usługodawcę tego typu usług. Więcej informacji znajdziesz w <a href="#cookies">Polityce plików cookies.</a></p>
            <p>Podstawa prawna: art. 6 ust. 1 lit. f) RODO – nasz prawnie uzasadniony interes polegający na poprawie funkcjonalności usług, zapewnieniu bezpieczeństwa z ich korzystania oraz administrowaniu.</p>

            <li>Udzielanie odpowiedzi na pytania i reklamacje</li>
            <p>Możesz zwrócić się do nas z pytaniem lub sugestią lub jakąkolwiek inną sprawą dotyczącą świadczenia usług za pośrednictwem Fablo. W takim przypadku możemy przetwarzać Twoje dane identyfikacyjne, kontaktowe oraz będące przedmiotem Twojego zapytania.</p>
            <p>Podstawa prawna: art. 6 ust. 1 lit. f) RODO – nasz prawnie uzasadniony interes polegający na zapewnieniu sprawnego kontaktu oraz rozpatrywaniu Twoich reklamacji i zapytań.</p>

            <li>Roszczenia – ustalenie, dochodzenie, obrona</li>
            <p>W przypadku konieczności weryfikacji okoliczności faktycznych lub innych związanych ze świadczeniem naszych usług na potrzeby prowadzenia ewentualnych postępowań, możemy przetwarzać Twoje dane osobowe w tym celu.</p>
            <p>Podstawa prawna: art. 6 ust. 1 lit. f) RODO – nasz prawnie uzasadniony interes polegający na prowadzeniu ewentualnych postępowań i ochrony lub ustalenia związanych z nimi roszczeń.</p>
          </ol>

          <li>Prawnie uzasadnione interesy administratora</li>
          <p>Przetwarzanie niektórych wyżej wymienionych rodzajów danych osobowych zostało oparte na naszym prawnie uzasadnionym interesie. W naszej opinii przetwarzanie danych w celach dbania o bezpieczeństwo i funkcjonalność naszych usług, marketingu naszych usług, komunikacji z Tobą, ochrony ewentualnych roszczeń, przeprowadzania statystyk nie narusza Twojej prywatności a jednocześnie jest istotne i proporcjonalne do osiągnięcia tych celów.</p>

          <li>Profilowanie</li>
          <p>Nie podejmujemy żadnych decyzji dotyczących Ciebie pośrednio lub bezpośrednio, opierających się wyłącznie na zautomatyzowanym przetwarzaniu, w tym profilowaniu i wywołujących wobec Ciebie skutki prawne lub w podobny sposób wpływających na Ciebie.</p>

          <li>Odbiorcy danych osobowych</li>
          <p>Fablo może udostępniać dane osobowe następującym kategoriom odbiorców:</p>
          <ul>
            <li><b>Sprzedawcy</b> - udostępniamy sprzedawcom dane osobowe użytkowników, którzy złożyli u nich zamówienia. Sprzedawcy, którym udostępniamy takie dane osobowe samodzielnie określają cele i sposoby przetwarzania tych danych, nie podlegając w tym zakresie naszym poleceniom (są odrębnymi administratorami danych osobowych).</li>
            <li><b>Operatorzy płatności</b> – udostępniamy operatorom płatności informacje użytkowników, którzy wybrali formę płatności online;</li>
            <li><b>Zewnętrzne bazy danych</b> – udostępniamy dane sprzedawców w celu uzyskania weryfikacji ich statusu oraz zgodności podanych informacji z tymi dostępnymi publicznie;</li>
            <li><b>Podmioty współpracujące</b>, które świadczą usługi elektroniczne, takie jak hosting stron internetowych, bezpieczeństwo stron internetowych, dostawcy systemów IT i aplikacji;</li>
            <li><b>Profesjonalni dostawcy usług</b>, np. wsparcie IT, dostawcy narzędzi i usług marketingowych, biura rachunkowe, kancelarie prawne, audytorzy i doradcy;</li>
            <li><b>Instytucje państwowe/sądy/organy ścigania</b> – w przypadku otrzymania prośby/żądania uzasadnionych przepisami prawa od instytucji państwowej, urzędu, sądu lub innej uprawnionej organizacji, przekażemy Twoje dane osobowe w zakresie, w jakim będziemy do tego zobowiązani.</li>
          </ul>
          <p>Przetwarzamy dane osobowe na terenie Europejskiego Obszaru Gospodarczego (EOG).</p>

          <li>Czas przechowywania Twoich danych osobowych</li>
          <p>Fablo przechowuje dane osobowe tak długo, jak jest to konieczne do wypełnienia celów, dla których te dane były zebrane.</p>
          <p>Co do zasady dane osobowe sprzedawców przetwarzamy do momentu zamknięcia konta danego sprzedawcy. Dane osobowe użytkowników przetwarzamy do momentu zakończenia świadczenia usługi.</p>
          <p>Jednak możemy przetwarzać niektóre dane osobowe nawet po zamknięciu konta lub zakończeniu świadczenia usług, jeżeli mamy ku temu prawnie uzasadnioną potrzebę biznesową (np. w celu wykonywania lub obrony roszczeń prawnych) lub jeżeli zobowiązuje nas do tego prawo, tak długo, jak zezwala na to prawo.</p>
          <p>Dane osobowe przetwarzane na podstawie naszego uzasadnionego interesu będziemy przechowywać do czasu wyrażenia skutecznego sprzeciwu. Dane z dokumentów podatkowych, przechowujemy przez okres wynikający z przepisów podatkowych i rachunkowych tj. 5 lat od końca roku kalendarzowego, w którym upłynął termin płatności podatku.</p>
          <p>Dane osobowe przetwarzane w związku z komunikacją, rozpatrywaniem reklamacji oraz ochroną i prowadzeniem ewentualnych postępowań możemy przetwarzać przez czas niezbędny do udzielenia odpowiedzi/ich zakończenia, jednak nie dłużej niż przez okres 3 lat od ich otrzymania/rozpoczęcia danego postępowania. Jeżeli dane osobowe są lub mogą być wykorzystywane na potrzeby prowadzonego postępowania, możemy je przetwarzać do jego prawomocnego zakończenia.</p>
          <p>Twoje dane osobowe zebrane za pomocą plików cookies są przetwarzane przez okres odpowiadający cyklowi życia cookies zapisanych na urządzeniach.</p>

          <li>Twoje prawa</li>
          <p>W przypadku, gdy przetwarzanie danych osobowych odbywa się na podstawie uzasadnionego interesu, możesz w każdej chwili wyrazić sprzeciw co do takiego przetwarzania. W takiej sytuacji, będziemy zobowiązani podać ważny powód, dla którego nasze przetwarzanie powinno być kontynuowane, który jest nadrzędny wobec Twoich interesów, praw i wolności lub że przetwarzanie jest niezbędne do ustalenia, dochodzenia lub obrony roszczenia prawnego.</p>
          <p>Masz również prawo:</p>
          <ul>
            <li>uzyskać dostęp do Twoich danych osobowych i otrzymać informacje na ich temat, takie jak cel, w jakim są przetwarzane, osoby, którym je ujawniono oraz okres, na jaki będą przechowywane;</li>
            <li>wymagać od nas poprawienia wszelkich nieścisłości w Twoich danych osobowych bez zbędnej zwłoki;</li>
            <li>wymagać od nas usunięcia danych osobowych;</li>
            <li>wymagać od nas ograniczenia przetwarzania danych osobowych;</li>
            <li>zażądać, abyśmy dostarczyli Ci kopię wszelkich danych osobowych, które nam przekazałeś(łaś), w formacie nadającym się do odczytu maszynowego, w przypadku gdy przetwarzamy je na podstawie zgody użytkownika lub gdy jest to niezbędne do wykonania łączącej nas umowy a przetwarzanie jest zautomatyzowane.</li>
          </ul>
          <p>Jeżeli przetwarzanie danych osobowych odbywa się wyłącznie za Twoją zgodą, masz prawo wycofać swoją zgodę w dowolnym czasie – po wycofaniu przez Ciebie zgody, nie będziemy już przetwarzać Twoich danych w danym celu. Nie będzie to wpływać na zgodność z prawem jakiegokolwiek przetwarzania dokonanego przed wycofaniem zgody.</p>
          <p>W celu skorzystania z któregokolwiek z przysługujących praw, wyślij do nas wiadomość na adres: <a href="mailto:prywatnosc@fablo.pl">prywatnosc@fablo.pl</a>.</p>
          <p>Możesz również złożyć skargę do właściwego organu nadzorującego ochronę danych, dane do kontaktu są dostępne na stronie: <a href="https://uodo.gov.pl/" target="_blank">https://uodo.gov.pl/</a>.</p>
        </ol>
      </Container>


      <Container className="padding" ref={refs.cookies}>
        <H2>Polityka plików cookies</H2>
        <p>Na stronie internetowej Fablo.pl korzystamy z plików cookies. Administratorem danych osobowych zebranych za pomocą cookies jest Retailic Sp. z o.o. z siedzibą w Warszawie, ul. Dembowskiego 19 lok. 26, 02-784 Warszawa, wpisana do Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m. st. Warszawy w Warszawie, XIII Wydział Gospodarczy KRS, pod numerem KRS 0000357852, NIP 9512317035, REGON 142434470, z kapitałem zakładowym wynoszącym 5 000 zł („<b>Fablo</b>”).</p>
        <p>Pliki cookies to małe pliki tekstowe przechowywane przez stronę internetową na komputerze lub urządzeniu mobilnym odwiedzającego. Są one powszechnie wykorzystywane do zapamiętywania użytkownika strony, jego preferencji oraz urządzeń.</p>
        <p>Instalujemy pliki cookies na Twoim urządzeniu i odczytujemy informacje zawarte w już zainstalowanych plikach cookies tylko za Twoją zgodą. Nie dotyczy to jednak plików cookies, które są absolutnie niezbędne do działania strony internetowej Fablo.pl.</p>
        <p>W ramach funkcjonowania utrzymujemy następujące typy plików cookies:</p>
        <ol>
          <li>Ściśle niezbędne pliki cookies - pozwalają zapewnić płynną nawigację i dostęp do strony internetowej oraz korzystanie z jej funkcji;</li>
          <li></li>
        </ol>
        <p>Ponadto, wykorzystywane przez nas pliki cookies można podzielić na tymczasowe (kasowane po zamknięciu okna) oraz stałe (aktywne do czasu ich ręcznego usunięcia/upływu cyklu ich aktywności).</p>
        <p>Rodzaje wykorzystywanych przez nas plików cookies:</p>
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Nazwa pliku cookie</th>
              <th>Cel</th>
              <th>Okres przechowywania</th>
              <th>Dane, które mogą być przechowywane w pliku</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
        <p>Żeby dowiedzieć się więcej o sposobach korzystania oraz usuwania plików cookies możesz zapoznać się z instrukcjami producentów przeglądarek internetowych – linki poniżej:</p>
        <ul>
          <li>Mozilla Firefox: <a target="_blank" href="http://support.mozilla.org/pl/kb/ciasteczka">http://support.mozilla.org/pl/kb/ciasteczka</a></li>
          <li>Internet Explorer: <a target="_blank" href="http://support.microsoft.com/kb/278835/pl">http://support.microsoft.com/kb/278835/pl</a></li>
          <li>Microsoft Edge: <a target="_blank" href="http://windows.microsoft.com/pl-pl/windows-10/view-delete-browsing-history-microsoft-edge">http://windows.microsoft.com/pl-pl/windows-10/view-delete-browsing-history-microsoft-edge</a></li>
          <li>Google Chrome: <a target="_blank" href="http://support.google.com/chrome/bin/answer.py?hl=pl&answer=95647">http://support.google.com/chrome/bin/answer.py?hl=pl&answer=95647</a></li>
          <li>Safari: <a target="_blank" href="https://support.apple.com/pl-pl/guide/safari/sfri11471/mac">https://support.apple.com/pl-pl/guide/safari/sfri11471/mac</a></li>
          <li>Opera: <a target="_blank" href="http://help.opera.com/Linux/9.60/pl/cookies.html">http://help.opera.com/Linux/9.60/pl/cookies.html</a></li>
        </ul>
        <p>Zastrzegamy sobie prawo do zmiany niniejszej Polityki w dowolnym momencie, ze skutkiem od momentu opublikowania zaktualizowanej wersji Polityki. W takim przypadku opublikujemy odpowiednie powiadomienie.</p>
      </Container>

      <Container className="padding nested-numbered-list" ref={refs['dla-sprzedawcow']}>
        <H2>Regulamin Fablo.pl dla sprzedawców</H2>

        <ol>
          <li>DEFINICJE
            <ol>
              <li><b>Sprzedawca</b> – osoba fizyczna prowadząca działalność gospodarczą, osoba prawna lub jednostka organizacyjna nieposiadająca osobowości prawnej, której ustawa przyznaje zdolność prawną, korzystająca z platformy Fablo w celu prezentacji swojej oferty handlowej za pośrednictwem dedykowanej strony internetowej i dokonywania transakcji z Użytkownikami. W przypadku spółki cywilnej za Sprzedawcę uznaje się wszystkich wspólników spółki.</li>
              <li><b>Użytkownik</b> – osoba fizyczna posiadająca pełną zdolność do czynności prawnych, osoba prawna albo jednostka organizacyjna nieposiadająca osobowości prawnej, której ustawa przyznaje zdolność prawną, która zawiera ze Sprzedawcą Umowę rezerwacji lub sprzedaży towarów lub usług za pośrednictwem Fablo.</li>
              <li><b>Fablo</b> – w zależności od kontekstu - platforma online dostępna za pośrednictwem strony www.Fablo.pl umożliwiająca Użytkownikom zamawianie towarów i usług na stronach Sprzedawców lub podmiot prowadzący Fablo.pl - Retailic Sp. z o.o. z siedzibą w Warszawie, ul. Dembowskiego 19 lok. 26, 02-784 Warszawa, wpisana do Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m. st. Warszawy w Warszawie, XIII Wydział Gospodarczy KRS, pod numerem KRS 0000357852, NIP 9512317035, REGON 142434470, z kapitałem zakładowym wynoszącym 5 000 zł. Adres do korespondencji: Retailic Sp. z o.o., ul. Dembowskiego 19/26, 02-784 Warszawa.</li>
              <li><b>Umowa</b> – umowa rezerwacji lub sprzedaży towarów lub usług zawarta pomiędzy Użytkownikiem a Sprzedawcą na podstawie złożonego zamówienia.</li>
              <li><b>Regulamin</b> – niniejszy regulamin, który określa zasady i warunki świadczenia usług drogą elektroniczną przez Fablo dla Sprzedawców.</li>
              <li><b>Plan</b> – wybrany przez Sprzedawcę rodzaj konta Fablo. Funkcjonalności dla danego rodzaju konta określa Załącznik nr 1 do Regulaminu.</li>
              <li><b>Umowa z operatorem płatności</b> - umowa o świadczenie usług płatniczych pomiędzy Sprzedawcą a operatorem płatności, z którym współpracuje Fablo.</li>
            </ol>
          </li>
          <li>WARUNKI ŚWIADCZENIA USŁUG DLA SPRZEDAWCÓW
            <ol>
              <li>Fablo świadczy usługę Fablo.pl zgodnie z Regulaminem.</li>
              <li>Aby uzyskać dostęp do platformy w domenie fablo.pl należy dysponować urządzeniem mającym dostęp do sieci Internet, obsługującym przeglądarki typu: Internet Explorer, Google Chrome, Mozilla Firefox, Safari w aktualnej wersji.</li>
              <li>Aby korzystać z aplikacji mobilnej Fablo należy dysponować urządzeniem mobilnym wyposażonym w system operacyjny iOS w wersji 10 albo Android w wersji 5.0, posiadającym dostęp do sieci Internet. Aplikacja może wymagać okresowych aktualizacji, których pobranie może być konieczne dla jej prawidłowego funkcjonowania. Ze względu na ograniczenia techniczne funkcjonalności konta oferowane przez stronę internetową mogą być niedostępne poprzez aplikację.</li>
              <li>Korzystanie z określonych funkcjonalności strony internetowej lub aplikacji może być uzależnione od instalacji oprogramowania typu Java, Java Script oraz akceptacji cookies.</li>
              <li>Aby korzystać z Fablo Sprzedawca powinien zarejestrować konto oraz zawrzeć Umowę z operatorem płatności.</li>
              <li>Sprzedawca może posiadać maksymalnie jedno konto. Funkcjonalności konta oraz liczbę punktów sprzedaży określa Plan.</li>
              <li>W celu rejestracji konta Sprzedawca podaje swoje dane identyfikacyjne oraz kontaktowe oraz przechodzi procedurę uwierzytelnienia. Sprzedawca jest zobowiązany i wyłącznie odpowiedzialny za podanie kompletnych i zgodnych z prawdą danych osobowych oraz innych danych wymaganych podczas rejestracji oraz do ich samodzielnego sprawdzania i aktualizowania.</li>
              <li>Zawarcie Umowy z operatorem płatności poprzedza weryfikacja AML Sprzedawcy.</li>
            </ol>
          </li>
          <li>KORZYSTANIE Z FABLO I OBOWIĄZKI WOBEC UŻYTKOWNIKÓW
            <ol>
              <li>Fablo w ramach konta i Planu umożliwia Sprzedawcy przedstawienie Użytkownikom jego oferty handlowej, zawieranie Umów oraz dokumentowanie transakcji. </li>
              <li>Sprzedawca może udostępniać informacje wyłącznie o sobie i swojej ofercie handlowej. Sprzedawca zobowiązuje się nie dostarczać treści o charakterze bezprawnym. </li>
              <li>Fablo może wykorzystywać nazwę Sprzedawcy oraz fakt współpracy stron w swoich celach marketingowych. Sprzedawca upoważnia Fablo do korzystania z treści Sprzedawcy w celu umożliwienia prezentacji oferty handlowej Sprzedawcy oraz marketingu Sprzedawcy i Fablo. </li>
              <li>Sprzedawca może korzystać z predefiniowanych baz produktowych, własnych oraz udostępnionych przez partnerów Fablo. Korzystanie z baz udostępnianych przez partnerów Fablo i zawartych w nich informacji możliwe jest wyłącznie w ramach platformy.  </li>
              <li>Fablo może udostępniać mechanizmy do komunikacji z bazami produktowymi Sprzedawcy w celu bieżącej aktualizacji stanów magazynowych, jednak nie ponosi odpowiedzialności za prawidłowość ich działania.</li>
              <li>Sprzedawca zobowiązany jest zawierać Umowy na podstawie obowiązującego w danej chwili Regulaminu dla użytkowników i nie stosować wobec Użytkowników innych warunków niż w nim wskazane. </li>
              <li>Sprzedawca nie może nakładać na Użytkowników innych opłat niż cena towaru lub usługi, opłata za przygotowanie zamówienia lub opłata za dostawę. </li>
              <li>Sprzedawca zobowiązany jest na bieżąco aktualizować informacje o towarach i usługach, w szczególności informacje o zmianach ceny i dostępności towarów i usług. </li>
              <li>Sprzedawca zobowiązuje się nie stosować w Fablo cen wyższych niż przy sprzedaży innymi kanałami.  </li>
              <li>Sprzedawca zobowiązuje się rozpatrywać reklamacje Użytkowników (zgłoszone przez nich osobiście lub za pośrednictwem Fablo) niezwłocznie nie później jednak niż w ciągu 14 dni od ich otrzymania. </li>
              <li>Fablo może usuwać treści umieszczane przez Sprzedawcę, które naruszają Regulamin lub przepisy prawa.</li>
            </ol>
          </li>
          <li>ODPOWIEDZIALNOŚĆ SPRZEDAWCY
            <ol>
              <li>Sprzedawca zapewnia, że:
                <ol>
                  <li>wszystkie udostępnianie przez niego treści są zgodne z prawem i prawami osób trzecich i posiada wszelkie zgody i upoważnienia do udostępniania tych treści w ramach Fablo;</li>
                  <li>oferowane przez niego towary są należycie przechowywane, ich data przydatności do użycia jeszcze nie upłynęła, są dopuszczone do obrotu i posiadają wszelkie niezbędne certyfikaty;</li>
                  <li>oferowane przez niego usługi są zgodne z obowiązującym prawem, spełniają wszelkie wymogi i są świadczone przez odpowiednio wykwalifikowany personel;</li>
                  <li>będzie umieszczał w Fablo rzetelne oraz kompletne informacje, w szczególności opisy towarów i ich ceny; informacje te nie będą wprowadzać w błąd co do właściwości i cech towaru lub rodzaju, zakresu i warunków oferowanej usługi;</li>
                  <li>jest uprawniony oraz posiada możliwość zawarcia i wykonania wszelkich Umów; </li>
                  <li>będzie zawierał Umowy wyłącznie na podstawie Regulaminu użytkownika i nie będzie stosował warunków z nim sprzecznych; </li>
                </ol>
              </li>
              <li>Sprzedawca jest wyłącznie odpowiedzialny za:
                <ol>
                  <li>zgodność umieszczanych przez niego informacji handlowych z przepisami prawa, w tym prawem konsumenckim;</li>
                  <li>za przestrzeganie przepisów dotyczących danych osobowych w zakresie, w którym administruje danymi Użytkowników;</li>
                  <li>za wszelkie błędy lub nieścisłości oferty handlowej.</li>
                </ol>
              </li>
              <li>Sprzedawca zwalnia Fablo i Użytkowników z odpowiedzialności wobec osób trzecich za wszelkie naruszenia ich praw własności intelektualnej lub innych praw w związku z korzystaniem przez Fablo i Użytkowników z treści Sprzedawcy zgodnie z przeznaczeniem. </li>
              <li>Sprzedawca zwalnia Fablo z odpowiedzialności wobec wszelkich roszczeń wobec Użytkowników związanych z zawieraniem i realizacją Umowy. </li>
              <li>Sprzedawca jest zobowiązany do dostarczenia towarów bez wad oraz jest odpowiedzialny za ich wady na zasadach uregulowanych w art. 556 i n. Kodeksu cywilnego (rękojmia za wady).</li>
              <li>Sprzedawca jest zobowiązany do należytego wykonywania usług oraz jest odpowiedzialny za ich niewykonanie lub nienależyte wykonanie.</li>
            </ol>
          </li>

          <li>PŁATNOŚĆ
            <ol>
              <li>Sprzedawca dokonuje płatności z góry za korzystanie z Fablo zgodnie z wybranym Planem.</li>
              <li>Brak zapłaty w uzgodnionym terminie spowoduje zawieszenie świadczenia usług przez Fablo i zablokowanie konta Sprzedawcy. Wznowienie usług i odblokowanie konta nastąpi niezwłocznie po zaksięgowaniu wpłaty.</li>
              <li>Fablo nie pośredniczy w procesie płatności dokonywanej online.</li>
            </ol>
          </li>
          <li>PLASOWANIE SPRZEDAWCÓW
            <ol>
              <li>Fablo wyświetla profile Sprzedawców na stronie Fablo.pl w kolejności zależnej od odległości sklepu Sprzedawcy od adresu wskazanego przez Użytkownika, lokalizacji sklepu Sprzedawcy, aktywności Sprzedawcy oraz ocen Użytkowników.</li>
            </ol>
          </li>
          <li>ZAKOŃCZENIE ŚWIADCZENIA USŁUG
            <ol>
              <li>Sprzedawca może w każdym czasie wypowiedzieć umowę o świadczenie usług przez Fablo. Wypowiedzenie jest skuteczne na koniec miesiąca kalendarzowego. Usunięcie aplikacji mobilnej nie jest równoznaczne z usunięciem konta.</li>
              <li>Fablo może wypowiedzieć umowę bez podawania przyczyny z zachowaniem 30 dniowego okresu wypowiedzenia.</li>
              <li>Fablo może wypowiedzieć umowę Sprzedawcy bez zachowania okresu wypowiedzenia, gdy:
                <ol>
                  <li>podlega obowiązkowi prawnemu lub regulacyjnemu, na podstawie którego zobowiązany jest do zakończenia świadczenia całości swoich usług Sprzedawcy,</li>
                  <li>Sprzedawca istotnie narusza postanowienia niniejszego Regulaminu.</li>
                </ol>
              </li>
              <li>W chwili rozwiązania umowy wszystkie oferty Sprzedającego zostaną usunięte i nie będzie możliwe wystawianie nowych. Rozwiązanie umowy nie wpływa na ważność zawartych przez Sprzedawcę Umów.</li>
            </ol>
          </li>
          <li>REKLAMACJE
            <ol>
              <li>Sprzedawca może zgłosić reklamacje dotyczące usług świadczonych drogą elektroniczną na podstawie niniejszego Regulaminu za pośrednictwem adresu e-mail: b2b@fablo.pl.</li>
              <li>Reklamacja powinna zawierać co najmniej imię i nazwisko oraz numer NIP lub KRS, adres e-mail oraz opis zgłaszanych zastrzeżeń.</li>
              <li>Reklamacje będą rozpatrywane w terminie 14 dni od dnia ich otrzymania przez Fablo. O decyzji Fablo Sprzedawca zostanie powiadomiony na adres podany przy składaniu reklamacji.</li>
              <li>Sprzedawca ma prawo odwołać się od decyzji Fablo podjętej zgodnie z pkt 8.3.</li>
            </ol>
          </li>
          <li>DANE OSOBOWE
            <p>Fablo przetwarza dane osobowe Sprzedawców na zasadach określonych w Polityce Prywatności, dostępnej na stronie <a href="https://fablo.pl/regulaminy#prywatnosc">https://fablo.pl/regulaminy#prywatnosc</a>.</p>
          </li>
          <li>OCHRONA PRAW WŁASNOŚCI INTELEKTUALNEJ
            <ol>
              <li>Wszelkie prawa, w tym prawa autorskie i prawa własności przemysłowej do usługi Fablo lub do jej poszczególnych elementów tekstowych, graficznych, zdjęć, materiałów audiowizualnych, aplikacji, programów, narzędzi i baz danych, znaków towarowych, logotypów zastrzeżone są na rzecz Retailic sp. z o.o. lub jej partnerów.</li>
              <li>Powielanie i rozpowszechnianie w jakiejkolwiek formie i w jakikolwiek sposób materiałów umieszczonych na stronie internetowej Fablo poza granice dozwolone prawem jest zabronione.</li>
            </ol>
          </li>
          <li>POSTANOWIENIA KOŃCOWE
            <ol>
              <li>Regulamin obowiązuje od 01.01.2021 r.</li>
              <li>Fablo może zmienić niniejszy Regulamin w przypadku:
                <ol>
                  <li>zmiany przepisów prawa mającej bezpośredni wpływ na treść Regulaminu,</li>
                  <li>zapobiegania nadużyciom lub ze względów bezpieczeństwa,</li>
                  <li>zmian w funkcjonalnościach platformy Fablo.</li>
                </ol>
              </li>
              <li>Wszelkie zmiany Regulaminu będą publikowane na stronie Fablo.pl oraz przesyłane na adres e-mail Sprzedawcy podany na koncie.</li>
              <li>Zmiana Regulaminu wchodzi w życie w terminie wskazanym przez Fablo nie krótszym niż 7 dni od chwili powiadomienia Sprzedawcy o zmianie.</li>
              <li>Prawem właściwym dla stosunków wynikających z niniejszego Regulaminu i dla rozstrzygania wszelkich sporów związanych z niniejszym Regulaminem jest prawo polskie. Spory te będą rozstrzygane przez sąd powszechny właściwy miejscowo dla Fablo.</li>
            </ol>
          </li>
        </ol>

        <H3 style={{marginTop: 32}}>Załącznik nr 1<br/>Plany</H3>
        <p>Lista planów znajduje się na stronie: <Link to="/?plany">https://fablo.pl/?plany</Link>.</p>


      </Container>

      <Container className="padding nested-numbered-list" ref={refs['dla-uzytkownikow']}>
        <H2>Regulamin dla Użytkowników</H2>
        <ol>
          <li>DEFINICJE
            <ol>
              <li><b>Sprzedawca</b> – osoba fizyczna prowadząca działalność gospodarczą, osoba prawna lub jednostka organizacyjna nieposiadająca osobowości prawnej, której ustawa przyznaje zdolność prawną, korzystająca z platformy Fablo w celu prezentacji swojej oferty handlowej za pośrednictwem dedykowanej strony internetowej i dokonywania transakcji z Użytkownikami. W przypadku spółki cywilnej za Sprzedawcę uznaje się wszystkich wspólników spółki.</li>
              <li><b>Użytkownik</b> – osoba fizyczna posiadająca pełną zdolność do czynności prawnych, osoba prawna albo jednostka organizacyjna nieposiadająca osobowości prawnej, której ustawa przyznaje zdolność prawną, która zawiera ze Sprzedawcą Umowę rezerwacji lub sprzedaży towarów lub usług za pośrednictwem Fablo.</li>
              <li><b>Fablo</b> – w zależności od kontekstu - platforma online dostępna za pośrednictwem strony www.Fablo.pl umożliwiająca Użytkownikom zamawianie towarów i usług na stronach Sprzedawców lub podmiot prowadzący Fablo.pl - Retailic Sp. z o.o. z siedzibą w Warszawie, ul. Dembowskiego 19 lok. 26, 02-784 Warszawa, wpisana do Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m. st. Warszawy w Warszawie, XIII Wydział Gospodarczy KRS, pod numerem KRS 0000357852, NIP 9512317035, REGON 142434470, z kapitałem zakładowym wynoszącym 5 000 zł. Adres do korespondencji: Retailic Sp. z o.o., ul. Dembowskiego 19/26, 02-784 Warszawa.</li>
              <li><b>Umowa</b> – umowa rezerwacji lub sprzedaży towarów lub usług zawarta pomiędzy Użytkownikiem a Sprzedawcą na podstawie złożonego zamówienia.</li>
              <li><b>Regulamin</b> - niniejszy regulamin, który określa zasady i warunki świadczenia usług drogą elektroniczną przez Fablo za pośrednictwem strony internetowej www.Fablo.pl dla Sprzedawców.</li>
            </ol>
          </li>
          <li>SKŁADANIE ZAMÓWIEŃ
            <ol>
              <li>Użytkownik zamawia towary i usługi od Sprzedawcy. Fablo nie jest stroną Umowy ani ani nie ponosi odpowiedzialności za wykonanie Umowy przez Użytkownika lub Sprzedawcę.</li>
              <li>Wszystkie informacje dostępne na stronach, w tym opisy, ceny towarów i usług czy miejsce odbioru:
                <ol>
                  <li>pochodzą od Sprzedawców - Fablo nie ponosi odpowiedzialności za ich prawidłowość i dokładność. </li>
                  <li>stanowią zaproszenie do zawarcia umowy w rozumieniu art. 71 kodeksu cywilnego.</li>
                </ol>
              </li>
              <li>Dodanie towaru lub usługi do "Koszyka" nie oznacza rezerwacji. Użytkownik otrzymuje potwierdzenie dostępności wybranych towarów lub usług w podsumowaniu zamówienia.</li>
              <li>Użytkownik składa zamówienie poprzez kliknięcie w formularzu zamówienia „Rezerwuję” albo "Zamawiam i płacę". Po złożeniu zamówienia, Użytkownik otrzymuje automatyczne potwierdzenie złożenia zamówienia w wiadomości e-mail.</li>
              <li>Użytkownik nie może jednocześnie zamówić towarów/usług o łącznej wartości przekraczającej 700 złotych. Wartości te liczone są dla zamówień w toku (nieodebranych jeszcze przez Użytkownika).</li>
              <li>Użytkownik powinien odebrać towar w terminie wskazanym w podsumowaniu zamówienia. Jeżeli Użytkownik nie odbierze towaru w tym terminie, jego zamówienie zostanie anulowane.</li>
              <li>Jeżeli Sprzedawca ma dostarczyć towar pod adres wskazany przez Użytkownika, a nie zastanie Użytkownika pod wskazanym adresem, to Sprzedawca może wskazać Użytkownikowi adres i termin odbioru towaru. Jeżeli Użytkownik odbierze towar w sposób wskazany przez Sprzedawcę, to opłata za dostawę nie podlega zwrotowi. Jeżeli Użytkownik nie odbierze towaru w tym terminie, to zamówienie zostanie anulowane.</li>
              <li>Użytkownik może anulować zamówienie opłaconej usługi, o ile Sprzedawca przewiduje taką możliwość. Jeżeli Użytkownik nie pojawi się w miejscu świadczenia usługi w wyznaczonym terminie, Sprzedawca zachowuje prawo do wynagrodzenia, jeżeli zostało pobrane.</li>
              <li>Przy odbiorze zamówienia, w celach weryfikacyjnych, osoba wydająca zamówienie może zażądać od Użytkownika numeru PIN podanego w zamówieniu.</li>
            </ol>
          </li>
          <li>CENY I ZWROTY PŁATNOŚCI
            <ol>
              <li>Wszystkie ceny na stronach Sprzedawców są podane w złotych polskich oraz zawierają wszystkie elementy, takie jak cła i podatki, w tym podatek VAT (są cenami całkowitymi).</li>
              <li>Na stronach Sprzedawcy informują o akceptowanych sposobach płatności, terminie płatności, o łącznej cenie za towary lub usługi oraz wszelkich dodatkowych opłatach, w tym za dodatkowe usługi.</li>
              <li>Dla realizacji płatności online Sprzedawca korzysta z zewnętrznych dostawców usług płatniczych. W celu dokonania płatności online Użytkownik zostanie przekierowany na stronę internetową wybranego dostawcy.</li>
              <li>Wszelkie koszty płatności ponosi Użytkownik. Prowizje zewnętrznych usługodawców za korzystanie przez Użytkownika z wybranych form płatności pokrywa Sprzedawca, z wyłączeniem opłat i prowizji pobieranych przez bank prowadzący rachunek bankowy Użytkownika.</li>
              <li>Użytkownik otrzymuje paragon, chyba że wskazał na formularzu zamówienia, że chce otrzymać fakturę VAT zgodnie z obowiązującymi przepisami.</li>
            </ol>
          </li>
          <li>REKLAMACJE
            <p>Użytkownik może zgłosić reklamacje dotyczące Zamówienia do Sprzedawcy telefonicznie lub poprzez formularz kontaktowy na fablo.pl https://fablo.pl/pomoc, bądź maila fablo@b2c.pl. Fablo przekaże Sprzedawcy otrzymane reklamacje. Wszelkie reklamacje będą rozpatrywanie niezwłocznie.</p>
          </li>
          <li>ODPOWIEDZIALNOŚĆ SPRZEDAWCY
            <ol>
              <li>Sprzedawca jest zobowiązany do dostarczenia towarów bez wad oraz jest odpowiedzialny za ich wady na zasadach uregulowanych w art. 556 i n. kodeksu cywilnego (rękojmia za wady). Sprzedawca odpowiada za wady fizyczne (niezgodność z umową) i prawne towaru. Kupującemu przysługują następujące uprawnienia: 1) żądanie wymiany towaru na nowy; 2) żądanie naprawy towaru, 3) złożenie oświadczenia o obniżeniu ceny; 4) złożenie oświadczenia o odstąpieniu od umowy. Sprzedawca odpowiada z tytułu rękojmi, jeżeli wada fizyczna zostanie stwierdzona przed upływem dwóch lat od dnia wydania towaru.</li>
              <li>Sprzedawca jest zobowiązany do należytego wykonywania usług.</li>
            </ol>
          </li>
          <li>DANE OSOBOWE
            <p>Dane osobowe Użytkowników są przetwarzane na zasadach określonych w polityce prywatności, dostępnej na stronie <a href="https://fablo.pl/regulaminy#prywatnosc">https://fablo.pl/regulaminy#prywatnosc</a>.</p>
          </li>
          <li>KORZYSTANIE Z PLATFORMY
            <ol>
              <li>Usługa Fablo jest świadczona zgodnie z regulaminem.</li>
              <li>Aby uzyskać dostęp do platformy w domenie fablo.pl należy dysponować urządzeniem mającym dostęp do sieci Internet, obsługującym przeglądarki typu: Internet Explorer, Google Chrome, Mozilla Firefox, Safari w aktualnej wersji. </li>
              <li>Aby korzystać z aplikacji mobilnej Fablo należy dysponować urządzeniem mobilnym wyposażonym w system operacyjny iOS w wersji 10 albo Android w wersji 5.0, posiadającym dostęp do sieci Internet. Aplikacja może wymagać okresowych aktualizacji, których pobranie może być konieczne dla jej prawidłowego funkcjonowania. Ze względu na ograniczenia techniczne funkcjonalności konta oferowane przez stronę internetową mogą być niedostępne poprzez aplikację.</li>
              <li>Korzystanie z określonych funkcjonalności strony internetowej lub aplikacji może być uzależnione od instalacji oprogramowania typu Java, Java Script oraz akceptacji cookies.</li>
              <li>Użytkownik może w każdym momencie zrezygnować z korzystania usługi Fablo.</li>
              <li>Użytkownik nie może dostarczać treści o charakterze bezprawnym.</li>
              <li>Wszelkie pytania i wątpliwości dotyczące działania platformy prosimy zgłaszać poprzez formularz kontaktowy dostępny na stronie: https://fablo.pl/pomoc.</li>
            </ol>
          </li>
          <li>OCHRONA PRAW WŁASNOŚCI INTELEKTUALNEJ
            <ol>
              <li>Wszelkie prawa, w tym prawa autorskie i prawa własności przemysłowej do usługi Fablo lub do jej poszczególnych elementów tekstowych, graficznych, zdjęć, materiałów audiowizualnych, aplikacji, programów, narzędzi i baz danych, znaków towarowych, logotypów zastrzeżone są na rzecz Retailic sp. z o.o. z siedzibą w Warszawie lub jej partnerów.</li>
              <li>Powielanie i rozpowszechnianie w jakiejkolwiek formie i w jakikolwiek sposób materiałów umieszczonych na stronie internetowej Fablo poza granice dozwolone prawem jest zabronione.</li>
            </ol>
          </li>
          <li>POSTANOWIENIA KOŃCOWE
            <ol>
              <li>Regulamin obowiązuje od 01.01.2021 r.</li>
              <li>Fablo ma prawo zmienić niniejszy regulamin w przypadku zmian w zakresie świadczonych usług. Każda zmiana będzie dokonywana z odpowiednim wyprzedzeniem.</li>
              <li>Użytkownik będący konsumentem, ma możliwość rozwiązywania sporów drogą elektroniczną za pośrednictwem platformy internetowej ODR Unii Europejskiej umożliwiającej składanie reklamacji i dochodzenie roszczeń związanych z zawartymi przez nich umowami - platforma znajduje się na stronie: http://ec.europa.eu/consumers/odr/. Konsument może także skorzystać z pomocy rzecznika konsumentów lub organizacji pozarządowych działających na rzecz konsumentów. Więcej informacji znajduje się na stronach internetowych Urzędu Ochrony Konkurencji i Konsumentów (www.uokik.gov.pl).</li>
            </ol>
          </li>
        </ol>
        <H3 style={{ marginTop: 40 }}>Informacje dotyczące korzystania z ustawowego prawa do odstąpienia od umowy</H3>
        <ol>
          <li>Informacje ogólne
            <ol>
              <li>Niniejsza informacja przeznaczona jest dla kupujących będących konsumentami, oraz kupujących będących osobami fizycznymi, którzy zawarli umowę bezpośrednio związaną z ich działalnością gospodarczą, gdy z treści umowy wynika, że nie posiada ona dla nich charakteru zawodowego, wynikającego w szczególności z przedmiotu wykonywanej przez nich działalności gospodarczej, udostępnionego na podstawie przepisów o Centralnej Ewidencji i Informacji o Działalności Gospodarczej.</li>
              <li>Kupujący, wskazani powyżej, który zawarli umowę sprzedaży na odległość, mogą w terminie 14 dni od niej odstąpić bez podawania przyczyny i bez ponoszenia kosztów, za wyjątkiem kosztów wskazanych poniżej (ustawowe prawo do odstąpienia od umowy).</li>
              <li>Prawo do odstąpienia nie przysługuje w odniesieniu do umowy:
                <ol>
                  <li>o świadczenie usług, jeżeli przedsiębiorca wykonał w pełni usługę za wyraźną zgodą konsumenta, który został poinformowany przed rozpoczęciem świadczenia, że po spełnieniu świadczenia przez przedsiębiorcę utraci prawo odstąpienia od umowy; </li>
                  <li>w której przedmiotem świadczenia jest towar dostarczany w zapieczętowanym opakowaniu, którego po otwarciu opakowania nie można zwrócić ze względu na ochronę zdrowia lub ze względów higienicznych, jeżeli opakowanie zostało otwarte po dostarczeniu;</li>
                  <li>w której przedmiotem świadczenia są nagrania dźwiękowe lub wizualne albo programy komputerowe dostarczane w zapieczętowanym opakowaniu, jeżeli opakowanie zostało otwarte po dostarczeniu;</li>
                  <li>w której przedmiotem świadczenia jest towar nieprefabrykowany, wyprodukowany według specyfikacji konsumenta lub służący zaspokojeniu jego zindywidualizowanych potrzeb; </li>
                  <li>w której przedmiotem świadczenia jest towar, który po dostarczeniu, ze względu na swój charakter, zostaje nierozłącznie połączony z innymi rzeczami;</li>
                  <li>w której przedmiotem świadczenia jest towar ulegający szybkiemu zepsuciu lub mający krótki termin przydatności do użycia;</li>
                  <li>w której przedmiotem świadczenia jest towar, który po dostarczeniu, ze względu na swój charakter, zostaje nierozłącznie połączony z innymi rzeczami;</li>
                  <li>o dostarczanie dzienników, periodyków lub czasopism, z wyjątkiem umowy o prenumeratę;</li>
                  <li>o świadczenie usług w zakresie zakwaterowania, innych niż do celów mieszkalnych, przewozu rzeczy, najmu samochodów, gastronomii, usług związanych z wypoczynkiem, wydarzeniami rozrywkowymi, sportowymi lub kulturalnymi, jeżeli w umowie oznaczono dzień lub okres świadczenia usługi.</li>
                </ol>
              </li>
              <li>Aby wykonać prawo do odstąpienia, kupujący powinien złożyć jednoznaczne oświadczenie w tym zakresie. Kupujący może skorzystać z wzoru formularza odstąpienia od umowy (poniżej), jednak nie jest to obowiązkowe. Oświadczenie może zostać złożone poprzez wysłanie wiadomości e-mail na adres sprzedawcy, który potwierdzi otrzymanie tego oświadczenia w wiadomości e-mail. Kupujący ponosi koszty związane z wysyłką oświadczenia pocztą lub kurierem.</li>
              <li>Okres na odstąpienie od umowy wygasa po upływie 14 dni od dnia, w którym kupujący wszedł w posiadanie towaru lub w którym osoba trzecia wskazana przez kupującego weszła w posiadanie towaru albo po upływie 14 dni od dnia zawarcia umowy. Termin, o którym mowa powyżej, jest zachowany, jeżeli oświadczenie zostanie wysłane przed upływem terminu. </li>
              <li>W chwili odstąpienia od umowy wygasają powiązane z nią umowy dodatkowe zawarte przez kupującego, jeżeli na ich podstawie świadczenie jest spełniane przez sprzedawcę lub osobę trzecią na podstawie porozumienia ze sprzedawcą.</li>
            </ol>
          </li>
          <li>SKUTKI ODSTĄPIENIA OD UMOWY
            <ol>
              <li>Sprzedawca niezwłocznie, nie później niż w ciągu 14 dni od dnia otrzymania oświadczenia o odstąpieniu od umowy, zwróci kupującemu wszystkie dokonane przez niego płatności, w tym koszty dostarczenia (z wyjątkiem dodatkowych kosztów wynikających z wybranego przez kupującego sposobu dostarczenia innego niż najtańszy standardowy sposób dostarczenia).</li>
              <li>Zwrot płatności zostanie dokonany przy użyciu takiego samego sposobu zapłaty, jakiego użył kupujący, chyba że kupujący wyraźnie zgodzi się na inny sposób zwrotu, który nie wiąże się dla niego z żadnymi kosztami.</li>
              <li>Kupujący zobowiązany jest zwrócić towar sprzedawcy niezwłocznie, jednak nie później niż 14 (czternaście) dni od daty odstąpienia od umowy. Do zachowania terminu wystarczy odesłanie towaru przed jego upływem.</li>
              <li>Sprzedawca może wstrzymać się ze zwrotem płatności do czasu otrzymania towaru lub do czasu dostarczenia dowodu jego odesłania, w zależności od tego, które zdarzenie nastąpi najwcześniej.</li>
              <li>Kupujący odpowiada za zmniejszenie wartości towaru wynikające z korzystania z niego w sposób inny niż było to konieczne do stwierdzenia jego charakteru, cech i funkcjonowania.</li>
              <li>Kupujący ponosi bezpośrednie koszty zwrotu towaru (w tym koszt dostarczenia go do sprzedawcy).</li>
            </ol>
          </li>
        </ol>
        <H4 style={{marginTop: 40}}>WZÓR FORMULARZA ODSTĄPIENIA OD UMOWY</H4>
        <p>
Adresat: [nazwa lub firma sprzedawcy i adres – zgodnie z informacjami podanymi w potwierdzeniu zamówienia]<br/>
          Ja………………………………. niniejszym informuję o moim odstąpieniu od:<br/>
(*) umowy o sprzedaży następujących rzeczy: ………………………………………. /  <br/>
(*) umowy dostawy następujących rzeczy: ………………………………………. / <br/>
(*) o świadczenie następującej usługi: ……………………………………….<br/>
Data (*) zawarcia umowy / (*) odbioru: ………………………………………..<br/>
Imię i nazwisko kupującego: ………………………………………………………….<br/>
Adres kupującego: ………………………………………………………….<br/>
Data: ………………………………………………………….<br/>
Podpis (tylko jeżeli formularz j est przesyłany w wersji papierowej): …………………………………..<br/>
(*) – niepotrzebne skreślić<br/>
        </p>
      </Container>
    </Wrapper>
  </PageWrapper>
}

export default LegalPage
