import React, { useState, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { RightOutlined, LeftOutlined } from '@ant-design/icons'
import { animated, useTransition } from 'react-spring'
import { Link } from 'react-router-dom';
import { pathTo, categoryToUrl } from '../routes'
import { inject, observer } from 'mobx-react';

const CategoryBox = styled(Link)`
width: 98px;
height: 98px;
flex-shrink: 0;

color: inherit;
background: #fff;
border-radius: 6px;
margin: 12px;
padding: 0 6px 0;
box-shadow: rgba(0,0,0,0.03) 0px 1px 2px;
cursor: pointer;

text-align: center;

display: flex;
flex-direction: column;
justify-content: space-between;
p {
  font-size: 12px;
  line-height: 1.2em;
  margin: 0;
  max-height: 42px;
  overflow: hidden;
}
.icon {
  font-size: 40px;
}

&.active, &:hover {
  color: var(--primary);
}
&.active {
  border: 1px solid var(--primary);
}
transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
`

const categoriesData = {
  'Spożywcze': { icon: 'Shop', order: 0 },
  'Delikatesy': { icon: 'Add-Cart', order: 1 },
  'Bio': { icon: 'Environmental-3', order: 1 },
  'Napoje': { icon: 'Glass-Water', order: 2 },
  'Owoce i warzywa': { icon: 'Fruits', order: 3 },
  'Mrożonki': { icon: 'Snowman', order: 4 },
  'Mięso i ryby': { icon: 'Cow', order: 5 },
  'Nabiał i pieczywo': { icon: 'Milk-Bottle', order: 6 }, // Bread
  'Potrawy gotowe': { icon: 'Plates', order: 7 },
  'Zwierzęta': { icon: 'Dog', order: 8 },
  'Dzieci': { icon: 'Stroller', order: 9 },
  'Kosmetyki': { icon: 'Butterfly', order: 10 },
  'Dom i ogród': { icon: 'Home', order: 11 },
  'Kuchnia i łazienka': { icon: 'Spray', order: 12 },
  'Inne': { icon: 'Shop', order: 13 },
  'Monopolowe': { icon: 'Wine-Glass', order: 14 },
}

const Category = ({ name, active, link }) => <CategoryBox
  className={active ? "active" : ""}
  to={link}
>
  <span />
  <span className={`icon icon-${(categoriesData[name] || {}).icon || 'Shop'}`} />
  <p>{name}</p>
  <span />
</CategoryBox>

const Wrapper = styled.div`
max-width: 1222px;
//max-width: 100%;
width: 100%;
margin: 0 auto;
margin-top: 12px;
position: relative;

> div {
  overflow: scroll;
}

> div > div {
  display: flex;
  align-items: center;
}
`

const Arrow = styled.a`
display: block;
position: absolute;
top: 0;
width: 32px;
height: 100%;
line-height: 122px;
font-size: 18px;
text-align: center;
`


const getIn = (m, keys) => keys.length ? getIn(m && m[keys[0]], keys.slice(1)) : m

const CategoriesMenu = inject('state')(observer(({ state, categories }) => {
  const categoryFilter = state.categoryFilter
  const selected = categoryFilter.split('>>').filter(x => x)
  const nextLevel = Object.keys(getIn(categories, selected) || {}).sort((a, b) => (categoriesData[a] || {}).order - (categoriesData[b] || {}).order).filter(x => x)
  const [arrowsVisible, setArrowsVisible] = useState([false, false])

  const allProducts = {
    key: '00all',
    name: "Wszystkie produkty",
  }

  const scrollRef = useRef()

  const items = (selected.length > 0 ? [allProducts] : [])
    .concat(selected.map((x, i) => ({
      key: "" + i + x,
      name: x,
      active: i === selected.length - 1,
      arrowBefore: true,
      link: categoryToUrl(selected.slice(0, i + 1)),
    })))
    .concat(((!selected.length || nextLevel.length > 1) ? nextLevel : []).map(x => ({
      key: "" + selected.length + x,
      name: x,
      link: categoryToUrl(selected.concat(x)),
    })))

  const scrollHandle = () => {
    const el = scrollRef.current
    setArrowsVisible([
      el.scrollLeft > 0,
      el.clientWidth + el.scrollLeft < el.scrollWidth,
    ])
  }
  var throttleTimeout = null
  const throttledScrollHandle = () => {
    if (throttleTimeout === null) {
      throttleTimeout = setTimeout(() => {
        throttleTimeout = null
        scrollHandle()
      }, 200)
    }
  }

  const transitions = useTransition(items, {
    //ref: transRef,
    //config: {duration: 300},
    keys: (x) => x.key,
    trail: 300 / items.length,
    //config: config.gentle,
    from: { opacity: 0, transform: 'scale(0)', width: 0 },
    enter: { opacity: 1, transform: 'scale(1)', width: 122 },
    leave: { opacity: 0, transform: 'scale(0)', width: 0 },
    onRest: throttledScrollHandle,
  })

  useLayoutEffect(() => {
    const el = scrollRef.current
    el.addEventListener('scroll', throttledScrollHandle)
    return () => el.removeEventListener('scroll', throttledScrollHandle)
  }, [])

  return <Wrapper>
    <div ref={scrollRef}>
      <div>
        {transitions((style, item) => <animated.div
          key={item.key}
          style={{ ...style, position: 'relative' }}
          className={item.key}
        >
          {item.arrowBefore && <RightOutlined style={{
            fontSize: 16, color: '#444',
            top: 55,
            left: -8,
            position: 'absolute',
          }} />}
          <Category
            name={item.name}
            active={item.active}
            link={item.link ? pathTo('category', { category: item.link }) : pathTo('products')}
          />
        </animated.div>)}
      </div>
    </div>
    <Arrow
      onClick={() => scrollRef.current.scrollBy({ top: 0, left: -scrollRef.current.clientWidth, behavior: 'smooth' })}
      style={{
        display: arrowsVisible[0] ? 'block' : 'none',
        left: 0,
        background: 'linear-gradient(270deg, rgba(240, 242, 245, 0), rgba(240, 242, 245, 1) 50%)',
      }}
    >
      <LeftOutlined />
    </Arrow>
    <Arrow
      onClick={() => scrollRef.current.scrollBy({ top: 0, left: scrollRef.current.clientWidth, behavior: 'smooth' })}
      style={{
        display: arrowsVisible[1] ? 'block' : 'none',
        right: 0,
        background: 'linear-gradient(90deg, rgba(240, 242, 245, 0), rgba(240, 242, 245, 1) 50%)',
      }}
    >
      <RightOutlined />
    </Arrow>
  </Wrapper>
}))

export default CategoriesMenu
