import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'mobx-react';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { Router } from 'react-router';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { state } from './models/AppState'
import { pageview } from './analytics'

const browserHistory = createBrowserHistory();
const routingStore = new RouterStore();

const stores = {
  // Key can be whatever you want
  routing: routingStore,
  state: state
  // ...other stores
};

const history = syncHistoryWithStore(browserHistory, routingStore);

ReactDOM.render(
  <Provider {...stores}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root')
);

history.listen((location) => {
  pageview()

  if((location.pathname || "").match('b2b')) {
    window.document.title = 'fablo.pl Twój lokalny biznes online.'
  } else {
    window.document.title = 'Fablo.pl. Kup zdalnie. Odbierz lokalnie.'
  }
  
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
