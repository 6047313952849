import React, { useState, useEffect, useContext, useRef } from 'react'
import {
  Modal,
  Input,
  Spin,
  Affix,
  Badge,
  Drawer,
  Divider,
  Collapse,
  Radio,
  Space,
} from 'antd'
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Link, } from "react-router-dom";
import {
  LangSwitch,
  Header,
  Header2,
  ContactButton,
  Wrapper,
  GlobalStyle,
  readQrCodeCookie,
  t,
  HeadText,
  CartButton,
  Button,
  ScanQRAgain,
} from './Common.js'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react';

import { Products } from '../components/ProductsPage'
import { state } from '../models/AppState';
import { formatPrice } from '../utils.js';

const SearchInput = styled(Input)`
background: #f0f2f5;
border-radius: 12px;
padding: 12px;
max-width: 635px;
input { font-size: 16px; }
`;

const Search = inject('state')(observer(({ state }) => {
  const search = (phrase, now) => {
    if (now) {
      state.setSearchNow(phrase)
    } else {
      state.setSearch(phrase)
    }
    /*if (!phrase) {
      history.replace(pathTo('products'))
    } else {
      if (!url_phrase) {
        history.push(pathTo('search', { search_phrase: encodeURIComponent(phrase) }))
      } else {
        history.replace(pathTo('search', { search_phrase: encodeURIComponent(phrase) }))
      }
    }*/
  }
  return <Affix>
    <div style={{
      backgroundColor: '#fff',
      margin: '0 -12px',
      padding: 12,
      boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 2px'
    }}>
      <SearchInput
        placeholder="Search..."
        prefix={<SearchOutlined />}
        size="large"
        id="search-input"
        value={state.searchPhrase}
        type="search"
        onChange={(e) => search(e.target.value, false)}
        onPressEnter={(e) => search(e.target.value, true)}
        allowClear={true}
      />
    </div>
  </Affix>

}))

const CartList = inject('state')(observer(({ state, close }) => {
  const [summary, setSummary] = useState(false)
  const [thx, setThx] = useState(false)

  const closeAll = () => {
    setSummary(false)
    setThx(false)
    close()
    state.clearCart()
  }

  const { setInCart, products, currentStore } = state
  const cart = state.cart[currentStore] || {}
  const total = state.cartTotal

  //<ProductLineCart key={id} product={products[id]} quantity={cart[id]} setInCart={setInCart} />
  const Products = ({ prices }) => <div>
    {Object.keys(cart).map((id) => {
      const product = products[id]
      return <div key={id} style={{
        display: 'flex',
        alignItems: 'top',
        marginBottom: 8,
      }}>
        <div style={{flex: 1, marginRight: 24}}>
          <span style={{fontSize: 16, fontWeight: 600}}>{product.name}</span>
          {cart[id] > 1 && <span style={{marginLeft: 8}}>(x{cart[id]})</span>}
        </div>
        {prices && <div>{formatPrice(product.price)} PLN</div>}
        {prices && <CloseCircleOutlined style={{marginLeft: 12, marginTop: 4}} onClick={() => setInCart(product, 0)}/>}
      </div>
    })}
  </div>


  const Summary = () => <div>
    <p style={{
      fontSize: '1.5em',
      marginBottom: 0,
    }}>{t({ pl: 'Wartość zamówienia', en: 'Total price' })}: <b>{total.toFixed(0)} PLN</b></p>

    <p style={{
      fontWeight: 600,
      marginTop: 36,
    }}>Payment method:</p>
    <Radio.Group size="large">
      <Space direction="vertical">
        <Radio style={{ fontSize: 16 }} value={1}>Add to the room bill</Radio>
        <Radio style={{ fontSize: 16 }} value={2}>Pay online</Radio>
      </Space>
    </Radio.Group>

  </div>

  return <div style={{
    fontSize: 16,
    fontFamily: 'FS Lola',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    color: 'var(--primary)',
  }}>
    <p style={{
      fontSize: '1.5em',
      fontWeight: 600,
      marginBottom: 0,
    }}>{t({ pl: 'Twoje zamówienie', en: 'Your order' })}</p>

    <Divider />

    <div style={{
      flex: 1,
      overflow: 'scroll',
    }}>
      {!summary ? <Products prices /> : <Summary />}
    </div>

    <div style={{ textAlign: 'center' }}>
      <Button
        type="primary"
        size="large"
        onClick={() => {
          if (summary) {
            setThx(true)
          } else {
            setSummary(true)
          }
        }}
      >Order</Button>
    </div>

    <Drawer
      visible={thx}
      onClose={closeAll}
      placement="bottom"
      height="100%"
    >
      <Wrapper>
        <Header2 hotelId={currentStore} />
        <p style={{
          textAlign: 'center',
          fontWeight: 600,
          fontSize: '1.5em',
          color: 'var(--primary)',
        }}>Thank you for your order!</p>
        <Collapse style={{fontSize: 16, marginBottom: 36}}>
          <Collapse.Panel header="See order details" key="1">
            <Products />
          </Collapse.Panel>
        </Collapse>
        <p style={{textAlign: 'center', color: 'var(--primary)'}}>Want to change something?</p>
        <ContactButton />
      </Wrapper>
    </Drawer>

  </div>
}))

const CartBar = inject('state')(observer(({ state }) => {
  const [listOpened, setListOpened] = useState(false)

  const cart = state.cart[state.currentStore] || {}
  const cartSize = Object.keys(cart).length

  // fixme: bottom button copied from restaurant menu
  return <div>
    <div style={{
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      padding: '8px 0',
      background: 'rgba(255, 255 ,255, 0.9)',
      boxShadow: 'rgb(0 0 0 / 5%) 0px -1px 2px',
    }}>
      <div style={{ margin: '0 auto', width: 200, }}>
        <Badge count={cartSize}>
          <Button
            style={{
              width: 200,
              height: 'auto',
              fontSize: '20px',
              fontWeight: '500',
            }}
            type="primary"
            onClick={() => setListOpened(true)}
            disabled={cartSize == 0}
          >
            {t({
              pl: 'Twoje zamówienie',
              en: 'Your order',
            })}
          </Button>
        </Badge>
      </div>
    </div>
    <Drawer
      visible={listOpened}
      onClose={() => setListOpened(false)}
      placement="bottom"
      height={550}
    >
      <CartList
        close={() => setListOpened(false)}
      />
    </Drawer>
  </div >
}))

const ShopPage = ({ hotelId }) => {
  useEffect(() => {
    state.setStore(hotelId)
    state.fetchProducts(hotelId)
  }, [])

  return <div>
    <Header hotelId={hotelId} />
    <Search />

    <div style={{
      backgroundColor: '#f0f2f5',
      margin: '0 -12px 56px',
    }}>
      <Products />
    </div>

    <CartBar />
  </div>
}

export default ShopPage
