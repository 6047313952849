import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react';
import {
  Form,
  Input,
  Checkbox,
  Button as ButtonAnt,
  Modal,
  Steps,
  message,
  Radio
} from 'antd';
import styled, { css } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs'
import images from 'react-payment-inputs/images';
import PageWrapper, { Container, H1, H2, H3, H4, Lead, Hero } from './PageWrapper'
import { encryptCard } from './CardInput'

const FormWrapper = styled.div`
width: min(960px, 100%);
text-align: center;
margin-top: 24px;
background: #fff;
border: 1px solid rgb(236, 236, 236);
border-radius: 6px;
padding: 39px;
.ant-form-item {
  margin-bottom: 12px;
  .ant-input {
    background: #fcfcfc;
    font-size: 14px;
  }
  .ant-form-item-control-input-content > .ant-input,
  .ant-form-item-control-input-content > .ant-input-password {
    background: #fcfcfc;
    border: 1px solid #ececec;
  }
  input::placeholder {
    color: #b3b3b3;
  }
}
.ant-form-item-control {
  max-width: 100%;
}

a {
  color: #2E773A;
  text-decoration: underline;
}
label {
  font-size: 14px;
}
.ant-radio-wrapper {
  margin: 0 15px;
}
`

const Button = styled(ButtonAnt)`
&:hover {
  color: #283E46;
  border-color: #283E46;
}

&.ant-btn-primary {
  background: #2E773A;
  border-color: #2E773A;
  &:hover {
    color: #fff;
    background: #283E46;
    border-color: #283E46;
  }
}
`

const StepsA = styled(Steps)`
max-width: 500px;
margin: 0 auto 32px;
text-align: left;
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background: #2E773A;
}
`

const Progress = ({ step }) => {
  return <StepsA
    current={step}
    progressDot
  >
    <Steps.Step />
    <Steps.Step />
    <Steps.Step />
  </StepsA>
}

const Api = axios.create({
  baseURL: '/api',
});

const validNIP = (nip) => {
  if (!nip) return Promise.resolve();
  const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7]
  if (nip.length === 10) {
    var sum = 0;
    for (var i = 0; i < 9; i++) {
      sum += nip[i] * weights[i];
    }
    if ((sum % 11) === Number(nip[9])) {
      return Promise.resolve();
    }
  }
  return Promise.reject('Sprawdź poprawność numeru NIP');
}

const doRegistration = (values) => {
  return Api.post('/shop/owner', values).catch(err => {
    if (err && err.response && err.response.data) {
      const reason = err.response.data.text;
      if (reason === "This email is already registered") {
        Modal.error({ title: "Ten adres e-mail jest już zarejestrowany.", content: 'Możesz się zalogować.' })
        throw err
      }
      if ((err.response.data.message || "").match('There is no company with number')) {
        Modal.error({ title: "Numer NIP nie został odnaleziony", content: 'Sprawdź NIP lub skontaktuj się z nami.' })
        throw err
      }
    }
    Modal.error({ title: "Rejestracja nie powiodła się.", content: 'Spróbuj później lub skontaktuj się z nami.' })
    throw err
  })
}


const QuestionsForm = ({ nextStep }) => {
  return <FormWrapper>
    <Progress step={0}/>

    <H3 style={{ marginBottom: 8 }}>1. Poznajmy się</H3>
    <p style={{ marginBottom: 32 }}>
      Cieszymy się, że chcesz dołączyć do społeczności tworzącej Fablo. Odpowiedz na pytania poniżej abyśmy mogli poznać Cię lepiej.
    </p>

    <Form
      layout="vertical"
      style={{fontSize: 14}}
      onFinish={(values) => {
        nextStep(values)
      }}
    >
      <p style={{textAlign: 'center', fontSize: 14, fontWeight: 'bold', marginBottom: 16}}>Mój biznes to:</p>
      <Form.Item
        name="business_type"
        rules={[{
          required: true,
          message: 'Wybierz odpowiedź',
        }]}
      >
        <Radio.Group>
          <Radio value="Sklep spożywczy"><br/>Sklep spożywczy</Radio>
          <Radio value="Sklep inny"><br/>Sklep inny</Radio>
          <Radio value="Gastronomia"><br/>Gastronomia</Radio>
        </Radio.Group>
      </Form.Item>
      <p style={{textAlign: 'center', fontSize: 14, fontWeight: 'bold', marginBottom: 16, marginTop: 50}}>Liczba lokalizacji:</p>
      <Form.Item
        name="count"
        rules={[{
          required: true,
          message: 'Wybierz odpowiedź',
        }]}
      >
        <Radio.Group>
          <Radio value="1"><br/>1</Radio>
          <Radio value="2"><br/>2</Radio>
          <Radio value="3+"><br/>3+</Radio>
        </Radio.Group>
      </Form.Item>
      <Form.Item style={{ marginTop: 50 }}>
        <Button type="primary" htmlType="submit" size="large">
          DALEJ
        </Button>
      </Form.Item>
    </Form>

  </FormWrapper>
}

const RegistrationForm = ({ nextStep, setShopId, questionnaire }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const plan = ((location.search || '').match(/plan=([^&]+)/) || [])[1]

  const onFinish = values => {
    setLoading(true)
    doRegistration({
      email: values.email,
      password: values.password,
      nip: values.nip,
      plan: values.plan,
      questionnaire
    }).then((res) => {
      setLoading(false)
      setShopId(res.data['shop-id'])
      nextStep()
    }).catch(() => {
      setLoading(false)
    })
  };

  return (
    <FormWrapper>

      <Progress step={1} />

      <H3 style={{ marginBottom: 8 }}>2. Utwórz konto swojej firmy</H3>
      <p style={{ marginBottom: 32 }}>Chcemy, aby Fablo tworzyli aktywni przedsiębiorcy, dlatego weryfikujemy dane w CEiDG i KRS.</p>

      <Form
        form={form}
        onFinish={onFinish}
        scrollToFirstError
        layout="vertical"
        style={{
          margin: "0 auto",
          maxWidth: 500,
        }}
        initialValues={{
          plan,
        }}
      >


        <Form.Item
          name="nip"
          type="number"
          rules={[
            {
              required: true,
              message: 'Wprowadź swój NIP',
            },
            {
              pattern: /^\d+$/,
              message: 'Wpisz tylko cyfry'
            },
            {
              validator: (_, v) => validNIP(v),
              message: 'Sprawdź poprawność numeru NIP',

            }
          ]}
          normalize={(v) => v.replace(/\D/g, '')}
        >
          <Input
            placeholder="NIP"
          />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            {
              type: 'email',
              message: 'Wprowadź poprawny adres E-mail',
            },
            {
              required: true,
              message: 'Wprowadź swój adres E-mail',
            },
          ]}
        >
          <Input
            placeholder="Adres e-mail"
            type="email"
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Wprowadź hasło',
            },
          ]}
          hasFeedback
        >
          <Input.Password
            placeholder="Hasło"
          />
        </Form.Item>

        <Form.Item
          name="confirm"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Wprowadź ponownie swoje hasło',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('Wprowadzone hasła są różne');
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="Potwierdź hasło"
          />
        </Form.Item>

        <Form.Item name="plan" noStyle><Input type="hidden" /></Form.Item>

        <Form.Item
          name="agreement"
          valuePropName="checked"
          rules={[
            { validator: (_, value) => value ? Promise.resolve() : Promise.reject('Musisz zaakceptować regulamin') },
          ]}
        >
          <Checkbox>
            Akceptuję <a target="_blank" href="/regulaminy#dla-sprzedawcow">regulamin</a>
          </Checkbox>
        </Form.Item>
        <Form.Item style={{ margin: 0 }}>
          <Button type="primary" htmlType="submit" size="large" loading={loading}>
            DALEJ
          </Button>
        </Form.Item>
      </Form>
      <Link to="/pomoc" style={{ fontSize: 11, marginTop: 50, display: 'block', textDecoration: 'undeline' }}>
        Masz problem z rejestracją? Skontaktuj się z nami
      </Link>
    </FormWrapper>
  );
};


const CreditCardForm = ({ shopId }) => {
  const {
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    meta
  } = usePaymentInputs({
    errorMessages: {
      emptyCardNumber: 'Podaj numer karty',
      invalidCardNumber: 'Sprawdź numer karty',
      emptyExpiryDate: 'Podaj datę ważności karty',
      monthOutOfRange: 'Popraw miesiąc daty ważności karty',
      yearOutOfRange: 'Popraw rok daty ważności karty',
      dateOutOfRange: 'Popraw datę ważności karty',
      invalidExpiryDate: 'Popraw datę ważności karty',
      emptyCVC: 'Podaj kod CVC',
      invalidCVC: 'Popraw kod CVC'
    }
  })

  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const location = useLocation()
  //const plan = ((location.search || '').match(/plan=([^&]+)/) || [])[1]

  const apiKey = useMemo(() => Api.get('/tpay-public-key').then(({ data }) => data), [])


  useEffect(() => {
    apiKey.then(key => {
      encryptCard(key, {card_number: '1234567891234568', expiry_date: '12/23', cvc: '123'}).then(enc => {
        console.log("enc", enc)
      })
    })
  }, [apiKey])

  const encrypt = (values) => apiKey.then(key => encryptCard(key, values))


  const setupSubscription = ({ card_cypher, card_holder, back_url }) => {
    return Api.post(`/shop/${shopId}/plan-subscription`, {
      card_cypher,
      card_holder,
      back_url,
      fail_url: window.location.origin + '/rejestracja?card-failed'
    })
  }

  const backUrl = () => {
    if (window.location.hostname.match("demo.fablo.pl")) {
      return '//panel.demo.fablo.pl/payments?first-time'
    } else {
      return '//my.fablo.pl/payments?first-time'
    }
  }

  const onFinish = (values) => {
    if (meta.error) return

    setLoading(true)

    encrypt(values).then((card_cypher) => {
      return setupSubscription({
        card_cypher,
        card_holder: values.card_holder,
        back_url: backUrl(),
      })
        .then((res) => {
          setLoading(false)
          window.location = res.data.redirect_url
        })
    }).catch((err) => {
      if (err.response && err.response.data && err.response.data.cause === 'card-check-failed') {
        Modal.error({
          title: 'Nie udało się zweryfikować karty',
        })
      } else {
        message.error("Wystąpił błąd")
      }
      setLoading(false)
    })
  }

  return <FormWrapper>
    <Progress step={2} />
    <H3 style={{ marginBottom: 8 }}>3. Podaj metodę rozliczeń</H3>
    <p style={{ fontSize: 16, marginBottom: 32 }}>Pierwsza płatność zostanie pobrana po 14 dniach. Kolejne będą pobierane co miesiąc.</p>
    <Form
      form={form}
      onFinish={onFinish}
      scrollToFirstError
      layout="vertical"
      style={{
        margin: "0 auto",
        maxWidth: 500,
      }}
    >
      <Form.Item
        name="card_holder"
        rules={[{
          required: true,
          message: 'Pole wymagane',
        }, {
          max: 30,
          message: 'Max 30 znaków',
        }]}
      >
        <Input
          placeholder="Podaj Imię i Nazwisko właściela karty"
        />
      </Form.Item>

      <PaymentInputsWrapper {...wrapperProps} styles={{
        fieldWrapper: {
          base: css`
              width: 100%;
              margin-bottom: 12px;
            `,
        },
        inputWrapper: {
          base: css`
              background: #fcfcfc;
              font-size: 14px;
              border: 1px solid #ececec;
              border-radius: 6px;
              box-shadow: none;
              justify-content: space-between;
            `,
          errored: css`
              border: 1px solid #ececec;
            `
        },
        input: {
          base: css`
              background: #fcfcfc;
              &::placeholder {
                color: #b3b3b3;
              }
            `
        },
        errorText: {
          base: css`
              color: #ff4d4f;
            `
        }
      }} >
        <svg {...getCardImageProps({ images })} />
        <input
          {...getCardNumberProps({ onChange: (e) => form.setFieldsValue({ card_number: e.target.value }) })}
          placeholder="Podaj numer karty"
          style={{ flex: 1 }}
        />
        <input
          {...getExpiryDateProps({ onChange: (e) => form.setFieldsValue({ expiry_date: e.target.value }) })}
          placeholder="MM/RR"
        />
        <input
          {...getCVCProps({ onChange: (e) => form.setFieldsValue({ cvc: e.target.value }) })}
        />
      </PaymentInputsWrapper>

      <Form.Item name="card_number" noStyle><input type="hidden" /></Form.Item>
      <Form.Item name="expiry_date" noStyle><input type="hidden" /></Form.Item>
      <Form.Item name="cvc" noStyle><input type="hidden" /></Form.Item>

      <Form.Item
        name="promo_code"
      >
        <Input
          placeholder="Kod promocyjny"
        />
      </Form.Item>

      <Form.Item style={{ margin: "55px 0 16px" }}>
        <Button type="primary" htmlType="submit" size="large" loading={loading}>
          DALEJ
          </Button>
      </Form.Item>
    </Form>
  </FormWrapper>
}

const RegistrationPage = () => {
  const location = useLocation()
  const cardError = location.search.match('card-failed')
  const [step, setStep] = useState(0)
  const [shopId, setShopId] = useState(null)

  const [questionnaire, setQuestionnaire] = useState({})

  useEffect(() => {
    Api.get('/login/info').then(({ data }) => {
      if (data && data.user) {
        window.location = `//${window.location.hostname.match("demo") ? 'panel.demo' : 'my'}.fablo.pl`
      }
      /*
      const id = data['shop-id']
      if (id) {
        setShopId(id)
        setStep(!cardError ? 1 : 2)

        Api.get(`/shop/${id}/paylane`).then(({ data }) => {
          if(data.registration_data) {
            setStep(2)
          }
        })
      }
      */
    })
  }, [])

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
    if (cardError) {
      Modal.error({ title: "Nie udało się autoryzować karty" })
    }
  }, [cardError])

  const bulletStyle = {fontWeight: 'bold', color: '#2E773A', marginRight: 12, border: '3px solid #2E773A', borderRadius: '50%', width: 32, height: 32, display: 'inline-block', lineHeight: '26px', textAlign: 'center'}

  return <PageWrapper
    buttonsButtons={<>
      <Link to="/pomoc"><Button>POMOC</Button></Link>
      <a href={`//${window.location.hostname.match("demo") ? 'panel.demo' : 'my'}.fablo.pl/login`}>
        <Button
          type="primary"
          style={{ marginLeft: 13, }}
        >
          ZALOGUJ
        </Button>
      </a>
    </>}
  >
    <Hero>
      <Container style={{ paddingTop: 50, paddingBottom: 100 }}>
        {step === 0 &&
          <QuestionsForm nextStep={(v) => {
            setQuestionnaire(v)
            setStep(1)}
          } />
        }
        {step === 1 &&
          <RegistrationForm nextStep={() => setStep(2)} setShopId={setShopId} questionnaire={questionnaire} />
        }
        {/*step == 1 &&
          <PaylaneOptInForm shopId={shopId} nextStep={() => setStep(2)} />
        */}
        {/*step == 1 &&
          <CreditCardForm shopId={shopId} />
        */}
        {step === 2 &&
          <FormWrapper>
            <Progress step={1} />
            <H3 style={{
              color: '#2E773A',
              fontWeight: 600,
              margin: '50px 0 30px',

            }}>
              Doskonale! Założyłeś konto.
            </H3>
            <div style={{textAlign: 'left', fontSize: 16, width: 275, margin: '0 auto 30px', lineHeight: '60px'}}>
            <p style={{textAlign: 'center', marginBottom: 0}}>Aby zacząć sprzedawać:</p>
            <div><div style={bulletStyle}>1</div> Uzupełnij ustawienia.</div>
            <div><div style={bulletStyle}>2</div> Dodaj ofertę.</div>
            <div><div style={bulletStyle}>3</div> Poinformuj Swoich klientów!</div>
            </div>

            <Button
              type="primary"
              size="large"
              onClick={() => {
                if (window.location.hostname.match("demo.fablo.pl")) {
                  window.location = '//panel.demo.fablo.pl/settings?first-time'
                } else {
                  window.location = '//my.fablo.pl/settings?first-time'
                }
              }}
            >
              DALEJ
            </Button>
          </FormWrapper>
        }
      </Container>
    </Hero>
  </PageWrapper>

}

export default RegistrationPage
