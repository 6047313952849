import React, { Component, useState } from 'react';
import styled from 'styled-components';
import { Button, Drawer, InputNumber, Popconfirm } from 'antd';
import { inject, observer } from 'mobx-react';
import { ArrowRightOutlined, CloseCircleFilled } from '@ant-design/icons';
import { formatPrice, imageUrl, quantityStep, productOptionsTotalPrice, imageErrorHandler, formatQuantity } from '../utils'
import SwipeToDelete from 'react-swipe-to-delete-ios'
import { Logo } from './PageWrapper'
import CheckoutForm, { TotalPriceNotice } from './Checkout'

const Prod = styled.div`
display: flex;
flex-direction: row;
border-bottom: 1px solid #f0f0f0;
align-items: center;
justify-content: space-between;
padding: 12px 0;
@media(max-width: 500px) {
    //flex-wrap: wrap;
}
`

const PieceWrapper = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-end;
@media(max-width: 500px) {
    //flex: 1;
    //flex-wrap: wrap;
    //width: 92px;
}
`

const ProdImg = styled.img`
width: 84px;
height: 84px;
margin: -6px 12px -6px 0;
object-fit: contain;
`

const ProdName = styled.div`
flex: 1;
margin-right: 12px;
max-width: 180px;
max-height: 88px;
overflow: hidden;
color: #444;
`

const ProdTotal = styled.div`
width: 62px;
text-align: right;
font-size: 13px;
`

@observer
class ProductLineCart extends Component {
    render() {
        const { product, quantity, setInCart, options } = this.props
        if (!product) {
            return <div />
        }
        const opts = Object.values(options || {}).map(v => v.join ? v.join(', ') : v).join(', ')
        if (quantity && typeof quantity === 'object') {
            return Object.entries(quantity).map(([options, quantity], i) => (
                <ProductLineCart
                    key={i}
                    product={product}
                    options={JSON.parse(options)}
                    quantity={quantity}
                    setInCart={setInCart}
                />
            ))
        }

        const optionsPrice = !options ? 0 : productOptionsTotalPrice(product, JSON.stringify(options))

        if (!product) return <div />

        return <SwipeToDelete
            height={97}
            onDelete={() => setInCart(product, 0, options)}
            deleteWidth={100}
            deleteComponent={<>Usuń <CloseCircleFilled style={{ marginLeft: 12 }} /></>}
        >
            <div style={{
                background: '#fff',
                padding: '0 12px',
            }}>


                <Prod>
                    <ProdImg src={imageUrl(product)} onError={imageErrorHandler} />
                    <ProdName>
                        {product.name}
                        {opts && <span style={{ display: 'block', marginTop: 12 }}>{opts}</span>}
                    </ProdName>
                    <PieceWrapper>
                        <InputNumber
                            value={quantity}
                            min={quantityStep(product.unit)}
                            precision={quantityStep(product.unit) < 1 ? 1 : 0}
                            step={quantityStep(product.unit)}
                            style={{ width: "56px", margin: "4px 0" }}
                            onChange={(x) => setInCart(product, x, options)}

                        />
                        <div>&nbsp;{product.unit || 'szt.'}</div>
                        <ProdTotal>
                            <b>{formatPrice((product.price + optionsPrice) * quantity)}</b> zł
                </ProdTotal>
                        <Popconfirm
                            className="only-desktop"
                            title="Czy na pewno usunąć ten produkt z koszyka?"
                            onConfirm={() => setInCart(product, 0, options)}
                            okText="Tak"
                            cancelText="Nie"
                            placement="topRight"
                        >
                            <CloseCircleFilled style={{ color: '#ff4d4f', marginLeft: '12px', fontSize: 17 }} />
                        </Popconfirm>
                    </PieceWrapper>
                </Prod>
            </div>
        </SwipeToDelete>
    }
}

const BreakfastProductLineCart = ({ product, quantity, setInCart, options }) => {
    if (!product) return <div />

    if (quantity && typeof quantity === 'object') {
        return Object.entries(quantity).map(([options, quantity], i) => (
            <BreakfastProductLineCart
                key={i}
                product={product}
                options={JSON.parse(options)}
                quantity={quantity}
                setInCart={setInCart}
            />
        ))
    }

    const optionsPrice = !options ? 0 : productOptionsTotalPrice(product, JSON.stringify(options))

    return <SwipeToDelete
        //height={97}
        onDelete={() => setInCart(product, 0, options)}
        deleteWidth={100}
        deleteComponent={<>Delete <CloseCircleFilled style={{ marginLeft: 12 }} /></>}
    >
        <div style={{
            background: '#fff',
            padding: '0 12px',
        }}>
            <Prod style={{alignItems: 'flex-start'}}>
                <div>
                    <ProdName style={{
                        fontFamily: 'FS Lola',
                        fontSize: 20,
                        fontWeight: 500,
                        color: '#509E2F',
                        maxWidth: 'none',
                    }}>
                        {product.name}
                    </ProdName>
                    <ul style={{
                        fontFamily: 'FS Lola',
                        fontSize: 16,
                    }}>
                        {Object.values(options || {}).map(v => <li>{v.join ? v.join(', ') : v}</li>)}
                    </ul>
                </div>
                <PieceWrapper>
                    <ProdTotal style={{
                        width: 'auto',
                        fontFamily: 'FS Lola',
                        fontSize: 18,
                        lineHeight: '31px',
                    }}>
                        {quantity > 1 && (formatQuantity(quantity) + ' × ')}
                        <b>{formatPrice((product.price + optionsPrice) * quantity)}</b>&nbsp;zł
                    </ProdTotal>
                    <Popconfirm
                        className="only-desktop"
                        title="Czy na pewno usunąć ten produkt z koszyka?"
                        onConfirm={() => setInCart(product, 0, options)}
                        okText="Tak"
                        cancelText="Nie"
                        placement="topRight"
                    >
                        <CloseCircleFilled style={{ color: '#ff4d4f', marginLeft: '12px', fontSize: 17 }} />
                    </Popconfirm>
                </PieceWrapper>
            </Prod>
        </div>
    </SwipeToDelete>
}

const Container = styled.div`
display: flex;
flex-direction: column;
flex: 1;
`;

const ProductsList = styled.div`
flex: 1;
overflow: scroll;
margin: 0 -24px;
//padding: 0 12px;
border-bottom: 1px solid #f0f0f0;
`;


const CartProducts = inject('state')(observer(({ state }) => {

    const [checkout, setCheckout] = useState(false)

    const { setInCart, products, currentStore } = state
    const cart = state.cart[currentStore] || {}
    const total = state.cartTotal
    const store = state.currentStoreData
    var { packing_cost, min_order_value } = store
    min_order_value = min_order_value || 0

    const breakfast = state.currentStoreData && state.currentStoreData.flags.breakfast

    return <Container>

        <ProductsList>
            {Object.keys(cart).map((id) => <>
                {!breakfast && <ProductLineCart key={id} product={products[id]} quantity={cart[id]} setInCart={setInCart} />}
                {breakfast && <BreakfastProductLineCart key={id} product={products[id]} quantity={cart[id]} setInCart={setInCart} />}
                </>
            )}

            {(packing_cost > 0) &&
                <Prod>
                    <ProdName style={{ marginLeft: 102, maxWidth: 220 }}>Koszt przygotowania zamówienia</ProdName>
                    <ProdTotal style={{ marginRight: 27 }}>
                        <b>{formatPrice(packing_cost)}</b> zł
                    </ProdTotal>
                </Prod>
            }

        </ProductsList>

        <TotalPriceNotice
            store={store}
            total={total}
        />

        {total === packing_cost &&
            <div style={{ padding: "0 0 16px", lineHeight: "2em", color: "#820014", textAlign: 'center' }}>
                Dodaj produkty do koszyka aby złożyć zamówienie
            </div>}
        {(total > packing_cost && total < min_order_value) &&
            <div style={{ padding: "0 0 16px", lineHeight: "2em", color: "#820014", textAlign: 'center' }}>
                Minimalna wartość zamówienia to: {formatPrice(min_order_value)} zł
            </div>}
        <Button
            type={total >= Math.max(0.01, min_order_value) ? "primary" : "disabled"}
            shape="round"
            size="large"
            icon={<ArrowRightOutlined />}
            onClick={() => total > 0 && setCheckout(true)}
            style={breakfast ? {
                background: '#509E2F',
                borderColor: '#509E2F',
                fontSize: 22,
                fontFamily: 'FS Lola',
                fontWeight: 500,
                height: 50,
            } : {}}
        >
            {!breakfast ? 'Złóż zamówienie' : 'Confirm & pay'}
        </Button>

        <Drawer
            //title="Czy chcesz zamówić poniższe produkty? "
            visible={checkout}
            onClose={() => setCheckout(false)}
            placement="bottom"
            height="100%"
        >
            {!breakfast && <Logo
                flat={true}
                homeLink={null}
            />}
            {breakfast && <img
                src="https://digital.ihg.com/is/image/ihg/holiday-inn_lsc_lkp_d_r_rgb_pos-web?fmt=png-alpha"
                style={{maxWidth: 300, height: 45 }}
            />}
            <CheckoutForm onClose={() => setCheckout(false)} />
        </Drawer>

    </Container>
}))

export { CartProducts }
