import React, { Component, useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import ProductsPage from './components/ProductsPage'
import LegalPage from './components/LegalPage'
//import HomePage from './components/HomePage';
import HelpPage from './components/HelpPage';
//import B2BPage from './components/B2BPage'
import RegistrationPage from './components/RegistrationPage'
import ShopCardPage from './components/ShopCardPage'
import { ConfirmationPage } from './components/Checkout'
import NotFoundPage from './components/NotFoundPage'
import Breakfast from './hotel/Breakfast'
import HotelWelcomePage from './hotel/Welcome'
import { Switch, Route, useParams, useHistory, useRouteMatch, generatePath, Redirect } from 'react-router';
import './App.less';
import { Spin } from 'antd';
import { routes } from './routes'

const shopPaths = ['produkty', 'zamowienie']

const WithShop = inject('state')(({children, state}) => {
  const { shop_id, shop_slug } = useParams();
  const history = useHistory();
  const match = useRouteMatch()
  const [ready, setReady] = useState();

  useEffect(() => {
    state.setStore(shop_id)
    .then(() => {
      setReady(true)

      // fix slug
      if(shop_slug !== state.currentStoreSlug) {

        if(shopPaths.includes(shop_slug)) {
          history.replace(generatePath(match.path, {
            ...match.params,
            shop_slug: state.currentStoreSlug,
            path: [shop_slug].concat(decodeURIComponent(match.params.path).split('/'))
          }))
          return
        }

        const newPath = generatePath(match.path, {
          ...match.params,
          shop_slug: state.currentStoreSlug,
        })
        history.replace(newPath)
      }

    }).catch(() => {
      history.replace('/404')
    })
  }, [shop_id])
  if(ready) {
    return <Switch>{children}</Switch>
  } else {
    return <div style={{textAlign: 'center', paddingTop: '40vh'}}>
        <Spin delay={200}/>
      </div>
  }
})

const ShopSlugRewrite = inject('state')(({ state }) => {
  const { shop_slug } = useParams()
  const history = useHistory()
  const match = useRouteMatch()
  const [ready, setReady] = useState()

  useEffect(() => {
    state.setStore(shop_slug)
    .then(() => {
      setReady(true)
    }).catch(() => {
      history.replace('/404')
    })
  }, [shop_slug])
  if(ready) {



    const newPath = generatePath(`/${state.currentStore}` + decodeURIComponent(match.path), {
      ...match.params,
      shop_slug: state.currentStoreSlug,
    })
    return <Redirect to={newPath} push={false}/>
  } else {
    return <div style={{textAlign: 'center', paddingTop: '40vh'}}>
        <Spin delay={200}/>
      </div>
  }
})

@inject('routing')
@observer
class App extends Component {
  render() {
    return (
      <Switch>
        {/*<Route exact path="/" component={HomePage}/>
        <Route path="/b2b" component={B2BPage}/>

        <Route exact path="/" component={B2BPage}/>
        */}

        <Route exact path="/" component={() => {
          window.location.href = 'https://fablo.eu/';
          return null;
        }} />

        <Route path="/pomoc" component={HelpPage}/>
        <Route path="/regulaminy" component={LegalPage}/>
        <Route path="/rejestracja" component={RegistrationPage}/>
        <Route path="/404" component={NotFoundPage}/>

        <Route path="/hotel">
          <HotelWelcomePage />
        </Route>
        <Route path="/breakfast">
          <Breakfast />
        </Route>

        <Route path="/:shop_id(\d+)/:shop_slug/:path*">
          <WithShop>
            <Route path={routes.order} component={ConfirmationPage} />
            <Route path={routes.search} component={ProductsPage} />
            <Route path={routes.category} component={ProductsPage} />
            <Route path={routes.products} component={ProductsPage} />
            <Route path={routes.shopCard} component={ShopCardPage} />
          </WithShop>
        </Route>
        <Route path="/:shop_slug/:path*" component={ShopSlugRewrite} />

        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
    );
  }
}

export default App
