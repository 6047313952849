import React, { useMemo, useState } from 'react';
import { H1, H2, Logo } from './PageWrapper'
import styled from 'styled-components';
import { Button, Modal, Form, Input, Drawer, InputNumber, message, Radio, Select, Spin, Checkbox, Card } from 'antd';
import { inject, observer } from 'mobx-react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { formatPrice, formatQuantity, geoDistance, debounce } from '../utils'
import { useHistory } from 'react-router-dom'

import {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import axios from 'axios';
import { useEffect } from 'react';

const Centered = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
min-height: 100%;
max-width: 800px;
margin: 10px auto 0;
`

const CenteredH = styled.div`
max-width: 800px;
margin: 10px auto 0;
`

const Total = styled.div`
display: flex;
justify-content: space-between;
font-size: 24px;
padding: 12px 0;
align-items: center;
`

const paymentDone = (order) => order.paid
const paymentPending = (order) => order.payment_type === 'online' && !order.paid && order.payments
//const paymentError = (order) => order.payment_result && order.payment_result.status == "ERROR"

const PaymentRetry = ({ order, store, token }) => {
  const getState = (order) => paymentDone(order) ? "done" : (paymentPending(order) ? "pending" : "error")
  //const [state, setState] = useState(getState(order))
  const state = getState(order)
  const [formOpened, setFormOpened] = useState(false)
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()

  const doPayment = (values) => {
    setLoading(true)
    const v = { ...values }

    const postOrder = (card_token) => {
      delete v.card_number
      delete v.expiry_date
      delete v.cvc
      if (card_token) {
        v.payment_data.card_token = card_token
      }
      return axios.post(`/api/shop/${store.id}/order/${order.id}?token=${token}`, v).then((x) => {
        if (x.data.payment_url) {
          window.location = x.data.payment_url
          return
        }
        setLoading(false)
        //setState(getState(x.data))
      })
    }

    //if (values.payment_data && values.payment_data.banktransfer_type == 'card') {
    //  getCardToken(store, values).then(postOrder)
    //} else {
    postOrder()
    //}
  }

  return <div>

    {!formOpened && state === "done" &&
      <H2>Zamówienie opłacone</H2>}

    {!formOpened && state === "pending" &&
      <H2><Spin /><br />Zamówienie oczekuje na płatność <br />
        <a href={order.payments[0] && order.payments[0]['payment-url']}>
          <Button type="primary">PRZEJDŹ DO PŁATNOŚCI</Button></a>
      </H2>}

    {!formOpened && state === "error" &&
      <H2>Płatność nieudana <br />
        <Button type="primary" onClick={() => setFormOpened(true)}>PONÓW PŁATNOŚĆ</Button></H2>}

    {formOpened &&
      <Spin spinning={loading}>
        <Card title="Ponów płatność"
          style={{ margin: '50px auto' }}>
          <Form
            form={form}
            style={{ maxWidth: '520px', margin: '0 auto', width: '100%', textAlign: 'left' }}
            layout='vertical'
            onFinish={doPayment}
            onFinishFailed={() => { message.error("Sprawdź poprawność danych") }}
            initialValues={order}
          >
            <PaymentForm form={form} merchantId={store.tpay_merchant_id} />
            <Button
              size="large"
              type="primary"
              style={{ width: "100%" }}
              htmlType="submit"
            >ZAPŁAĆ</Button>
          </Form>
        </Card>
      </Spin>
    }
  </div>
}

const Confirmation = inject('state')(observer(({ orderId, order: orderInit, state, token }) => {
  const { currentStore, stores, currentStoreData } = state;
  const [order, setOrder] = useState(orderInit)

  useEffect(() => {
    var timer
    const fetch = (n) => {
      axios.get(`/api/shop/${currentStore}/order/${orderId || orderInit.id}?token=${token || orderInit._id}`)
        .then((res) => {
          const o = res.data
          setOrder(o)

          const timeout = n < 10 && o.payment_type === 'online' && !o.paid ? n * 1000 : 60 * 1000
          timer = setTimeout(() => fetch(n + 1), timeout)
        })
        .catch(() => {
          // TODO
        })
    }
    fetch(1)
    return () => clearTimeout(timer);
  }, [])

  if (!order) {
    return <div style={{
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}>
      <Spin />
    </div>
  }

  orderId = order.id
  const store = stores[currentStore]

  // temporary? for demo presentation
  const hotel = store.flags.hotel
  const breakfast = store.flags.breakfast

  if(breakfast) {
    return <Centered style={{ textAlign: 'center' }}>
      <img
        src="https://digital.ihg.com/is/image/ihg/holiday-inn_lsc_lkp_d_r_rgb_pos-web?fmt=png-alpha"
        style={{ width: 200, height: 'auto', margin:  '0 auto 100px'}}
      />
      <H1>
        Thank you!
      </H1>
      <H2 style={{padding: '0 12px'}}>
        Your order has been confirmed and will be delivered at your chosen time.
      </H2>
    </Centered>
  }

  return <Centered style={{ textAlign: 'center' }}>
    <Logo flat={true} homeLink={`/${store.slug}/`} />
    <H1>
      Dziękujemy!
        </H1>
    <H2>
      Twój numer zamówienia:<br />
    </H2>
    <H2 style={{ fontWeight: '600', lineHeight: "1em" }}><span style={{ fontSize: "2em" }}>{orderId}</span></H2>

    {!order.delivery_address && (order.payment_type !== 'online' || order.paid > 0) &&
      <H2>Odbierz za <b>{currentStoreData.order_processing_time || 30} minut</b></H2>}

    {order.delivery_address && (order.payment_type !== 'online' || order.paid > 0) &&
      <H2>Zamówienie zostanie dostarczone za <b>{(currentStoreData.order_processing_time || 30) + (currentStoreData.order_delivery_time || 30)} minut</b></H2>}

    {order.payment_type === 'online' &&
      <PaymentRetry order={order} store={currentStoreData} token={token || orderInit._id} />}


    {!hotel && !order.delivery_address &&
      <p style={{ fontSize: "16px" }}>
        Kiedy sklep skompletuje Twoje zamówienie <br />
        otrzymasz email z informacją o możliwości odbioru.
      </p>}

    {!hotel && order.delivery_address &&
      <p style={{ fontSize: "16px" }}>
        Zamówienie zostanie dostarczone pod adres: <br />
        <b>{order.delivery_address.replace(', Polska', '').replace(', Poland', '')}</b>
      </p>}


    {store &&
      <>

        <div style={{ maxWidth: 400, margin: "0 auto 16px", }}>
          <img
            src={`/api/shop/${store.id}/image`}
            style={{
              maxWidth: "100%",
              objectFit: 'cover',
              borderRadius: 6,
            }}
            alt=""
          />
        </div>
        <p style={{ fontSize: "16px" }}>{store.name}<br /> {store.street}, {store.city}</p>

      </>}
  </Centered>
}))

const ConfirmationPage = inject('state')(observer(({ match, state }) => {
  const orderId = match.params.order_id
  const token = match.params.token

  return <div style={{
    display: 'flex',
    minHeight: "100vh",
  }}>
    <Confirmation orderId={orderId} token={token} />
  </div>
}))

const TotalPriceNotice = ({ store, total }) => {
  const online = store.available_payment_types && store.available_payment_types.online

  // temporary? for demo presentation
  const hotel = store.flags.hotel
  const breakfast = store.flags.breakfast

  if (breakfast) {
    return <div style={{
      fontSize: 20,
      margin: "16px 0",
      fontFamily: 'FS Lola',
      fontWeight: 500,
    }}>
      Total price: <i><b style={{ fontSize: "1.2em", color: "#509E2F", marginLeft: 16 }}>{formatPrice(total)}&nbsp;zł</b></i>
    </div>
  }

  return <div style={{ fontSize: "14px", margin: "16px 0" }}>
    Cena {!hotel ? "Twoich zakupów" : "Twojego zamówienia"} to{!online && ' około'}: <b style={{ fontSize: "2em", color: "#222E33", marginLeft: 16 }}>{formatPrice(total)}&nbsp;zł</b><br />
    {!online && 'Końcową cenę poda pracownik sklepu przy płatności.'}
  </div>
}

const PaymentForm = ({ form, defaultOpen, merchantId, breakfast }) => {
  const [paymentTypes, setPaymentTypes] = useState()

  useEffect(() => {
    if (merchantId) {
      axios.get(`/api/payment-types/${merchantId}`).then((x) => {
        setPaymentTypes(x.data)
      })
    }
  }, [])

  return <>
    <Form.Item
      label={!breakfast ? "Wybierz formę płatności online" : null}
      name={["payment-data", "group-id"]}
      rules={[{
        required: true,
        message: 'Wybierz metodę płatności',
      }]}
    >
      {!paymentTypes
        ? <Spin />
        : <Select
          placeholder={!breakfast ? "Wybierz..." : "Choose"}
          //defaultOpen={defaultOpen}
          showSearch
          autoFocus
          filterOption={(input, option) =>
            option.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          size="large"
        >
          {Object.values(paymentTypes || {}).map(x => <Select.Option key={x.id} value={x.id}>
            <img
              src={x.img}
              style={{ width: 80, marginRight: 20, marginTop: -3 }}
              alt=""
            />
            {x.name}
          </Select.Option>)}

        </Select>
      }
    </Form.Item>

    <Form.Item
      label={!breakfast ? "Twoje imię i nazwisko:" : null}
      name="full_name"
      rules={[{
        required: true,
        message: 'Podaj swoje dane',
      }]}
    >
      <Input placeholder={!breakfast ? "Imię i nazwisko" : "Your name"} size="large" />
    </Form.Item>

    {!breakfast &&
      <Form.Item
        name="tpay_tos"
        valuePropName="checked"
        rules={[{ required: true, message: "zaakceptuj regulamin" }]}
      >
        <Checkbox>Akceptuję <a href="https://secure.tpay.com/regulamin.pdf" target="_blank" rel="noopener noreferrer">regulamin</a> płatności online</Checkbox>
      </Form.Item>
    }

    {/*
    <Form.Item noStyle shouldUpdate={(p, n) => (n.payment_data || {}).banktransfer_type != (p.payment_data || {}).banktransfer_type}>
      {() => form.getFieldValue(["payment_data", "banktransfer_type"]) == "card" &&
        <Form.Item label="Dane karty:">
          <CartInput form={form} />
        </Form.Item>
      }
    </Form.Item>
    */}
  </>
}



const AddressInput = ({ value, onChange, onBlur, store, form }) => {

  const AutocompleteService = useMemo(() => {
    return new window.google.maps.places.AutocompleteService()
  }, [])
  const PlacesService = useMemo(() => {
    return new window.google.maps.places.PlacesService(window.document.createElement('div'))
  }, [])
  const sessionToken = useMemo(() => new window.google.maps.places.AutocompleteSessionToken(), [])
  const [results, setResults] = useState(value ? [{ description: value, place_id: 'default' }] : null)
  const [selected, setSelected] = useState(value ? 'default' : null)

  const deliveryRadiusMeters = store.delivery_radius * 1000

  const handleSearch = (phrase) => {
    if (!phrase) return;
    AutocompleteService.getPlacePredictions({
      input: phrase,
      componentRestrictions: { country: 'pl' },
      origin: { lat: store.lat, lng: store.lng },
      location: new window.google.maps.LatLng(store.lat, store.lng),
      radius: deliveryRadiusMeters,
      types: ['address'],
      sessionToken,
    }, (x) => {
      setResults(x)
      //if(x && x[0]) setSelected(x[0].place_id)
    })
  }

  const handleSelect = (place_id) => {
    if (!place_id || place_id === selected) return;
    setSelected(place_id)
    PlacesService.getDetails({
      placeId: place_id,
      fields: ['geometry.location'], //'address_components',
      sessionToken
    }, (x) => {
      const latLng = { lat: x.geometry.location.lat(), lng: x.geometry.location.lng() }
      console.log(latLng)
      form.setFieldsValue({ delivery_geolocation: latLng })
    })
  }

  const options = (results || []).map(x =>
    <Select.Option
      key={x.place_id}
      value={x.place_id}
      disabled={!x.distance_meters || x.distance_meters > deliveryRadiusMeters}
    >
      {x.description.replace(", Polska", "").replace(", Poland", "")}
      {(x.distance_meters && x.distance_meters > deliveryRadiusMeters) && <small> (poza obszarem dostawy)</small>}
    </Select.Option>)
  return <Select
    showSearch
    showArrow={false}
    notFoundContent={null}
    onSearch={debounce(handleSearch, 200)}
    defaultActiveFirstOption={true}
    filterOption={false}
    placeholder="Ulica nr/nr, miasto"
    size="large"
    value={selected}
    onSelect={handleSelect}
    onBlur={() => handleSelect(results && results[0] && results[0].place_id)}
  >
    {options}
  </Select>

}

const BreakfastInfo = ({payOnline}) => {
  return <div style={{
    textAlign: 'center',
    fontFamily: 'FS Lola',
    fontSize: 26,
    fontWeight: 500,
    color: '#509E2F',
  }}>
    <img
      src="https://digital.ihg.com/is/image/ihg/holiday-inn_lsc_lkp_d_r_rgb_pos-web?fmt=png-alpha"
      style={{ maxWidth: 300, height: 45, marginBottom:  50}}
    />
    <p style={{marginBottom:  50}}>
      We are sorry but you don't have an open bill.
    </p>
    <a href="tel:+48 22 257 66 99" style={{border: '1px solid #d9d9d9', width: 130, borderRadius: 6, padding: 14, margin: 8, color: '#666666', fontSize: 16, display: 'inline-block'}}>
      <i className="icon-Phone-2" style={{fontSize: '2em', marginBottom: 12}}/><br/>
      Call front desk
    </a>
    <a onClick={payOnline} style={{border: '1px solid #d9d9d9', width: 130, borderRadius: 6, padding: 14, margin: 8, color: '#666666', fontSize: 16, display: 'inline-block'}}>
      <i className="icon-Credit-Card2" style={{fontSize: '2em', marginBottom: 12}}/><br/>
      Pay online
    </a>
  </div>
}

const CheckoutForm = inject('state')(observer(({ state, onClose }) => {

  const { setInCart, products, currentStore } = state
  const cart = state.cart[currentStore] || {}
  const total = state.cartTotal
  const store = state.currentStoreData

  // temporary? for demo presentation
  const hotel = store.flags.hotel
  const breakfast = store.flags.breakfast

  const history = useHistory()

  const [form] = Form.useForm()

  const [savedOrder, setSavedOrder] = useState()
  const [loading, setLoading] = useState(false)

  const postOrder = (values) => {
    setLoading(true)
    var orderProducts = [];
    for (var id in cart) {
      const q = cart[id]
      if (typeof q === 'number') {
        orderProducts.push({
          id,
          name: products[id].name,
          unit_price: products[id].price,  //remove?
          quantity: cart[id]
        })
      } else {
        for (var variant in q) {
          const v = JSON.parse(variant)
          orderProducts.push({
            id,
            options: v,
            name: products[id].name,
            unit_price: products[id].price,
            quantity: q[variant],
          })
        }
      }
    }

    if (orderProducts.length === 0) {
      Modal.error({ title: "Pusty koszyk", content: "Dodaj produkty do koszyka" })
      onClose()
      return;
    }

    const v = { ...values }

    if (hotel) {
      v.email = v.email || 'gosc@fablo.pl'
      v.phone = v.phone || '000000000'
      if (v.delivery_type == 'delivery') {
        v.delivery_address = 'Pokój 200'
      }
    }

    if (breakfast && v.payment_type == 'collect_cash') {
      Modal.info({
        title: '',
        icon: null,
        content: <BreakfastInfo payOnline={() => {
          form.setFieldsValue({payment_type: 'online'})
          Modal.destroyAll()
        }}/>,
        okType: 'link',
        okText: ' ',
        closable: true,
        maskClosable: true,
      })
      setLoading(false)
      return;
    }

    const postOrder = () => {

      delete v.card_number
      delete v.expiry_date
      delete v.cvc

      if (v.remember_data) {
        state.rememberCheckoutFormData(v)
      }

      delete v.remember_data
      delete v.delivery_type

      return axios.post(`/api/shop/${currentStore}/orders`, {
        ...v,
        products: orderProducts,
      }).then((x) => {
        state.clearCart()
        if (x.data.payment_url) {
          window.location = x.data.payment_url
          return
        }
        setSavedOrder(x.data)
        setLoading(false)
      })
        .catch((error) => {
          setLoading(false)
          if (error.response && error.response.status === 409) {
            const pid = error.response.data['product-id']
            const availability = error.response.data.availability
            const p = products[pid]
            setInCart(p, availability)
            Modal.confirm({
              icon: <ExclamationCircleOutlined />,
              title: availability > 0
                ? `Niestety sklep posiada tylko ${formatQuantity(availability)} ${p.unit} produktu "${p.name}"`
                : `Niestety sklep nie posiada już produktu "${p.name}"`,
              content: availability > 0
                ? `Zmniejszyliśmy jego ilość w Twoim koszyku do ${formatQuantity(availability)} ${p.unit}. Czy chcesz złożyć zmienione zamówienie?`
                : 'Usunęliśmy go z Twojego koszyka.  Czy chcesz złożyć zmienione zamówienie?',
              okText: "Tak, zamawiam",
              cancelText: "Nie, wróć do sklepu",
              onOk: () => { form.submit() },
              onCancel: onClose
            })
          } else {
            message.error("Nie udało się zapisać zamówienia. Spróbuj ponownie później")
          }
        })
    }

    postOrder()
  }

  /*const checkDeliveryAddres = (address) => {
    return geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        const dist = geoDistance(latLng, store)
        if (!store.delivery_radius || dist < store.delivery_radius) {
          form.setFieldsValue({ delivery_geolocation: latLng })
          return true;
        }
        else {
          console.log("distance", dist, "limit", store.delivery_radius)
          throw 'Podany adres znajduje się poza obszarem dostawy!'
        }
      })
  }*/

  return <div style={{ width: "100%", paddingTop: !breakfast ? 70 : 0, }}>
    <Spin spinning={loading}>
      <CenteredH>
        <Form
          style={{ maxWidth: '520px', margin: '0 auto', display: 'flex', flexDirection: 'column', }} //minHeight: 'calc(100vh - 110px)'
          layout='vertical'
          form={form}
          onFinish={postOrder}
          onFinishFailed={() => { message.error("Sprawdź poprawność danych") }}
          initialValues={{
            remember_data: true,
            ...state.checkoutFormData,
            delivery_type: store.delivery_available ? (state.checkoutFormData && state.checkoutFormData.delivery_type) : 'collect',
            delivery_address: state.address,
            payment_type: store.available_payment_types[state.checkoutFormData && state.checkoutFormData.payment_type] ? state.checkoutFormData.payment_type : (breakfast ? 'collect_cash' : null),
          }}
        >

          {breakfast && <div style={{ fontFamily: 'FS Lola', fontWeight: 500, fontSize: 20, flexGrow: 1 }}>
            <div style={{ color: '#509E2F' }}>
              Breakfast order for room no. <b style={{ fontSize: "1.8em", marginLeft: 16 }}>200</b><br />
            </div>
            <div style={{ display: 'flex', marginTop: 24, alignItems: 'baseline' }}>
              <div style={{ marginRight: 24 }}>Delivery time:</div>
              <Select size="large" options={['7:00', '7:30', '8:00', '8:30', '9:00', '9:30'].map(x => ({ label: <span style={{fontSize: 18}}>{x}</span>, value: x }))} defaultValue="7:00" />
            </div>
            <Form.Item
              name="extra_message"
            >
              <Input.TextArea
                maxLength={10000}
                placeholder="Input your extra requests here"
                style={{ fontSize: 16, marginTop: 24 }}
              />
            </Form.Item>
            <Form.Item
              name="payment_type"
              rules={[{
                required: true,
                message: 'Choose an option',
              }]}
            >
              <Radio.Group>
                <Radio style={{ fontSize: 16, marginRight: 24 }} value="collect_cash">Add to the room bill</Radio>
                <Radio style={{ fontSize: 16 }} value="online">Pay online</Radio>
              </Radio.Group>
            </Form.Item>
            <div style={{height: 128}}>
              <Form.Item noStyle shouldUpdate={(p, n) => n.payment_type !== p.payment_type}>
                {() => form.getFieldValue("payment_type") === "online" &&
                  <PaymentForm form={form} merchantId={store.tpay_merchant_id} defaultOpen breakfast />
                }
              </Form.Item>
            </div>
          </div>
          }
          {(hotel && !breakfast) && <div style={{ fontSize: "14px", margin: "16px 0" }}>
            Twój numer pokoju: <b style={{ fontSize: "2em", color: "#222E33", marginLeft: 16 }}>200</b><br />
          </div>
          }

          <TotalPriceNotice
            store={store}
            total={total}
          />

          {!breakfast && <>

            {!hotel && <>
              <Total>Podaj dane do kontaktu i złóż zamówienie:</Total>
              <Form.Item
                name="email"
                label=" Adres e-mail, na który wyślemy potwierdzenie zamówienia:"
                rules={[{
                  type: 'email',
                  message: 'Wprowadź poprawny adres E-mail',
                }, {
                  required: true,
                  message: 'Wprowadź swój adres E-mail',
                }]}
              >
                <Input placeholder="E-mail" size="large" />
              </Form.Item>
              <Form.Item
                label="Numer telefonu do kontaktu bezpośredniego:"
                name="phone"
                normalize={x => x.replace(/[^0-9]/g, '')}
                rules={[{
                  required: true,
                  message: 'Podaj numer telefonu',
                }, {
                  pattern: /^\d{9}$/,
                  message: 'Wpisz 9 cyfr'
                }
                ]}
              >
                <Input addonBefore="+48" placeholder="Numer telefonu" size="large" />
              </Form.Item>
            </>}

            {store.delivery_available &&
              <Form.Item
                label="Sposób odbioru:"
                name="delivery_type"
                rules={[{
                  required: true,
                  message: 'Wybierz sposób odbioru zamówienia',
                }]}
              >
                <Radio.Group>
                  {!hotel && <>
                    <Radio value="collect">Odbiór własny</Radio>
                    <Radio value="delivery">Dostawa {store.delivery_cost ? ` (+ ${formatPrice(store.delivery_cost).replace(',00', '')} zł)` : ''}</Radio>
                  </>}
                  {hotel && <>
                    <Radio value="collect">Odbiór na recepcji</Radio>
                    <Radio value="delivery">Dostawa do pokoju</Radio>
                  </>}
                </Radio.Group>
              </Form.Item>}

            <Form.Item noStyle shouldUpdate={(p, n) => n.delivery_type !== p.delivery_type}>
              {() => <>
                {form.getFieldValue("delivery_type") === "delivery" && !hotel &&
                  <>
                    <Form.Item
                      label="Adres dostawy:"
                      name="delivery_address"
                      initialValue={state.address}
                      validateTrigger={["onBlur"]}
                      hasFeedback
                      rules={[{
                        required: true,
                        message: 'Podaj adres dostawy',
                      },
                        // now AddressInput allows only for addresses in range
                        //{ validator: (_, v) => checkDeliveryAddres(v),}
                      ]}
                    >
                      {/*
                    <Input
                      placeholder="Ulica nr/nr, 00-000 miasto"
                      size="large"
                    />
                    */}
                      <AddressInput store={store} form={form} />

                    </Form.Item>
                    <Form.Item
                      name="delivery_geolocation"
                      noStyle
                    />
                  </>
                }
                <Form.Item
                  label="Sposób płatności"
                  name="payment_type"
                  rules={[{
                    required: true,
                    message: 'Wybierz sposób zapłaty za zamówienie',
                  }]}
                >
                  <Radio.Group>
                    {store.available_payment_types.online &&
                      <Radio value="online">Płatność online</Radio>}
                    {!hotel && form.getFieldValue("delivery_type") !== "delivery" &&
                      <>
                        {store.available_payment_types.collect_card &&
                          <Radio value="collect_card">Kartą przy odbiorze</Radio>}
                        {store.available_payment_types.collect_cash &&
                          <Radio value="collect_cash">Gotówką przy odbiorze</Radio>}
                      </>}
                    {!hotel && form.getFieldValue("delivery_type") === "delivery" &&
                      <>
                        {store.available_payment_types.delivery_card &&
                          <Radio value="delivery_card">Kartą przy dostawie</Radio>}
                        {store.available_payment_types.delivery_cash &&
                          <Radio value="delivery_cash">Gotówką przy dostawie</Radio>}
                      </>}
                    {hotel &&
                      <Radio value="collect_cash">Dodaj do rachunku</Radio>
                    }
                  </Radio.Group>
                </Form.Item>
              </>
              }
            </Form.Item>

            <Form.Item noStyle shouldUpdate={(p, n) => n.payment_type !== p.payment_type}>
              {() => form.getFieldValue("payment_type") === "online" &&
                <PaymentForm form={form} merchantId={store.tpay_merchant_id} defaultOpen />
              }
            </Form.Item>

            <Form.Item
              label={!hotel ? "Jeśli chcesz, możesz dodać wiadomość dla sprzedawcy:" : "Jeśli chcesz, możesz dodać wiadomość do zamówienia"}
              name="extra_message"
            >
              <Input.TextArea
                maxLength={10000}
                placeholder="Opcjonalna wiadomość"
              />
            </Form.Item>

          </>}

          {(store.extra_checkout_questions || []).map((x, i) => x.type && (
            <div key={i}>
              {x.type === "number" &&
                <Form.Item
                  label={x.label}
                  name={["extra_questions", x.label]}
                >
                  <InputNumber min={0} max={999} />
                </Form.Item>
              }
              {x.type === "radio" &&
                <Form.Item
                  label={x.label}
                  name={["extra_questions", x.label]}
                >
                  <Radio.Group>
                    {(x.options || []).map((v, i) => <Radio key={i} value={v}>{v}</Radio>)}
                  </Radio.Group>
                </Form.Item>
              }
            </div>
          ))}

          <Form.Item>
            {!breakfast &&
              <Button
                size="large"
                type="primary"
                style={{ width: "100%", margin: "20px 0" }}
                shape="round"
                htmlType="submit"
              >Składam zamówienie</Button>
            }
            {breakfast &&
              <Button
                size="large"
                //type="primary"
                style={{
                  width: "100%",
                  margin: "20px 0",
                  fontSize: 22,
                  height: 50,
                  fontFamily: 'FS Lola',
                  fontWeight: 500,
                }}
                //shape="round"
                htmlType="submit"
              >Confirm</Button>
            }
          </Form.Item>
          {!breakfast &&
            <Form.Item
              name="remember_data"
              valuePropName="checked"
            >
              <Checkbox>Zapamiętaj moje dane dla przyszłych zamówień</Checkbox>
            </Form.Item>
          }
        </Form>
      </CenteredH>
    </Spin>
    <Drawer
      placement="bottom"
      height="100%"
      visible={!!savedOrder}
      onClose={() => {
        onClose()
        setSavedOrder(undefined)
        history.push(`/${currentStore}`)
      }}
      bodyStyle={{ display: "flex" }}
    >
      <Confirmation order={savedOrder} />
    </Drawer>
  </div>
}))

export default CheckoutForm
export {
  ConfirmationPage,
  TotalPriceNotice,
}