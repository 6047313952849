// FIXME: this file is used both in the frontend and the panel via symlink!

import React, { useEffect, useRef } from 'react'
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Button, Affix, Row, Col, Tooltip, Carousel } from 'antd';
import { InstagramOutlined, FacebookFilled, LeftOutlined, RightOutlined } from '@ant-design/icons';
import Typed from 'typed.js'

const HeaderContainer = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
padding: 13px;
box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
background: rgba(255,255,255,0.0);
min-height: 65px;
.ant-affix & {
    background: rgba(255,255,255,0.985);
}
@media (max-width: 720px) {
    flex-wrap: wrap;
}
`
const LogoImg = styled.img`
height: 100%;
`
const LogoWrapper = styled.div`
margin-left: 50px;
position: absolute;
top: 6px;
left: 0;
border-radius: 55px;
width: 110px;
height: 68px;
text-align: center;
@media (max-width: 460px) {
    margin-left: 6px;
}
@media (max-width: 370px) {
    height: 50px;
    width: 80px;
}
`

const RotatingHeroImages = () => {
    const Wrapper = styled.div`
        position: relative;
        min-height: 550px;
        height: 100%;
        width: calc(100% + 30px);
        z-index: 0;
        margin: 0 -15px;
        @media(max-width: 576px) {
            overflow: hidden;
        }
        @keyframes fade {
            0% {opacity: 0;}
            20% {opacity: 1;}
            33% {opacity: 1;}
            53% {opacity: 0;}
            100% {opacity: 0;}
        }
        img {
            animation-name: fade;
            animation-iteration-count: infinite;
            animation-timing-function: ease;
            animation-duration: 15s;
        }
    `
    return <Wrapper>
        {[1, 2, 3].map((item) => (
            <img
                key={item}
                src={`/hp-slide-${item}.jpg`}
                style={{
                    height: "auto",
                    //objectFit: 'cover',
                    width: 675,
                    //height: 550,
                    //objectPosition: 'center bottom',
                    position: 'absolute',
                    bottom: 0,
                    left: "50%",
                    transform: "translate(-50%, 0)",
                    animationDelay: `-${item * 5}s`
                }}
            />
        ))}
    </Wrapper>
}

const Logo = ({ homeLink, flat }) => {
    const s = flat ? { boxShadow: "none" } : {}
    const logo = <LogoWrapper
        style={{ ...s }}>
        <Tooltip
            title={<>Fabulous Local Experience<br/> wspieraj jakość i obsługę klienta!</>}
            placement="bottom"
        >
            <LogoImg src="/logo.png" />
        </Tooltip>
    </LogoWrapper>

    if (homeLink !== null) {
        if (window.location.host.match(/panel\.|my\./)) {
            return <a href={`//${window.location.host.replace(/panel\.|my\./, '')}/`}>
                {logo}
            </a>
        } else {
            return <Link to={homeLink || "/"}>
                {logo}
            </Link>
        }
    }
    return logo
}

const Header = ({ buttons, homeLink }) => {
    const rightButtons = buttons || <>
        <Link to="/pomoc"><Button type="danger" style={{background: 'rgb(198, 24, 25)', borderColor: 'rgb(198, 24, 25)'}}>ZGŁOŚ PROBLEM</Button></Link>
        {/*<Link to="/b2b">
            <Button type="primary" style={{ marginLeft: 13, background: "#2E773A", borderColor: "#2E773A" }}>
                DLA BIZNESU
            </Button>
        </Link>*/}
    </>
    return <Affix>
        <HeaderContainer>
            <Logo homeLink={homeLink} />
            <div style={{zIndex: 2}}>
                {rightButtons}
            </div>
        </HeaderContainer>
    </Affix>
}


const FooterWrapper = styled.div`
background: #252525;
color: rgba(255,255,255,0.6);
padding: 70px 0;
text-align: center;
font-size: 12px;
& a {
    color: rgba(255,255,255,0.6);
    padding: 0 26px;
    font-weight: 700;
}
> div:first-child{
    @media (max-width: 470px) {
        a { display: block; }
    }
}
`

const FooterHeader = styled.div`
font-weight: 700;
font-size: 12px;
margin-bottom: 26px;
color: #252525;
margin-top: 50px;
`
const FooterLinkText = styled.span`
display: block;
font-size: 14px;
color: #666666;
line-height: 32px;
opacity: 0.6;
cursor: pointer;
`

const FooterSub = styled.span`
font-size: 12px;
color: #666666;
line-height: 26px;
`
const FooterSocial = styled.span`
color: #666666;
font-size: 16px;
margin-left: 26px;
opacity: 0.6;
`

const Footer = ({}) => {
    const FooterLink = ({to, children}) => {
        if(!to) {
            return <Tooltip
                title="Pracujemy nad tą podstroną. Niedługo będzie dostępna!"
                placement="bottomLeft"
            >
                <FooterLinkText>{children}</FooterLinkText>
            </Tooltip>
        } else if(to.startsWith('//')) {
            return <a href={to} target="_blank"><FooterLinkText>{children}</FooterLinkText></a>
        } else {
            return <Link to={to}><FooterLinkText>{children}</FooterLinkText></Link>
        }
    }

    return <div>
        <Row>
            <Col span={8}>
                <img src="/footer-1.jpg" style={{width: "100%"}}/>
            </Col>
            <Col span={8}>
                <img src="/footer-2.jpg" style={{width: "100%"}}/>
            </Col>
            <Col span={8}>
                <img src="/footer-3.jpg" style={{width: "100%"}}/>
            </Col>
        </Row>
    <Container>
        <Row>
            <Col md={6} span={12}>
                <FooterHeader>FABLO</FooterHeader>
                <FooterLink to="/pomoc">Pomoc</FooterLink>
                <FooterLink >FAQ</FooterLink>
                {/*<FooterLink >Poleć biznes</FooterLink>*/}
                <FooterLink to="/?form">Dołącz do Fablo</FooterLink>
                <FooterLink to="//retailic.com/kluczowe-osoby/">O nas</FooterLink>
            </Col>
            <Col md={6} span={12}>
                <FooterHeader>INFORMACJE PRAWNE</FooterHeader>
                <FooterLink to="/regulaminy#dla-uzytkownikow">Regulamin dla Użytkowników</FooterLink>
                <FooterLink to="/regulaminy#dla-sprzedawcow">Regulamin dla Sprzedawców</FooterLink>
                <FooterLink to="/regulaminy#cookies">Cookies</FooterLink>
                <FooterLink to="/regulaminy#prywatnosc">Polityka prywatności</FooterLink>
            </Col>
            <Col md={6} span={12}>
                <FooterHeader>FABULOUS LOCALS</FooterHeader>
                <FooterLink >Żoliborz</FooterLink>
                <FooterLink >Kabaty</FooterLink>
            </Col>
            <Col md={6} span={12}>
                <FooterHeader>WYRÓŻNIONE MARKI</FooterHeader>
                <FooterLink to="/2">Świat warzyw</FooterLink>
            </Col>
        </Row>
        <Row style={{padding: "26px 0"}}>
            <Col span={12}>
                <FooterSub>
                    <span style={{opacity: 0.5}}>© 2020 v2.0 Powered by</span>
                    <a href="https://retailic.com/" target="_blank" style={{ padding: 0 }}>
                        <img alt="Retailic"
                            src="/logo_footer.png"
                            style={{ width: "100px", marginLeft: 10, verticalAlign: "baseline" }}
                        />
                    </a>
                </FooterSub>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
                <a href="https://www.facebook.com/Fablopl-111455733860610/" target="_blank"><FooterSocial><FacebookFilled /></FooterSocial></a>
                <FooterSocial><InstagramOutlined /></FooterSocial>
            </Col>
        </Row>
    </Container>
    </div>
}

const PageWrapper = ({ headerButtons, children }) => {

    return <div className="page-wrapper">
        <Header buttons={headerButtons} />

        {children}

        <Footer />

    </div>
}

const H1 = styled.h1`
margin: 50px 0;
font-size: 3.14285714em;
line-height: 1.31818182em;
letter-spacing: -0.01em;
font-weight: 300;
color: #252525;
margin: 0 0 26px;

@media (max-width: 767px) {
    font-size: 2.35714286em;
    line-height: 1.36363636em;
}
`

const H2 = styled.h2`
text-align: center;
margin: 0 0 32px;
font-size: 2.35714286em;
line-height: 1.36363636em;
font-weight: 300;
color: #252525;

@media (max-width: 767px) {
    font-size: 1.78571429em;
    line-height: 1.5em;
}
`

const H3 = styled.h3`
margin: 0 0 26px;
font-size: 25px;
line-height: 1.5em;
font-weight: 300;
color: #252525;
margin: 0 0 26px;
text-align: center;

@media (max-width: 767px) {
    font-size: 17px;
    line-height: 32px;
}
`

const H4 = styled.h4`
margin: 0 0 26px;
font-size: 19px;
line-height: 26px;
font-weight: 400;
color: #252525;
margin: 0 0 26px;
text-align: center;
`

const Lead = styled.p`
font-size: 19px;
line-height: 32px;
font-weight:400;
color: #808080;
margin: 0;

@media (max-width: 767px) {
    font-size: 17px;
    line-height: 24px;
}
`

const Container = styled.div`
margin-right: auto;
margin-left: auto;
padding-left: 15px;
padding-right: 15px;

@media (min-width: 768px) {
    width: 750px;
}
@media (min-width: 992px) {
    width: 970px;
}
@media (min-width: 1200px) {
    width: 1170px;
}

&.section {
    padding-top: 100px;
}
&.padding {
    padding-top: 100px;
    padding-bottom: 100px;
    @media (max-width: 767px) {
        padding-top: 70px;
        padding-bottom: 70px;
    }
}
`

const Hero = styled.div`
background-color: #fafafa;
border-bottom: 1px solid rgb(235, 235, 235);
& > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}
`

const Testimonials = ({slides}) => {
    const carousel = useRef()

    const Arrow = styled.div`
        position: absolute;
        top: 60px;
        font-size: 24px;
        opacity: 0.5;
        cursor: pointer;
        padding: 20px 0;
        &:hover{
            opacity: 1;
        }
    `

    const Slide = ({ text, sub1, sub2, img }) => (<div>
        <Row justify="center">
            <Col md={7} style={{ textAlign: 'center' }}>
                <img
                    src={`/hp/${img}.jpg`}
                    style={{
                        width: 165,
                        height: 165,
                        borderRadius: "50%",
                        display: "inline-block",
                    }}
                />
            </Col>
        <Col md={13}>
            <H3 style={{ textAlign: 'left' }}>
            “{text}”
            </H3>
            <p style={{
                fontSize: 14,
                lineHeight: "26px",
                fontWeight: 600,
                color: "#252525",
                margin: 0,
            }}>{sub1}</p>
            <p style={{
                fontSize: 14,
            }}>{sub2}</p>
        </Col>
        </Row>
    </div >)

    return <div style={{ position: 'relative' }}>
        <Carousel autoplay ref={carousel} dots={false} autoplaySpeed={12000}>
            {slides.map((x ,i) => <Slide
                key={i}
                text={x.text}
                sub1={x.sub1}
                sub2={x.sub2}
                img={x.img}
            />)}
        </Carousel>
        <Arrow style={{ left: 0 }} onClick={() => carousel.current.prev()}><LeftOutlined /></Arrow>
        <Arrow style={{ right: 0 }} onClick={() => carousel.current.next()}><RightOutlined /></Arrow>
    </div>
}

const WordAnimation = ({ strings }) => {
    const ref = useRef()
    useEffect(() => {
        const typed = new Typed(ref.current, {
            strings: strings,
            loop: true,
            typeSpeed: 80,
            backSpeed: 40,
            backDelay: 2000,
        });
        return () => { typed.destroy() }
    }, [])
    return <span
        style={{
            color: "var(--primary)",
        }}
    >
        <span ref={ref}></span>
    </span>
}

export default PageWrapper
export {
    Footer, Header, Container, H1, H2, H3, H4, Lead, Hero, Logo,
    RotatingHeroImages, Testimonials, WordAnimation
}